import axios from 'axios'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import { GuidanceState, GuidanceType, RootState, Energy } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import {
  Section,
  getFluidSectionsUsage,
} from '@/composables/connection/guidance'

const state: GuidanceState = {
  // Traductions
  i18n: {},
  // Type sélectionné (specific ou standard) pour afficher Usage.vue
  meters: [],
  type: null,
  // Sert à afficher Usage.vue et stocke les valuers des inputs
  sections: [],
  // Guidance validé ?
  complete: false,
}

const getters: GetterTree<GuidanceState, RootState> = {
  // Check si Usage.vue est complété.
  // On regarde les sections active (slider === oui) et si au moins un input est rentré
  readyToGo: state => {
    const sectionsActive = state.sections.filter(
      section => section.slider.isActive
    )
    if (sectionsActive.length === 0) {
      return false
    }

    let ready = false
    sectionsActive.forEach(section => {
      if (section.total) {
        ready = true
      } else {
        ready = false
      }
    })

    return ready
  },
}

const mutations: MutationTree<GuidanceState> = {
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_METERS(state, payload) {
    state.meters = payload
  },
  SET_TYPE(state, payload: GuidanceType) {
    state.type = payload
  },
  SET_SECTIONS(state, payload: Section[]) {
    state.sections = payload
  },
  SET_COMPLETE(state, payload: boolean) {
    state.complete = payload
  },
  RESET_GUIDANCE(state) {
    state.type = null
    state.complete = false
    state.sections = []
  },
}

const actions: ActionTree<GuidanceState, RootState> = {
  // FETCH Translations
  async FETCH_GUIDANCE({ commit, state }) {
    if (Object.keys(state.i18n).length === 0) {
      await axios
        .get(`${getApiUrl()}/translations/guidance`)
        .then(({ data }) => {
          commit('SET_I18N', data)
        })
        .catch(error => {
          logger.error('[GUIDANCE] fetch i18n error', error.message)
        })
    }
  },
  // Construction des sections en fonction de l'énergie et du type.
  GET_SECTIONS_FLUID({ commit, state }, energy: Energy) {
    if (state.sections.length === 0 && state.type) {
      const sections = getFluidSectionsUsage(
        state.i18n[energy][state.type].sections
      )
      commit('SET_SECTIONS', sections)
    }
  },
}

export const guidance: Module<GuidanceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
