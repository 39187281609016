














































































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  props: {
    content: Object,
  },
  setup() {
    const isOpen = ref(false)
    const text = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const textAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })
      },
    }

    return {
      text,
      textAnims,
      isOpen,
      toggle,
    }
  },
})
