import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import axios from 'axios'

import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'

import {
  Ean,
  LocalizedEan,
  RootState,
  userAuthorizedAccess,
  UserPermission,
  UserState,
} from '@/inc/types'
import auth from '@/composables/auth'

/* eslint-disable */
const getDefaultState = (): UserState => ({
  uid: null,
  email: '',
  contact: {
    contactEmail: '',
    phone: '',
    adresse: {
      cdPostal: '',
      localite: '',
      numRue: '',
      rue: '',
    },
  },
  firstname: '',
  lastname: '',
  activated: true,
  ppp: false,
  smartPortal: true,
  validContactEmail: false,
  validPhone: false,
  listeEan: [],
  dossiers: [],
  profile: {
    communeAG: false,
    communeEP: false,
  },
  notifications: [],
  preferences: {},
  commune: {
    id: '',
    actif: false,
    admin: false,
    codePostaux: [],
    departement: '',
    fonction: '',
    localite: '',
    roles: [],
  },
  isFetching: false,
})

const state: UserState = getDefaultState()

const getters: GetterTree<UserState, RootState> = {
  username: state => `${state.firstname} ${state.lastname}`,
  address: state => state.contact.adresse,
  activeEans: state =>
    state.listeEan?.filter(
      ean => ean.status === 'ACTIF' || ean.status === 'INACTIF'
    ),
  // WIP
  localizedEans: (_state, getters): LocalizedEan =>
    getters.activeEans.reduce((acc: LocalizedEan, cur: Ean) => {
      const key = Object.values(cur.adresse).join('.')

      if (acc[key]) {
        acc[key].listeEan.push(cur)
      } else {
        acc[key] = {
          address: cur.adresse,
          listeEan: [cur],
        }
      }

      return acc
    }, {} as LocalizedEan),
  getEan: state => (id: string) => state.listeEan?.find(m => m.ean === id),
  permissions: (state, getters): UserPermission[] => {
    if (state.profile) {
      const fromProfile = Object.keys(state.profile)
        .filter(k => state.profile[k])
        .map(k => k.replace(/^commune/, '').toLowerCase() as UserPermission)
      const fromEan: UserPermission[] = []

      if (getters.activeEans.some((ean: Ean) => ean.profile.pPP)) {
        fromEan.push('ppp')
      }

      return fromProfile.concat(fromEan)
    }
    return []
  },
  hasAccess: (_state, getters) => (key: string): boolean =>
    getters.permissions.includes(key) || state[key] === true,
  isPpp: state => state.ppp,
  isCommune: state => (state?.commune?.id !== ''),
  isAdmin: state =>
    !!(state?.commune?.admin && state?.commune.actif),
  getRoles: state => {
    let roles: string[] = []
    if (state.commune) {
      if (state.commune.admin) {
        roles = ['ADMIN']
      } else {
        roles.push('AUTHENTICATED')
        roles = roles.concat(state.commune.roles)
      }
    }
    return roles
  },
  getCodesPostaux: state => {
    let cps: string[] = []
    if (state.commune && state.commune.codePostaux) {
      cps = state.commune.codePostaux
    }
    return cps
  },
  getCommuneId: state => {
    return state.commune?.id
  },
  authorizations(_state, getters): string[] {
    let authorizedAccess: string[] = []
    if (getters.isAdmin) {
      authorizedAccess = userAuthorizedAccess.ADMIN
    } else {
      getters.getRoles.forEach(role => {
        if (userAuthorizedAccess[role]) {
          userAuthorizedAccess[role].forEach(access => {
            authorizedAccess.push(access)
          })
        }
      })
    }
    return authorizedAccess
  },
  isFetching: state => state.isFetching,
}

const mutations: MutationTree<UserState> = {
  /* eslint-desable */
  UPDATE_USER_DOSSIERS(state, payload) {
    state.dossiers = payload.dossiers
  },
  START_LOADING_FETCH(state, payload) {
    state.isFetching = payload
  },
  UPDATE_USER(state, payload) {
    if (payload) {
      Object.keys(payload).forEach(key => {
        if (key in state) {
          state[key] = payload[key]
        }
      })
    }
  },
  SET_USER(state, payload) {
    if (payload) {
      Object.keys(getDefaultState()).forEach(key => {
        state[key] = payload[key]
      })
      if (payload.listeEan) {
        state.listeEan = payload.listeEan.map(ean => {
          if (ean.sectActivite === '01') {
            ean.sector = 'electricite'
          } else {
            ean.sector = 'gaz'
          }

          return ean
        })
      }
    }
  },
  DELETE_USER(state = {} as UserState) {
    Object.assign(state, getDefaultState())
  },
}

const actions: ActionTree<UserState, RootState> = {
  refresh({commit}) {
    logger.trace('[user:refresh]')
    commit('SET_USER', auth.currentUser.value)
  },
  refreshDossier({commit}) {
    axios
      .get(`${getApiUrl()}/me/dashboard`)
      .then(res => {
        const stepAction = ['0', '21', '32', '42', '44', '61', '72', '81', '84']
        res.data.dossiers.sort((a, b) => {
          if (
            stepAction.includes(a.codeStatut) ===
            stepAction.includes(b.codeStatut)
          ) {
            // trier par code statut
            if (a.codeStatut - b.codeStatut > 0) {
              return 1
            } else if (a.codeStatut - b.codeStatut === 0) {
              return 0
            } else {
              return -1
            }
          } else {
            if (!stepAction.includes(a.codeStatut)) {
              return 1
            } else {
              return -1
            }
          }
        })
        if (res.status === 200) {
          logger.trace('[user:refreshDossier] REUSSI')
          commit('UPDATE_USER_DOSSIERS', res.data)
        }
      })
      .catch(e => {
        logger.trace('[user:refreshDossier] FAILED')
      })
  },
  async refreshUser({commit}) {
    commit('START_LOADING_FETCH', true)
    await axios
      .get(`${getApiUrl()}/me/dashboard`)
      .then(res => {
        commit('UPDATE_USER', res.data)
        commit('START_LOADING_FETCH', false)
      })
      .catch(e => {
        logger.trace('[user:refreshUser] FAILED')
      })
  },
}

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
