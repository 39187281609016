














































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import auth from '@/composables/auth'
import MyLang from '@/components/my/header/Lang.vue'
import ChromeLang from '@/components/chrome/Lang.vue'
// import MyNotifications from '@/components/my/header/Notifications.vue'
import MyUser from '@/components/my/header/User.vue'
import { useActions, useState } from '@u3u/vue-hooks'

const {
  RESA_ENV,
  VUE_APP_DOMAIN,
  VUE_APP_TASK,
  VUE_APP_VERSION: version,
} = process.env

export default defineComponent({
  name: 'my-header',
  components: {
    MyLang,
    // MyNotifications,
    ChromeLang,
    MyUser,
  },
  setup(_props, ctx) {
    let resaUrl = VUE_APP_DOMAIN
    const { fetchI18n } = useActions('my', ['fetchI18n'])
    const { lang } = ctx.root.$route.params
    const resource = useState(['resource'])
    const myLang = ref(lang)
    const { user } = useState(['user'])
    if (VUE_APP_TASK === 'serve') {
      resaUrl = '/'
    }

    const toggleMenu = () => {
      ctx.root.$el.classList.toggle('is-sidebar-open')
    }

    ctx.root.$router.beforeEach((_to, _from, next) => {
      ctx.root.$el.classList.remove('is-sidebar-open')

      next()
    })

    watch(myLang, old => {
      fetchI18n()
    })

    const languagesAvailable = computed(() => {
      if (Object.keys(resource.resource.value.languages).length > 1) {
        return true
      }

      return false
    })

    return {
      isLogged: auth.isLogged,
      resaUrl,
      toggleMenu,
      user,
      languagesAvailable,
    }
  },
})
