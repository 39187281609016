




















































































































































































































import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import Axios from 'axios'
import FaqCategory from '@/components/faq/FaqCategory.vue'

export default defineComponent({
  name: 'search-module',
  components: { FaqCategory },
  props: {
    inModal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(_prop, ctx) {
    const click = ref(false)
    const { currentLang } = useGetters(['currentLang'])
    const input = ref('')
    const result = ref()
    const url = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/`
    // partie i18n , permettant de recuperer les traductions
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])
    const modalDisplay = ref(_prop.inModal)
    const hideTheme = ref(false)
    const urlFaq =
      currentLang.value === 'de' ? 'hilfe-und-kontakt/' : 'aide-et-contact/'

    onBeforeMount(() => {
      actions.FETCH_I18N()
    })

    const fetchSearch = async (
      text: string,
      lang: string,
      limit: number,
      type: string
    ) =>
      (result.value = await Axios.get(
        `${getApiUrl()}/search/${text.replaceAll(
          // eslint-disable-next-line quotes
          "'",
          '%27'
        )}&${lang}&${limit}&${type}`
      )
        .then(resp => resp.data)
        .catch(error => {
          logger.error('[FETCH FAQS] ID FAQ not exist', error)
        }))

    watch(input, _ => {
      if (input.value.length > 2 && modalDisplay.value) {
        hideTheme.value = true
        result.value = fetchSearch(
          encodeURIComponent(input.value),
          currentLang.value,
          -1,
          'any'
        )
      } else {
        hideTheme.value = false
      }
    })

    const onEnter = data => {
      if (data.length > 2) {
        ctx.root.$emit('searchwp:close')
        click.value = false
        let myPath = ''
        if (currentLang.value === 'fr') {
          myPath = `/${currentLang.value}/resultats-de-la-recherche/`
        } else if (currentLang.value === 'de') {
          myPath = `/${currentLang.value}/ergebnisse-der-suche/`
        }
        ctx.root.$router
          .push({
            path: myPath,
            query: { str: data },
            params: { id: data },
          })
          .catch(e => true)
      }
    }
    const showTitle = string => {
      const hmtl = string.replaceAll('mark', 'span')

      return hmtl.replace(
        'class="searchwp-highlight"',
        'style="font-weight: 900;"'
      )
    }

    return {
      click,
      input,
      result,
      url,
      onEnter,
      currentLang,
      i18n: state.i18n,
      ...useGetters(['chrome']),
      showTitle,
      modalDisplay,
      hideTheme,
      urlFaq,
    }
  },
})
