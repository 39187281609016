



















































import { defineComponent, onMounted } from '@vue/composition-api'
import { DualText } from '@/inc/types/components'
import { transformToHtmlId } from '../composables/utils'

export default defineComponent({
  name: 'dual-text',
  components: {},
  props: {
    content: {
      type: Object as () => DualText,
      required: true,
    },
  },
  setup() {
    const onClickAnchorLink = oEvent => {
      oEvent.stopPropagation()
      const { target } = oEvent
      let targetHash = target.hash

      if (target.nodeName !== 'A') {
        targetHash = target.parentElement.hash
      }

      if (targetHash) {
        oEvent.preventDefault()
        window.scroll({
          top: document.querySelector(targetHash).offsetTop - 50,
          behavior: 'smooth',
        })
      }
    }

    onMounted(() => {
      const anchorLink = document.querySelectorAll('a[href^="#"]')
      if (anchorLink.length) {
        anchorLink.forEach(link => {
          link.addEventListener('click', onClickAnchorLink)
        })
      }
    })

    return { transformToHtmlId }
  },
})
