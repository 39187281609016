import { AxiosResponse } from 'axios'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import { cache, logger } from '@/inc/utils'
import { langDefault, getApiUrl } from '@/inc/app.config'
import { Chrome, ChromeState, RootState, Language } from '@/inc/types'

const state: ChromeState = {
  data: {} as Chrome,
  currentLang: langDefault,
}

const getters: GetterTree<ChromeState, RootState> = {
  chrome: state => state.data,
  currentLang: state => state.currentLang,
  // TODO: VERIFY NECCESARY
  cookiebotPage: state => state.data.globalPages?.cookiebot || {},
}

const mutations: MutationTree<ChromeState> = {
  SET_CHROME(state, payload: Chrome) {
    if (payload) {
      state.data = payload
    }
  },
  UPDATE_LANGUAGE(state, lang: Language) {
    state.currentLang = lang
  },
}

const actions: ActionTree<ChromeState, RootState> = {
  async fetchChrome({ commit }, payload) {
    try {
      const { lang } = payload || langDefault
      const path = `${lang}/chrome`
      const response = (await cache.fetch(
        path,
        `${getApiUrl()}/chrome`
      )) as AxiosResponse<Chrome>
      const data: Chrome = response?.data

      commit('UPDATE_LANGUAGE', lang)
      commit('SET_CHROME', data)
    } catch (error) {
      logger.error('[fetchChrome]', error)
    }
  },
}

export const chrome: Module<ChromeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
