










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'text-title',
  components: {},
  props: {
    content: Object,
  },
})
