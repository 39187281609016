






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {},
  name: 'ds-box-color',
  props: {
    type: {
      type: String,
      value: 'tip' || 'info' || 'warning',
      default: 'info',
    },
    role: {
      type: String,
      default: 'alert',
    },
  },
})
