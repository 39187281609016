import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import logger from 'consola'
import { v1 as uuidv1 } from 'uuid'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { last } from 'rambda'
import {
  ConnectionMeter,
  ConnectionMeterSpecific,
  ConnectionMeterTemporary,
  ConnectionState,
  ConnectionStep,
  RootState,
} from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import {
  getDescriptionContact,
  getDescriptionFiles,
  getRequestWs35,
  getSubSection,
  sendFiles,
  lang,
} from '@/composables/connection/connectionRequest'
import gtm, { Event } from '@/composables/gtm'
import { getPhaseSlug, getTarif, getTarifSlug } from '@/inc/data'
import auth from '@/composables/auth'
import { connectionLogs } from '@/composables/connection/connectionLogs'
import { POWER_LIMIT } from '@/composables/const'

// eslint-disable-next-line
const getDefaultState = () => {
  return ({
    processus: null,
    subProcessus: null,
    editSubProcessusGaz: null,
    editSubProcessusElect: null,
    formsReady: false,
    summary: false,
    currentEnergyType: null,
    selectedEnergyType: null,
    currentStep: '',
    currentScreen: '',
    ean: null,
    eans: null,
    address: {
      area: null,
      existingAddress: true,
      existingMeter: false,
      street: null,
      streetNumber: null,
      zip: null,
      country: 'BE',
      location: null,
      idRue: null,
      idLocalite: null,
      idRadRue: null,
      idRadLocalite: null,
      parcelleSpw: null,
      coordinates: {
        lat: null,
        lng: null,
      },
    },
    availableEnergy: null,
    isGRD: null,
    dispoGaz: null,
    building: {
      type: null,
      usage: null,
      is10kva: false,
      appartements: false,
    },
    meters: [],
    suppMeters: false,
    existingMeters: [],
    recupTarif: [],
    specificInfo: {
      city: null,
      entity: null,
      location: null,
      startDate: null,
      endDate: null,
      activity: null,
      weeks: null,
      totalPriceSockets: null,
      totalSockets: null,
      totalPrice: null,
    },
    specificMeters: [],
    temporaryMeter: null,
    temporaryInfo: {
      startDate: null,
      endDate: null,
      usage: null,
    },
    personalData: {
      civil: null,
      type: 'Particulier',
      area: null,
      street: null,
      streetNumber: null,
      zip: null,
      country: 'BE',
      idRue: null,
      idLocalite: null,
      idRadRue: null,
      idRadLocalite: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      phoneCode: null,
      location: null,
      letterBox: null,
      company: null,
      tva: null,
    },
    ownerData: {
      isOwner: true,
      civil: null,
      area: null,
      street: null,
      streetNumber: null,
      zip: null,
      country: 'BE',
      idRue: null,
      idLocalite: null,
      idRadRue: null,
      idRadLocalite: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      phoneCode: null,
      location: null,
      letterBox: null,
      company: null,
      legal: null,
    },
    contactData: {
      isContact: false,
      civil: null,
      job: null,
      area: null,
      street: null,
      streetNumber: null,
      zip: null,
      country: 'BE',
      idRue: null,
      idLocalite: null,
      idRadRue: null,
      idRadLocalite: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      phoneCode: null,
      location: null,
      letterBox: null,
    },
    tva: {
      type: null,
      logement: null,
    },
    request: {
      date: null,
      remarkCpts: { electricity: '', gaz: '' },
      remarkInfos: null,
      gdprConsent: false,
      tvaConfirm: false,
      deplaCpt: false,
      typeSmart: null,
    },
    /* eslint-disable */
    files: {},
    /* eslint-enable */
    // { id: '', screens: [] }
    steps: [],
    sended: false,
    finalStatus: false,
    i18n: {},
  } as unknown) as ConnectionState
}

const state: ConnectionState = getDefaultState()

const mutations: MutationTree<ConnectionState> = {
  ADD_METER(state, payload) {
    const meter = {
      uuid: uuidv1(), // Local timestamp is used for uuid generation
      id: payload,
      energy: state.currentEnergyType,
      power: null,
      nightOption: false,
    } as ConnectionMeter

    state.meters.push(meter)
  },
  ADD_EXISTING_METER(state, { ean, res }) {
    res.compteurs.forEach(cpt => {
      if (!cpt.cptControle) {
        const meter = {
          uuid: uuidv1(), // Local timestamp is used for uuid generation
          id: cpt.numCpt,
          ean,
          numcpt: cpt.numCpt,
          energy: res.sectActivite === '01' ? 'electricity' : 'gaz',
          power: res.pContPrel ? parseFloat(res.pContPrel) : null,
          nightOption: cpt.nRegistres === 2,
          amperage: res.reglPrel ? res.reglPrel : null,
          phases: res.nbPhases ? res.nbPhases : null,
          tarif: getTarif(res.uCodeLibelle),
          tarifSlug: getTarifSlug(
            cpt.nRegistres === 2,
            getTarif(res.uCodeLibelle)
          ),
          phasesSlug:
            res.sectActivite === '01' ? getPhaseSlug(res.nbPhases) : null,
        } as ConnectionMeter

        state.meters.push(meter)
        state.existingMeters.push(JSON.parse(JSON.stringify(meter)))
      }
    })
  },
  ADD_SPECIFIC_METER(state, payload) {
    const specificMeter = {
      uuid: uuidv1(), // Local timestamp is used for uuid generation
      ...payload,
    } as ConnectionMeterSpecific

    state.specificMeters.push(specificMeter)
  },
  ADD_TEMPORARY_METER(state, payload) {
    const temporaryMeter = {
      uuid: uuidv1(), // Local timestamp is used for uuid generation
      ...payload,
    } as ConnectionMeterTemporary

    state.temporaryMeter = temporaryMeter
  },
  ADD_SCREEN(state, { id, screen }) {
    const meter = state.steps.find(step => step.id === id) as ConnectionStep

    if (meter && !meter.screens.includes(screen)) {
      meter.screens.push(screen)
    }
  },
  REMOVE_METER(state, uuid) {
    const meter = state.meters.find(meter => meter.uuid === uuid)

    if (meter) {
      state.meters.splice(state.meters.indexOf(meter), 1)
    }

    if (state.processus === 'new') {
      state.meters.forEach(meter => {
        const index = state.meters.indexOf(meter)
        meter.id = index + 1
        state.meters.splice(index, 1, meter)
      })
    }
  },
  REMOVE_SPECIFIC_METER(state, uuid) {
    const specificMeter = state.specificMeters?.find(
      specificMeter => specificMeter.uuid === uuid
    )

    if (specificMeter) {
      state.specificMeters?.splice(
        state.specificMeters.indexOf(specificMeter),
        1
      )
    }
  },
  REMOVE_TEMPORARY_METER(state) {
    state.temporaryMeter = null
  },
  RESET_METERS(state) {
    state.meters = []
    state.existingMeters = []
  },
  RESET_NULL_METERS(state) {
    state.meters.forEach(meter => {
      if (!meter.power && state.processus === 'new') {
        const index = state.meters.indexOf(meter)
        state.meters.splice(index, 1)
      }
    })
  },
  RESET_SPECIFIC_METERS(state) {
    state.specificMeters = []
  },
  SET_STEPS(state, payload) {
    state.steps = payload
  },
  SET_PROCESSUS(state, payload) {
    state.processus = payload
  },
  SET_SUBPROCESSUS(state, payload) {
    state.subProcessus = payload
  },
  SET_SUBPROCESSUSELECT(state, payload) {
    state.editSubProcessusElect = payload
  },
  SET_SUBPROCESSUSGAZ(state, payload) {
    state.editSubProcessusGaz = payload
  },
  SET_FORMSREADY(state, payload) {
    state.formsReady = payload
  },
  SET_SUMMARY(state, payload) {
    state.summary = payload
  },
  SET_EAN(state, payload) {
    state.ean = payload
  },
  SET_EANS(state, payload) {
    // 01:elect 02:gaz
    state.eans = payload
  },
  SET_SUPP_METERS(state, payload) {
    state.suppMeters = payload
  },
  SET_ADDRESS(state, payload) {
    state.address = payload
  },
  SET_COORDINATES(state, payload) {
    state.address.coordinates.lat = payload.lat
    state.address.coordinates.lng = payload.long
  },
  SET_BUILDING(state, payload) {
    state.building = payload
  },
  SET_SPECIFIC_INFO(state, payload) {
    state.specificInfo = payload
  },
  SET_TEMPORARY_INFO(state, payload) {
    state.temporaryInfo = payload
  },
  SET_RECUP_TARIF(state, payload) {
    state.recupTarif = payload
  },
  SET_CURRENT_STEP(state, payload) {
    state.currentStep = payload
  },
  SET_CURRENT_ENERGY_TYPE(state, payload) {
    state.currentEnergyType = payload
  },
  SET_CURRENT_SCREEN(state, payload) {
    const current = state.steps.find(step => step.id === state.currentStep)

    if (!payload && current) {
      ;[state.currentScreen] = current.screens

      return
    }

    state.currentScreen = payload
  },
  SET_ENERGY_TYPE(state, payload) {
    state.selectedEnergyType = payload
  },
  SET_FILES(state, payload) {
    state.files = payload
  },
  SET_AVAILABLE_ENERGY(state, payload: ConnectionState['availableEnergy']) {
    state.availableEnergy = payload
  },
  SET_ISGRD(state, payload) {
    state.isGRD = payload
  },
  SET_DISPOGAZ(state, payload) {
    state.dispoGaz = payload
  },
  SET_FINAL_STATUS(state, payload) {
    state.finalStatus = payload
  },
  SET_PERSON(state, payload) {
    state.personalData.area = payload.contact.adresse.localite
    state.personalData.country = payload.contact.adresse.codePays
    state.personalData.streetNumber = payload.contact.adresse.numRue
    state.personalData.street = payload.contact.adresse.rue
    state.personalData.zip = payload.contact.adresse.cdpostal
    if (payload.contact.phone) {
      const mobilePhoneNumber = (payload.contact.phone as string).match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/
      )
        ;[state.personalData.phoneCode] = mobilePhoneNumber!
      state.personalData.phone = (payload.contact.phone as string).substring(
        mobilePhoneNumber![0].length
      )
    }
    state.personalData.email = payload.contact.contactEmail
    state.personalData.firstname = payload.firstname
    state.personalData.lastname = payload.lastname
  },
  SET_TVA(state, payload) {
    state.tva = payload
  },
  SET_SENDED(state, payload) {
    state.sended = payload
  },
  UPDATE_METER(state, payload) {
    const meter = state.meters.find(
      meter => meter.uuid === payload.uuid
    ) as ConnectionMeter

    if (meter) {
      const index = state.meters.indexOf(meter)
      state.meters.splice(index, 1, payload)
    }
  },
  UPDATE_TEMPORARY_METER(state, payload) {
    Object.assign(state.temporaryMeter, payload)
  },
  ADD_SUPP_METERS(state, payload) {
    state.meters.push(payload)
  },
  UPDATE_REQUEST_DATA(state, { data = {}, type = 'request' }) {
    for (const key in data) {
      if (key) {
        state[type][key] = data[key]
      }
    }
  },
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_I18N_RAC(state, payload) {
    state.i18nRac = payload
  },
  SET_STATE(state, payload) {
    if ('connection' in payload) {
      if (
        payload.connection.processus === state.processus &&
        !state.sended &&
        !payload.connection.sended
      ) {
        Object.assign(state, payload.connection)
        // protection if user reload in last request WS35
        if (
          payload.connection.currentStep === 'final' ||
          payload.connection.currentStep === 'recap'
        ) {
          state.currentScreen = 'documents'
          state.currentStep = 'documents'
        }
      } else {
        state.i18n = payload.connection.i18n
        state.tva = payload.connection.tva
        state.request.tvaConfirm = payload.connection.request.tvaConfirm
        state.personalData = payload.connection.personalData
        state.ownerData = payload.connection.ownerData
        state.contactData = payload.connection.contactData
      }
      state.finalStatus = false
      state.formsReady = false
      state.summary = false
      state.files = {}
    }
  },
}

const actions: ActionTree<ConnectionState, RootState> = {
  async fetchI18nRac({ commit }) {
    const res: AxiosResponse = await axios.get(`${getApiUrl()}/rac`)
    commit('SET_I18N_RAC', res.data)
  },
  CHECK_ADDRESS({ commit, state }) {
    commit('SET_AVAILABLE_ENERGY', null)

    const params = {
      Cdpostal: state.address.zip,
      Langue: 'FR',
      Localite: state.address.area,
      Rue: state.address.street,
    }

    /* eslint-disable */
    if (state.address.existingAddress) {
      params['Numero'] = state.address.streetNumber
    }
    /* eslint-enable */

    axios
      .get(`${getApiUrl()}/address/services`, { params })
      .then(({ data }) => {
        const types = {
          gaz: 'gaz',
          elec: 'electricity',
        }
        const available: string[] = Object.keys(data)
          .filter(key => data[key] === 'X' && types[key])
          .map(key => types[key])

        const typesGRD = {
          isGRDGaz: 'gaz',
          isGRDElec: 'electricity',
        }
        const isGRD: string[] = Object.keys(data)
          .filter(key => data[key] === 'X' && typesGRD[key])
          .map(key => typesGRD[key])

        commit('SET_AVAILABLE_ENERGY', available.length > 0 ? available : [])
        commit('SET_ISGRD', isGRD.length > 0 ? isGRD : [])
        commit('SET_DISPOGAZ', data.dispoGaz)
      })
      .catch(error => {
        commit('SET_AVAILABLE_ENERGY', [])
        commit('SET_ISGRD', [])
        commit('SET_DISPOGAZ', 'NO')
        logger.error('[NV RACC] Service error')
      })
  },

  MATCH_ADDRESS({ commit, state }) {
    axios
      .post(`${getApiUrl()}/address/match`, {
        Cdpostal: String(state.address.zip),
        Localite: state.address.area,
        Rue: state.address.street,
        NumRue: state.address.streetNumber ? state.address.streetNumber : '',
        CodePays: 'BE',
      })
      .then(({ data }) => {
        if (state.address.existingAddress) {
          commit('SET_COORDINATES', data.data[0].gPS.milieuBatiment)
        } else {
          commit('SET_COORDINATES', data.data[0].gPS.projectionFacade)
        }
      })
      .catch(error => {
        logger.error('[NV RACC] Match address error')
      })
  },

  GET_PRICES({ state }) {
    const meters = state.meters
      .filter(meter => meter.energy === 'electricity')
      .filter(meter => meter.power !== POWER_LIMIT)
    const data = {
      Liste: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as { Liste: any[] }

    meters.forEach(m => {
      const cpt = {
        IdCpt: m.id,
        NbPhase: m.phases,
        ExclNuit: m.nightOption ? 1 : 0,
        Amperage: m.amperage,
        Puissance: m.power,
      }

      data.Liste.push(cpt)
    })

    return axios.post(`${getApiUrl()}/pricing`, data)
  },
  async SET_CONNECTION_SETTINGS({ state, commit }) {
    const headers: AxiosRequestConfig = {
      headers: {
        SessionId: auth.sessionId,
      },
    }

    const users: any = {
      Firstname: state.personalData.firstname,
      Lastname: state.personalData.lastname,
      Email: state.personalData.email,
      Phone: `${state.personalData.phoneCode}${state.personalData.phone}`,
      Adresse: {
        Rue: state.personalData.street,
        NumRue: state.personalData.streetNumber,
        Cdpostal: state.personalData.zip,
        Localite: state.personalData.area,
        CodePays: state.personalData.country,
      },
      Preferences: {
        // eslint-disable-next-line
        'com_dossier_racc_mail': true
      },
    }

    await axios.patch(`${getApiUrl()}/users`, users, headers).catch(err => {
      logger.error(
        connectionLogs.addErrors(`[RACC] SET_CONNECTION_SETTINGS: ${err}`)
      )
    })
  },
  async SEND_REQUEST_STANDARD({ commit, state }) {
    const asyncProcess = await axios
      .post(
        `${getApiUrl()}/async`,
        {
          Method: 'POST',
          Path: '/demande_travaux/demande',
          PathParameters: {},
          Params: {},
          Data: getRequestWs35(state),
          Type: 'external',
        },
        { headers: { 'Accept-Language': lang() } }
      )
      .catch(err => {
        connectionLogs.addErrors(`[RACC] Async request problem: ${err.message}`)
        gtm.sendEvent({
          event: 'error',
          errorType: `RACC-ASYNC-${err.message}`,
        } as Event)
        commit('SET_SENDED', true)
      })

    if (
      asyncProcess &&
      asyncProcess.data.externalStatusCode >= 200 &&
      asyncProcess.data.externalStatusCode <= 299
    ) {
      const emeters = state.meters.find(meter => meter.energy === 'electricity')
      if (emeters) {
        const numDossier = `${asyncProcess.data.processId}_01`
        await sendFiles(state.files, numDossier)
      }

      const gmeters = state.meters.find(meter => meter.energy === 'gaz')
      if (gmeters) {
        const numDossier = `${asyncProcess.data.processId}_02`
        await sendFiles(state.files, numDossier)
      }

      commit('SET_FINAL_STATUS', true)
      commit('SET_SENDED', true)
      connectionLogs.sendLogs(asyncProcess.data.processId)
    } else {
      connectionLogs.addErrors('[RACC] Async request problem')
      gtm.sendEvent({
        event: 'error',
        errorType: 'RACC-ASYNC',
      } as Event)
      commit('SET_SENDED', true)
      connectionLogs.sendLogs()
    }
  },

  async SEND_REQUEST_SPECIFIC({ commit, state }) {
    await getDescriptionFiles(state.files).then(res => {
      const description = getDescriptionContact(state)
      axios
        .post(`${getApiUrl()}/contact`, {
          Section:
            state.processus === 'edit'
              ? 'MODIFICATION RACCORDEMENT'
              : 'NOUVEAU RACCORDEMENT',
          SousSection: getSubSection(state.subProcessus, state.meters),
          Nom: state.personalData.lastname,
          Prenom: state.personalData.firstname,
          Email: state.personalData.email,
          Phone: `${state.personalData.phoneCode}${state.personalData.phone}`,
          Description: description,
          Files: res,
        })
        .then(res => {
          commit('SET_FINAL_STATUS', true)
          commit('SET_SENDED', true)
          connectionLogs.sendLogs()
        })
        .catch(err => {
          connectionLogs.addErrors(
            `[RACC] Contact request errors: ${err.message}`
          )
          gtm.sendEvent({
            event: 'error',
            errorType: `RACC-WS77-${err.message}`,
          } as Event)
          commit('SET_SENDED', true)
          connectionLogs.sendLogs()
        })
    })
  },
  RESET_MODIF_METERS({ commit, state }, energy) {
    state.request.deplaCpt = false
    state.request.typeSmart = null
    state.existingMeters.forEach(m => {
      const meter = state.meters.find(el => el.id === m.id)
      if (meter && meter.energy === energy) {
        commit('UPDATE_METER', JSON.parse(JSON.stringify(m)))
      }
    })
    state.meters
      .filter(m => m.supp)
      .forEach(m => {
        if (m.energy === energy) {
          commit('REMOVE_METER', m.uuid)
        }
      })
  },
}

const getters: GetterTree<ConnectionState, RootState> = {
  currentScreenIndex: (state, getters) =>
    getters.currentStep
      ? getters.currentStep.screens.indexOf(state.currentScreen)
      : undefined,
  currentStep: state => state.steps.find(step => step.id === state.currentStep),
  nextStep: (state, getters) =>
    state.steps[state.steps.indexOf(getters.currentStep) + 1] ?? null,
  previousStep: (state, getters) =>
    state.steps[state.steps.indexOf(getters.currentStep) - 1] ?? null,
  metersAll: state =>
    state.meters.filter(meter => meter.energy === state.currentEnergyType),
  metersCount: state =>
    state.meters.filter(meter => meter.energy === state.currentEnergyType)
      .length,
  specificMetersCount: state => state.specificMeters.length,
  previousMeter: state => (currentMeter: ConnectionMeter) =>
    state.meters.find(
      meter =>
        meter.energy === currentMeter.energy && meter.id === currentMeter.id - 1
    ),
  lastMeter: state =>
    last(
      state.meters.filter(meter => meter.energy === state.currentEnergyType)
    ),
  lastSpecificMeter: state => last(state.specificMeters),
  stateForLogs: state => {
    const stateForLogs = Object.assign({}, state)
    delete stateForLogs.i18n

    return stateForLogs
  },
}

export const connection: Module<ConnectionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
