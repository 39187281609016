import axios, { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { MobilityTerminalState, RootState } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import gtm, { Event } from '@/composables/gtm'

/* eslint-disable */
const state: MobilityTerminalState = {
    TypeDemande: {
        Libelle: '',
        Valeur: '',
      },
      Demandeur: {
        Nom: '',
        Prenom: '',
        Email: '',
        Telephone: '',
      },
      Installateur: {
        Nom: '',
        Email: '',
        Telephone: '',
      },
      Entreprise: {
        Numero: '',
        Nom: '',
        Acronyme: '',
        FormeJuridique: '',
      },
      Borne: {
        Adresse: {
          Rue: '',
          Numero: '',
          CodePostal: '',
          Commune: '',
          Pays: 'Be',
        },
        Ean: '',
        Date: null,
        Marque: '',
        Modele: '',
        Puissance: '',
        Utilisation: {
          Libelle: '',
          Valeur: '',
        },
        Bidirectionnelle: false,
        Serial: '',
        Photo: '',
      },
      custom: {
        installer: false,
        profileType: '',
        Serial: [],
        Photos: [],
        inputUpload: [],
        terminalQty: 1,
        Request: 'none',
        PhoneOwner:'',
        PhoneInstaller:'',
        Power:'',
        OtherPower: '',
        currentStep: 0,
        declareMore: false,
      },
}

const getters: GetterTree<MobilityTerminalState, RootState> = {
  getStepOne: state => state.TypeDemande,
  getState: state => state,
  getRequest: state => state.custom.Request,
  getCurrentStep: state => state.custom.currentStep
}

const mutations: MutationTree<MobilityTerminalState> = {
    UPDATE_STEP_ONE(state, payload) {
        state.TypeDemande ={...payload}
      },
    UPDATE_CURRENT_STEP(state, payload) {
      state.custom.currentStep = payload
    },
    UPDATE_TERMINAL(state, payload) {
        Object.keys(payload).forEach( key => {
            state[key] = {...payload[key]}
        })

    },
    UPDATE_EAN(state, payload) {
      state.Borne.Ean = payload

  },
    UPDATE_CUSTOM(state, payload) {
      Object.keys(payload).forEach( item => {
        state.custom[item] = payload[item]
      })

    },
    UPDATE_INSTALER(state, payload) {
      state.Installateur = {...payload}

    },
    UPDATE_DATE(state, payload) {
        state.Borne.Date = payload
    },
    UPDATE_BORNE(state, payload) {
      state.Borne = {...payload}

    },
    UPDATE_POWER(state, payload) {
      state.Borne.Puissance = payload

    },
    UPDATE_USAGE(state, payload) {
      state.Borne.Utilisation = {...payload}

    },
    UPDATE_SERIAL(state, payload) {
      state.custom.Serial.push(payload)

    },
    DELETE_SERIAL(state, payload) {
      state.custom.Serial.pop()

    },
    UPDATE_PHOTO(state, payload) {
      state.custom.Photos.push(payload)

    },
    DELETE_PHOTO(state, payload) {
      if (payload !== -1) {
        state.custom.Photos.splice(payload, 1)
        state.custom.inputUpload.splice(payload, 1)
      }
    },
    UPDATE_ADRESSE_TERMINAL(state, payload) {
      state.Borne.Adresse = {...payload}
      },
    UPDATE_TERMINAL_EAN(state, payload) {
        state.Borne.Ean = payload
      },
    UPDATE_FORM_DATA_REQUEST(state, payload) {
        state.custom.Request = payload
    },
}

const actions: ActionTree<MobilityTerminalState, RootState> = {
  async submitForm({ commit, state }) {
    let request = 'success'
    let dataTerminal: any =  []

    state.custom.Serial.forEach( (s ,i) => {
      const terminal = {...state}
      terminal.Borne.Serial = s
      terminal.Borne.Photo = state.custom.Photos[i]
      delete terminal.custom
      dataTerminal.push(terminal)
    })



    try {

      const event = {
        event: 'Formulaire de declaration de borne',
        typeDeclaration: state.TypeDemande.Valeur === 'ACTIVATE' ? 'Mise en service' : 'Mise hors service',
        nombreBornes:`${dataTerminal.length}`,
      } as Event
      gtm.sendEvent(event)

      await axios.post(`${getApiUrl()}/terminal/submit`, dataTerminal)

      commit('UPDATE_FORM_DATA_REQUEST', true)
    } catch (error) {
      request = 'error'
      commit('UPDATE_FORM_DATA_REQUEST', false)
    }
  },

}

export const terminal: Module<MobilityTerminalState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
