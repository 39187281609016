import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { Notification, NotificationsState, RootState } from '@/inc/types'
import { v1 as uuidv1 } from 'uuid'

const getDefaultState = () => ({ all: [] } as NotificationsState)

const state: NotificationsState = {
  all: [],
}

const getters: GetterTree<NotificationsState, RootState> = {
  all: state => state.all,
  unread: state => state.all.filter(n => n.status === 'unread'),
  read: state => state.all.filter(n => n.status === 'read'),
  hasUnread: state => state.all.some(n => n.status === 'unread'),
  count: state => state.all.length,
}

const mutations: MutationTree<NotificationsState> = {
  ADD(state, notification: Notification) {
    if (state.all.some(n => n.id && n.id === notification.id)) {
      return
    }

    notification.uuid = uuidv1()
    notification.status = 'unread'
    state.all.push(notification)
  },
  READ(_state, notification: Notification) {
    notification.status = 'read'
  },
  UNREAD(_state, notification: Notification) {
    notification.status = 'unread'
  },
  CLEAR_ALL(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions: ActionTree<NotificationsState, RootState> = {
  async fetch({ commit }) {
    commit('CLEAR_ALL')
    const res = await axios.get(`${getApiUrl()}/notifications`)
    const notifs = res.data as Notification[]

    notifs.forEach(n => commit('ADD', n))
  },
}

export const notifications: Module<NotificationsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
