import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { MyState, RootState } from '@/inc/types'
import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from '@/inc/app.config'

const state: MyState = {
  // meta loading...
  loading: false,
  i18n: {},
  hasData: {
    index: false,
    meter: false,
    billing: false,
    invoice: false,
    smart: false,
  },
  smartData: {},
  files: [],
  download: false,
  consentement: [],
  i18nSuivi: {},
  datepicker: {
    idDossier: '',
    dateSelected: '',
  },
}

const getters: GetterTree<MyState, RootState> = {
  isLoading: state => state.loading,
  hasData: state => state.hasData,
  has: state => (key: string) => state.hasData[key],
  isSend: state => state.files,
  isDatepicker: state => state.datepicker,
}

const mutations: MutationTree<MyState> = {
  START_LOADING(state) {
    state.loading = true
  },
  STOP_LOADING(state) {
    state.loading = false
  },
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_I18NSUIVI(state, payload) {
    state.i18nSuivi = payload
  },
  SET_DATA(state, payload) {
    state.hasData = { ...payload }
  },
  SET_FILE(state, payload) {
    state.files.push({ ...payload })
  },
  SET_DATEPICKER(state, payload) {
    state.datepicker = { ...payload }
  },
  SET_CONSENTEMENT(state, payload) {
    state.consentement.push({ ...payload })
  },
  SET_DOWNLOAD(state) {
    state.download = true
  },
  UPDATE_DATA(state, { key, value }) {
    state.hasData[key] = value
  },
  UPDATE_STATE(state, { key, value }) {
    state[key] = value
  },
  UPDATE_FILE(state, payload) {
    /* eslint-disabled */
    if (payload) {
      state.files.map(key => {
        if (
          key.idCompteur === payload.idCompteur &&
          key.idDossier === payload.idDossier &&
          key.idFile === payload.idFile &&
          key.index === payload.index
        ) {
          key.uploadAndSend = payload.uploadAndSend
        }

        return key
      })
    }
  },
}

const actions: ActionTree<MyState, RootState> = {
  async fetchI18n({ commit }) {
    const res: AxiosResponse = await axios.get(`${getApiUrl()}/myresa`)
    const response: AxiosResponse = await axios.get(
      `${getApiUrl()}/translations/suivi`
    )
    commit('SET_I18N', res.data)
    commit('SET_I18NSUIVI', response.data)
  },
  async fetchI18nOnly({ commit }) {
    const res: AxiosResponse = await axios.get(`${getApiUrl()}/myresa`)
    commit('SET_I18N', res.data)
  },
  async fetchSmartData({ commit, state }, { ean, numCpt }) {
    await axios
      .get(`${getApiUrl()}/portP1?Ean=${ean}&NumCpt=${numCpt}`)
      .then(res => {
        commit('UPDATE_STATE', { key: 'smartData', value: res.data.liste[0] })
        commit('UPDATE_DATA', { key: 'smart', value: true })
      })
  },
}

export const my: Module<MyState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
