






































































































































































import OverlayPanel from '@/components/OverlayPanel.vue'

import { GVideo } from '@/inc/types/components'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { Header } from '@/inc/types'

export default defineComponent({
  name: 'g-video',
  components: {
    OverlayPanel,
  },
  props: {
    content: {
      type: Object as () => GVideo,
      required: true,
    },
  },

  setup(props, ctx) {
    const imgUrl = `${process.env.VUE_APP_API_DOMAIN}`
    const isVisible = ref(false)
    const hide = () => (isVisible.value = false)
    const show = () => {
      ctx.root.$emit('overlay-panel:open')
      isVisible.value = true
    }

    onMounted(() => {
      ctx.root.$on('overlay-panel:close', hide)
    })

    return { imgUrl, ctx, isVisible, hide, show }
  },
})
