
























































































































































import { defineComponent } from '@vue/composition-api'
import { GCtaRich } from '@/inc/types'

export default defineComponent({
  name: 'g-cta-custom',
  components: {},
  props: {
    content: {
      type: [Array, Object as () => GCtaRich],
      required: true,
    },
    isImg: {
      type: Boolean,
      default: true,
    },
  },

  setup(_props, ctx) {
    const { VUE_APP_API_DOMAIN } = process.env

    const onClick = url => {
      if (url) {
        ctx.root.$router
          .push(url.slice(VUE_APP_API_DOMAIN?.length))
          .catch(e => true)
      }
    }

    return {
      onClick,
    }
  },
})
