


































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'footerChatbot',

  setup() {
    const { currentLang, chrome } = useGetters(['currentLang', 'chrome'])
    const supplier = ref('')
    const salesforceConfigs = ref()
    const salesforceInitOptions = ref({})

    const setChatbotData = () => {
      salesforceConfigs.value = chrome.value.chatbot.salesforceConfigs
      supplier.value = chrome.value.chatbot.supplier
      chrome.value.chatbot.salesforceConfigs.init.options.forEach(option => {
        salesforceInitOptions.value[option.key] = option.value
      })
    }

    watch(chrome, () => {
      setChatbotData()
    })

    onMounted(() => {
      setChatbotData()
    })

    return {
      currentLang,
      salesforceConfigs,
      salesforceInitOptions,
      supplier,
    }
  },
})
