import { groupBy } from 'rambda'
import logger from 'consola'
import { POWER_LIMIT } from '@/composables/const'

export type PhasesSlug = 'MONO' | 'TRI' | 'HT'
type ValueType = 'amperage' | 'power'

export interface ElectricMeter {
  phases: number
  phasesSlug: PhasesSlug
  phasesLabel: string
  label: string
  amperage: number | string
  amperageUnit: string
  power: number | string
  powerUnit: string
}

export interface ElectricMeterValue {
  phases: number
  phasesSlug: PhasesSlug
  data: number | string
  label: string
  unit: string
}

export const data: ElectricMeter[] = [
  {
    phases: 1,
    phasesSlug: 'MONO',
    phasesLabel: 'Monophasé',
    label: 'Essentiel',
    amperage: 40,
    amperageUnit: 'A',
    power: 9.2,
    powerUnit: 'kVA',
  },
  // {
  //   phases: 1,
  //   phasesSlug: 'MONO',
  //   phasesLabel: 'Monophasé',
  //   label: 'Monophasé (1N400) - 50 Ampères / 11.5 kVA',
  //   amperage: 50,
  //   amperageUnit: 'A',
  //   power: 11.5,
  //   powerUnit: 'kVA',
  // },
  {
    phases: 1,
    phasesSlug: 'MONO',
    phasesLabel: 'Monophasé',
    label: 'Confort',
    amperage: 63,
    amperageUnit: 'A',
    power: 14.5,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'Essentiel',
    amperage: 16,
    amperageUnit: 'A',
    power: 11.1,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'Confort',
    amperage: 20,
    amperageUnit: 'A',
    power: 13.9,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'Confort +',
    amperage: 25,
    amperageUnit: 'A',
    power: 17.3,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'Power',
    amperage: 32,
    amperageUnit: 'A',
    power: 22.2,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'Power +',
    amperage: 40,
    amperageUnit: 'A',
    power: 27.7,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'PRO 35',
    amperage: 50,
    amperageUnit: 'A',
    power: 34.6,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'PRO 44',
    amperage: 63,
    amperageUnit: 'A',
    power: 43.6,
    powerUnit: 'kVA',
  },
  {
    phases: 4,
    phasesSlug: 'TRI',
    phasesLabel: 'Tétraphasé',
    label: 'PRO +',
    amperage: '+ 63',
    amperageUnit: 'A',
    power: POWER_LIMIT,
    powerUnit: 'kVA',
  },
]

export const getValues = (): ElectricMeterValue[] =>
  data.map(meter => ({
    phases: meter.phases,
    phasesSlug: meter.phasesSlug,
    data: meter.power,
    unit: meter.powerUnit,
    label: `${meter.label} </br> ${meter.power.toString().replace('.', ',')}
      ${meter.powerUnit} / ${meter.amperage.toString().replace('.', ',')}
      ${meter.amperageUnit}`,
  }))

export const getValuesByPhases = () =>
  groupBy(meter => meter.phasesSlug, getValues()) as {
    [key in PhasesSlug]: ElectricMeterValue[]
  }

export const getDetails = (
  phasesSlug: PhasesSlug | string,
  power: number | string
) => data.find(m => m.phasesSlug === phasesSlug && m.power === power)

export const dotToComma = power => String(power).replace('.', ',')

export const getPhaseSlug = phases => {
  if (phases === '1N') {
    return 'MONO'
  }

  if (phases === '2') {
    return 'MONO'
  }

  return 'TRI'
}

export const getNamePhaseSlug = phasesSlug => {
  if (phasesSlug === 'MONO') {
    return 'Monophasé'
  }

  return 'Triphasé'
}

export const getTarif = tarif => {
  if (tarif === 'Double tarif') {
    return false
  }

  return true
}

export const getTarifSlug = (nightOption, tarif) => {
  if (nightOption && !tarif) {
    return 'EXN_DT'
  } else if (nightOption && tarif) {
    return 'EXN_ST'
  } else if (!tarif && !nightOption) {
    return 'DT'
  }

  return 'ST'
}

export default data
