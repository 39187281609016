





















































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy from 'epic-slidy'

export default defineComponent({
  name: 'g-gallery',
  props: {},
  setup() {
    const gallery = ref<HTMLElement | null>(null)

    onMounted(() => {
      const transition = (
        currentSlide: HTMLElement | HTMLElement[],
        newSlide: HTMLElement | HTMLElement[]
      ) =>
        new Promise<void>(resolve => {
          const tl = gsap.timeline({
            onComplete: () => {
              resolve()
            },
          })

          tl.add('transition')
            .set(newSlide, {
              opacity: 0,
              zIndex: 3,
            })
            .to(
              currentSlide,
              {
                duration: 0.5,
                opacity: 0,
                ease: 'power4.inOut',
              },
              'transition'
            )
            .to(
              newSlide,
              {
                duration: 0.5,
                opacity: 1,
                ease: 'power4',
              },
              'transition'
            )
        })

      const gallerySlider = new Slidy(gallery.value as HTMLElement, {
        controls:
          // eslint-disable-next-line max-len
          '<svg width="36px" height="36px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><path d="M26.1699003,12.0424082 C26.5298877,11.678283 27.0963374,11.6502734 27.4880883,11.9583793 L27.5821659,12.0424082 L32.5752788,17.0929133 L32.6161389,17.1367695 L32.6161389,17.1367695 L32.6841231,17.2231365 L32.6841231,17.2231365 L32.7350326,17.303479 L32.7350326,17.303479 L32.7949445,17.4278056 L32.7949445,17.4278056 L32.8264433,17.5188863 L32.8264433,17.5188863 L32.841125,17.5744453 L32.841125,17.5744453 L32.8565819,17.6559542 L32.8565819,17.6559542 L32.8660252,17.7473161 L32.8660252,17.7473161 L32.8660252,17.8670724 L32.8660252,17.8670724 L32.8520924,17.9859922 L32.8520924,17.9859922 L32.8264491,18.0954287 L32.8264491,18.0954287 L32.7844981,18.2116528 L32.7844981,18.2116528 L32.7362501,18.3087086 L32.7362501,18.3087086 L32.6801111,18.3967274 L32.6801111,18.3967274 L32.6483824,18.4389292 L32.6483824,18.4389292 L32.5752788,18.5214118 L27.5821659,23.5719169 C27.1921795,23.9663858 26.5598866,23.9663858 26.1699003,23.5719169 C25.7799139,23.1774479 25.7799139,22.5378873 26.1699003,22.1434183 L29.4574435,18.8165565 L4.25206612,18.8172635 C3.73993666,18.8172635 3.31784753,18.427324 3.26016199,17.9249617 L3.25344353,17.8071625 C3.25344353,17.2891465 3.63895198,16.8622058 4.13560563,16.8038572 L4.25206612,16.7970615 L29.4574435,16.7965565 L26.1699003,13.4709068 C25.8099128,13.1067815 25.7822215,12.5338209 26.0868262,12.1375671 L26.1699003,12.0424082 Z" /></svg>',
        drag: true,
        namespace: 'gallery',
        pagination: '-',
        swipe: true,
        transition,
      })
      gallerySlider.init()
    })

    return {
      gallery,
    }
  },
})
