





























import Accordion from '@/components/Accordion.vue'
import CtaBanner from '@/components/CtaBanner.vue'
import CtaRich from '@/components/g/CtaRich.vue'
import DualText from '@/components/DualText.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Steps from '@/components/home/Steps.vue'
import TextColor from '@/components/TextColor.vue'
import TextEnum from '@/components/TextEnum.vue'
import TextOnly from '@/components/TextOnly.vue'
import TextTitle from '@/components/TextTitle.vue'
import TripleCta from '@/components/TripleCta.vue'
import Video from '@/components/g/Video.vue'
import Wizard from '@/components/Wizard.vue'
import Wysiwyg from '@/components/g/Wysiwyg.vue'

import { defineComponent } from '@vue/composition-api'
import { Pages, Tip } from '@/inc/types'

/* eslint-disable camelcase */
const subpages: Pages = {
  accordion: Accordion,
  cta_banner: CtaBanner,
  ctaRich: CtaRich,
  homeSteps: Steps,
  related_links: RelatedLinks,
  text_color: TextColor,
  text_enum: TextEnum,
  text_only: TextOnly,
  text_rich: DualText,
  text_shortcodes: Wysiwyg,
  title: TextTitle,
  triple_cta: TripleCta,
  v_header: Header,
  v_video: Video,
  wizard: Wizard,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'TipPanel',
  props: {
    content: {
      type: Object as () => Tip,
      required: true,
    },
  },

  setup() {
    const isWrapped = cpt => {
      const wrapped = [
        'cta_rich',
        'related_links',
        'v_video',
        'text_shortcodes',
      ]

      return wrapped.some(item => item === cpt)
    }

    return {
      isWrapped,
      subpages,
    }
  },
})
