interface Gtm {
  sendEvent(event: Event)
}

type EventType =
  | 'virtualPageView'
  | 'transaction'
  | 'error'
  | 'formulaire-intervention-submission'
  | 'Déclaration de panne éclairage public'
  | 'Formulaire période de relève d’index'
  | 'Formulaire de contact - Aide'
  | 'changeProfil'
  | 'Encodage Index'
  | 'Suivi Pannes EP'
  | 'Suivi Pannes Electricite'
  | 'Formulaire de declaration de borne'
  | 'MyRESA_events'

type ProfileType = 'COMMUNES'

export interface Event {
  event?: EventType
  virtualPageView?: string
  ecommerce?: Ecommerce
  errorType?: string
  typeDePanne?: string
  commune?: string
  contactCategory?: string
  contactSubcategory?: string
  profil?: ProfileType
  typeEncodage?: string
  raisonEncodage?: string
  typeDeSuivi?: string
  typeDeclaration?: string
  nombreBornes?: string
  eventLabel?: string
}

export interface Purchase {
  actionField: Transaction
  products: Product[]
}

export interface Transaction {
  id: string
  revenue: string
  tax: string
  shipping: string
}

export interface Product {
  name: string
  id: string
  price: string
  category: string
  variant: string
  quantity: number
}

export interface Ecommerce {
  purchase: Purchase
}

const _push = data => window.dataLayer.push(data)

const gtm: Gtm = {
  sendEvent(event: Event) {
    return _push(event)
  },
}
export default gtm
