


















































import { PanelAccordion } from '@/inc/types'
import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'panel-accordion',
  components: {},
  props: {
    content: {
      type: Object as () => PanelAccordion,
      required: true,
    },
  },
  setup(props, ctx) {
    const emitValue = () => {
      ctx.emit('toggle')
    }

    const myState = {
      ...useState('commune', ['i18n']),
    }

    return {
      emitValue,
      i18n: myState.i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
