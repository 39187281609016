









































import ChromeLang from '@/components/chrome/Lang.vue'
import MegaMenu from '@/components/chrome/MegaMenu.vue'

import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import SearchWP from '@/components/chrome/search/SearchWP.vue'
import CustomLink from '@/components/g/CustomLink.vue'

const {
  RESA_ENV,
  VUE_APP_MYRESA_DOMAIN,
  VUE_APP_TASK,
  VUE_APP_VERSION: version,
} = process.env

export default defineComponent({
  name: 'chrome-header',
  components: {
    ChromeLang,
    MegaMenu,
    SearchWP,
    CustomLink,
  },

  setup(_props, ctx) {
    const { currentLang } = useGetters(['currentLang'])
    const resource = useState(['resource'])
    const disableLangSearch = ref(false)
    const myResaPath = 'dashboard/'
    const myResaUrl = ref(
      `${VUE_APP_MYRESA_DOMAIN}/${currentLang.value}/${myResaPath}`
    )

    watch(currentLang, () => {
      if (currentLang.value) {
        myResaUrl.value = `${VUE_APP_MYRESA_DOMAIN}/${currentLang.value}/${myResaPath}`
      } else {
        myResaUrl.value = `${VUE_APP_MYRESA_DOMAIN}/fr/${myResaPath}`
      }
    })

    const disableLang = () => {
      if (
        ctx.root.$route.path.includes('neuer-formular') ||
        ctx.root.$route.path.includes('anderung-formular')
      ) {
        return true
      }
      if (
        ctx.root.$route.path.includes('formulaire-nouveau') ||
        ctx.root.$route.path.includes('formulaire-modification')
      ) {
        return true
      }

      return false
    }

    // (JPI) TEMP: ROMOVE WHEN DUTCH IS AVAILABLE
    const languagesAvailable = computed(() => {
      if (Object.keys(resource.resource.value.languages).length > 1) {
        return true
      }

      return false
    })
    ctx.root.$on('searchwp:open', () => {
      disableLangSearch.value = true
    })
    ctx.root.$on('searchwp:close', () => {
      disableLangSearch.value = false
    })

    return {
      languagesAvailable,
      currentLang,
      myResaUrl,
      version,
      disableLang,
      disableLangSearch,
    }
  },
})
