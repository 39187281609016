





































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import PanelUser from '@/components/commune/header/PanelUser.vue'

export default defineComponent({
  name: 'my-commune-menu',
  components: {
    PanelUser,
  },
  setup(_props, ctx) {
    const { commune } = useState('user', ['commune'])
    const isOpen = ref(false)
    const body = ref<HTMLElement | null>(null)
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    const showPanelUser = () => {
      resetPanel()

      showPanel({
        component: {
          template: PanelUser,
        },
        content: {},
      })
      isOpen.value = true
      body.value!.style.overflow = 'hidden'
    }

    onMounted(() => {
      body.value = document.body
    })

    const sendEmit = () => {
      ctx.root.$emit('menu:commune:click')
    }

    return {
      showPanelUser,
      isOpen,
      sendEmit,
    }
  },
})
