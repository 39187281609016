import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=1e526a65&scoped=true&"
import script from "./Video.vue?vue&type=script&lang=ts&"
export * from "./Video.vue?vue&type=script&lang=ts&"
import style0 from "./Video.vue?vue&type=style&index=0&id=1e526a65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e526a65",
  null
  
)

export default component.exports