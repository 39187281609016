import axios, { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import {
  CommuneRole,
  CommuneState,
  CommuneUser,
  ConsommationEclairagePublic,
  RootState,
} from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

/* eslint-disable */
const state: CommuneState = {
  // Traductions
  i18n: {},
  usersCommune: [],
  userEdit: '',
  nbrPannesEpEnCours: '',
  nbrPannesElecEnCours: '',
  nbrPannesElecPlan: '',
  roles: [],
  records: [],
  eans: [],
  eansSmart: [],
  ep: {
    nbrEpLed: '',
    nbrEpNonLed: '',
    consomationAnnee: '',
    historique: []
  },
  isElec: false,
  isEp: false,
  isGaz: false,
}

const getters: GetterTree<CommuneState, RootState> = {
  getI18n: state => state.i18n,
  getUsers: state => state.usersCommune,
  getNbrPannesEpEnCours: state => state.nbrPannesEpEnCours,
  getNbrPannesElecEnCours: state => state.nbrPannesElecEnCours,
  getNbrPannesElecPlan: state => state.nbrPannesElecPlan,
  getLabelRoles: state => state.roles,
  getTableRoles: state => {
    let table: string[] = []
    for (const item of state.roles) {
      table.push(item.id)
    }
    return table
  },
  getRecords: state => state.records,
  getEans: state => state.eans,
  getEansSmart: state => state.eansSmart,
  getEp: state => state.ep,
  getIsElec: state => state.isElec,
  getIsEp: state => state.isEp,
  getIsGaz: state => state.isGaz,
}

const mutations: MutationTree<CommuneState> = {
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_EP(state, payload: ConsommationEclairagePublic) {
    state.ep = payload
  },
  SET_EDIT_USER(state, payload) {
    state.userEdit = payload
  },
  SET_ROLES(state, payload: CommuneRole[]) {
    state.roles = payload
  },
  SET_RECORDS(state, payload) {
    state.records = payload
  },
  SET_USERS(state, payload: CommuneUser[]) {
    state.usersCommune = []
    state.usersCommune = payload
    state.usersCommune.forEach(key => {
      payload.map(i => {
        if (i.nom === key.nom && key.prenom === i.prenom) {
          {
            key.fullname = i.nom + ' ' + i.prenom
          }
          return key
        }
      })
    })
  },
  SET_PANNES_EP_UNPLANNED(state, payload) {
    state.nbrPannesEpEnCours = payload
  },
  SET_PANNES_ELEC_UNPLANNED(state, payload) {
    state.nbrPannesElecEnCours = payload
  },
  SET_PANNES_ELEC_PLANNED(state, payload) {
    state.nbrPannesElecPlan = payload
  },
  SET_EANS(state, payload) {
    state.eans = payload
  },
  SET_EANS_SMART(state, payload) {
    state.eansSmart = payload
  },
  SET_ISELEC(state, payload) {
    state.isElec = payload
  },
  SET_ISEP(state, payload) {
    state.isEp = payload
  },
  SET_ISGAZ(state, payload) {
    state.isGaz = payload
  },
}

const actions: ActionTree<CommuneState, RootState> = {
  FETCH_I18N({commit}) {
    axios.get(`${getApiUrl()}/town/translations`).then(response => {
      commit('SET_I18N', response.data)
    })
  },
  async FETCH_USERS({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/users`)
    commit('SET_USERS', response.data)
  },
  async FETCH_ROLES({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/roles`)
    commit('SET_ROLES', response.data)
  },
  async FETCH_RECORDS({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/works/records`)
    commit('SET_RECORDS', response.data)
  },
  FETCH_NBR_PANNES({commit}, payload) {
    const {codesPostaux} = payload

    if (codesPostaux && codesPostaux.length > 0) {
      const codes = codesPostaux.join(',')
      // Call nombre de pannes EP en cours
      axios
        .get(
          `${getApiUrl()}/outages/list?Zipcode=${codes}&EnCours=true&PageSize=10000&Ep=true`
        )
        .then(response => {
          commit('SET_PANNES_EP_UNPLANNED', response.data.data.length)
        })
        .catch(() => {
          commit('SET_PANNES_EP_UNPLANNED', '0')
        })
      // Call nombre de pannes électriques en cours
      axios
        .get(
          `${getApiUrl()}/outages/unplanned?CdPostal=${codes}&PageSize=10000&EnCours=true`
        )
        .then(response => {
          commit('SET_PANNES_ELEC_UNPLANNED', response.data.data.length)
        })
        .catch(() => {
          commit('SET_PANNES_ELEC_UNPLANNED', '0')
        })
      // Call nombre de pannes électriques plannifiées
      axios
        .get(
          `${getApiUrl()}/outages/planned?CdPostal=${codes}&PageSize=10000`
        )
        .then(response => {
          commit('SET_PANNES_ELEC_PLANNED', response.data.data.length)
        })
        .catch(() => {
          commit('SET_PANNES_ELEC_PLANNED', '0')
        })
    }
  },
  async FETCH_EANS({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/index/releves`)
    commit('SET_EANS', response.data)
  },
  async FETCH_EANS_SMART({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/index/releves?Smart=true`)
    commit('SET_EANS_SMART', response.data)
  },
  async FETCH_CONSO_EP({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/town/ep`)
    commit('SET_EP', response.data)
  },
}

export const commune: Module<CommuneState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
