/* eslint-disable quote-props */
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { MyState, RootState } from '@/inc/types'
import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from '@/inc/app.config'

interface customsField {
  label: string
  value: any
}

const state: any = {
  Section: '',
  'Sous-section': ' ',
  Reference: 0,
  Ean: '',
  Nom: '',
  Prenom: '',
  Email: '',
  Phone: '',
  Description: '',
  Files: [],
  Preference: '',
  Remarque: '',
  Adresse: {
    Commune: '',
    Numero: '',
    Rue: '',
    CodePostal: '',
  },
  Destinataire: '',
  Customs: [],
  info: {
    numberOfWeek: 0,
  },
  place: [],
  informations: {},
  nextStep: [
    { value: true },
    { value: true },
    { value: true },
    { value: true },
    { value: true },
  ],
  Request: 'none',
}

const infoState: any = {}

const getters: GetterTree<any, RootState> = {
  getInfo: state => state.info,
  getCustoms: state => state.Customs,
  getPlace: state => state.place,
  getInformations: state => state.informations,
  getAddress: state => state.Adresse,
  getNextStep: state => state.nextStep,
  getRequest: state => state.Request,
}

const mutations: MutationTree<any> = {
  SET_FORM_DATA(state, payload) {
    state.Descritpion = { ...state.Descritpion, ...payload.value }
  },
  UPDATE_FORM_RECIPIENT(state, payload) {
    state.Destinataire = payload
  },
  UPDATE_FORM_FILE(state, payload) {
    state.Files = payload
  },
  DELETE_FORM_FILE(state, payload) {
    state.Files.splice(payload, 1)
  },
  UPDATE_FORM_DATA_PLACE(state, payload) {
    state.place = payload
  },
  UPDATE_FORM_NEXT_STEP(state, payload) {
    state.nextStep[payload.currentStep].value = payload.value
  },
  // eslint-disable-next-line id-length
  UPDATE_FORM_DATA_INFORMATIONS(state, payload) {
    state.informations = { ...state.informations, ...payload }
  },
  UPDATE_FORM_DATA_ADDRESS(state, payload) {
    state.Adresse.Commune = payload.city
    state.Adresse.Rue = payload.street
    state.Adresse.CodePostal = payload.zip
    state.Adresse.Numero = payload.streetNumber
  },
  UPDATE_FORM_DATA(state, payload) {
    Object.keys(payload).forEach(data => {
      state[data] = payload[data]
    })
  },
  UPDATE_FORM_DATA_CUSTOM(state, payload) {
    Object.keys(payload).forEach((data, i) => {
      if (
        state.Customs.length &&
        state.Customs.some(custom => custom.label === data)
      ) {
        state.Customs.forEach(custom => {
          if (custom.label === data) {
            custom.value = payload[data]
          }
        })
      } else {
        state.Customs = [
          ...state.Customs,
          { label: data, value: payload[data] },
        ]
      }
    })
  },
  UPDATE_FORM_DATA_INFO(state, payload) {
    state.info = { ...state.info, ...payload }
  },
  UPDATE_FORM_DATA_REQUEST(state, payload) {
    state.Request = payload
  },
}

const actions: ActionTree<any, RootState> = {
  async fetchFormData({ commit }) {
    const res: AxiosResponse = await axios.get(`${getApiUrl()}/forain`)
    commit('SET_FORM_DATA', res.data)
  },
  async submitForainForm({ commit, state }) {
    let request = 'success'
    const dataForain = { ...state }
    Object.keys(dataForain.info.qty).forEach(q => {
      if (dataForain.info.qty[q] > 0) {
        dataForain.info.forainPowerConsomation.forEach(conso => {
          if (conso.id === q) {
            dataForain.Customs.push({
              label: `${conso.categorie} (${conso.id})`,
              value: dataForain.info.qty[q],
            })
          }
        })
      }
    })
    dataForain.Section = 'Nouveau raccordement'
    dataForain['Sous-section'] = 'Demande raccordement courte durée'
    delete dataForain.informations
    delete dataForain.nextStep
    delete dataForain.place
    delete dataForain.info
    delete dataForain.Request
    try {
      await axios.post(`${getApiUrl()}/forain/submit`, dataForain)

      commit('UPDATE_FORM_DATA_REQUEST', true)
    } catch (error) {
      request = 'error'
      commit('UPDATE_FORM_DATA_REQUEST', false)
    }
  },
}

export const forain: Module<any, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
