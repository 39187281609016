import { Language } from '@/inc/types'
import { logger } from '@/inc/utils'
import * as runtimeProcess from 'process'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr', 'de'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  faq: ['faq', 'faq'],
  contact: 'aide-et-contact',
  contactDe: 'hilfe-und-kontakt',
  meterWizard: 'releve-index',
  meterWizardDe: 'meine-zahlerablesung',
  news: ['news', 'aktuelles'],
  aide: ['aide-et-contact', 'hilfe-und-kontakt'],
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-TCB7D2Q',
  staging: 'GTM-NFHRPZM',
}

/**
 * Cookiebot IDs
 */
export const cookiebot = {
  dev: '7f8449a7-3f11-4686-88f7-8dee847877b6',
  prod: '31a369a6-982a-4650-86e1-ee5dcff22fc8',
}

/**
 * Options
 */
export const isUnderMaintenance = false
export const useCookiebar = false
export const favicon = {
  tabColor: '#5bbad5',
  tileColor: '#da532c',
  themeColor: '#ffffff',
}

// eslint-disable-next-line max-len, no-useless-escape
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_~!@#$%^&€£*\-+=`|\\(){}\[\]:;"'<>,.?/])[A-Za-z\d_~!@#$%^&€£*\-+=`|\\(){}\[\]:;"'<>,.?/]{8,}$/
export const phoneRegex = /^(\+)?([0-9]{0,12})$/

/**
 * RESA_ENV
 * www.resa.be | my.resa.be
 *
 * RESA_ENV is available inside the container on `yarn start`, not during `yarn build`
 * It is get from `runtimeProcess`
 * Locally, if you need it, you have to pass it: `RESA_ENV=my.resa.be yarn serve`
 * It will be available through `process`
 * If nothing defined and client-side…, it will use `hostname`
 * because there is no `window` on server-side…
 */
let RESA_ENV = runtimeProcess.env.RESA_ENV || process.env.RESA_ENV
let RESA_LOCATION = runtimeProcess.env.RESA_ENV || process.env.RESA_ENV

if (!RESA_ENV && process.env.VAPPER_TARGET === 'client') {
  RESA_ENV = window.location.hostname
  RESA_LOCATION = window.location.pathname
}

export { RESA_ENV, RESA_LOCATION }
