import { groupBy } from 'rambda'

import { data } from '@/inc/data'
import { POWER_LIMIT } from '../const'

interface Slider {
  label: string
  isActive: boolean
}

interface Input {
  label: string
  tips?: string
  value: number | null
  id: string
}

interface Checkblock {
  title: string
  subtitle: string
  value: number
}

interface CheckblocksGroup {
  title: string
  checkblocks: Checkblock[]
  isActive: Checkblock | null
}

export interface Section {
  title?: string
  slider: Slider
  checkblocksGroup?: CheckblocksGroup
  inputs?: Input[]
  total: number
  id: string
}

const checkBlockValues = {
  normal: 7.4,
  middle: 22,
  fast: 63,
}

interface TriSuppMeter {
  amperage: number
  power: number
}

const triSupp: TriSuppMeter[] = [
  { amperage: 50, power: 34.6 },
  { amperage: 63, power: 43.6 },
  { amperage: 66, power: 45.7 },
  { amperage: 69, power: 47.8 },
  { amperage: 72, power: 49.9 },
  { amperage: 75, power: 52 },
  { amperage: 80, power: 55.4 },
]

// Construction d'un dictionnaire Section avec les données provenant de l'api.
export const getFluidSectionsUsage = sections => {
  const fluidSections: Section[] = []
  for (const [sectionName, section] of Object.entries(sections) as any) {
    const slider: Slider = { label: section.slider, isActive: false }
    const fluidSection: Section = {
      title: section.title || '',
      slider,
      total: 0,
      id: sectionName,
    }
    if (section.components.inputs !== undefined) {
      const inputs: Input[] = []
      for (const [inputName, input] of Object.entries(
        section.components.inputs
      ) as any) {
        inputs.push({
          label: input.label,
          value: null,
          ...(input.tips !== undefined && { tips: input.tips }),
          id: inputName,
        } as Input)
      }
      fluidSection.inputs = inputs
    }

    let checkblocksGroup: CheckblocksGroup
    if (section.components.checkblocks !== undefined) {
      const checkblocks: Checkblock[] = []
      for (const [checkblockName, checkblock] of Object.entries(
        section.components.checkblocks
      ) as any) {
        checkblocks.push({
          title: checkblock.title,
          subtitle: checkblock.subtitle,
          value: checkBlockValues[checkblockName],
        } as Checkblock)
      }
      // eslint-disable-next-line
      checkblocksGroup = { title: section.components.subtitle, checkblocks, isActive: null } as CheckblocksGroup
      fluidSection.checkblocksGroup = checkblocksGroup
    }

    fluidSections.push(fluidSection)
  }

  return fluidSections
}

// Donne le total des inputs/checkblocks selectionné d'une section
export const getSectionTotal = (section: Section) => {
  let total = 0
  if (section.inputs) {
    section.inputs.forEach(input => {
      if (input.value) {
        total += parseFloat(String(input.value).replace(',', '.'))
      }
    })
  }

  if (section.checkblocksGroup && section.checkblocksGroup.isActive) {
    total += parseFloat(String(section.checkblocksGroup.isActive.value))
  }

  return total
}

export const getTriSupp = (total: number) => {
  const tri = triSupp.find(t => t.power > total)
  if (tri) {
    return tri
  }

  const amps = Math.round((total / (Math.sqrt(3) * 400)) * 1000)

  return { amperage: amps, power: total } as TriSuppMeter
}

// Definit par Resa
export const coefFoissonement = 0.8
// Donne les compteurs électriques suggérés (1 MONO et 1 TRI).
// Si le type de demande est standard =>
// return les plus petits compteurs
// Si le type de demande est specific =>
// return le compteur supérieur au total des inputs (power) * coefFoissonement + Checkblock (power)
export const getSuggestions = (power: number) => {
  const suggestions: any = groupBy(meter => meter.phasesSlug, data)

  Object.keys(suggestions).forEach(phase => {
    suggestions[phase] = suggestions[phase].filter(
      meter => meter.power >= power
    )

    // Si pas de compteur avec une puissance supérieur aux inputs => proposer le TRI + de 27,7kVA
    if (suggestions[phase].length === 0 && phase === 'TRI') {
      suggestions[phase] = data.find(meter => meter.power === POWER_LIMIT)
    } else if (suggestions[phase].length > 0) {
      suggestions[phase] = suggestions[phase].reduce((p, v) =>
        p.power < v.power ? p : v
      )
    } else {
      delete suggestions[phase]
    }
  })

  return suggestions
}
