




















































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { GCtaRich } from '@/inc/types'

export default defineComponent({
  name: 'g-cta-rich',
  components: {},
  props: {
    content: {
      type: [Array, Object as () => GCtaRich],
      required: true,
    },
    isImg: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const imgUrl = `${process.env.VUE_APP_API_DOMAIN}`

    return {
      imgUrl,
    }
  },
})
