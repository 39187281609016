
























































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { Header } from '@/inc/types'

import Breadcrumb from '@/components/Breadcrumb.vue'

export default defineComponent({
  name: 'v-header',
  components: {
    Breadcrumb,
  },
  props: {
    content: {
      type: Object as () => Header,
      required: true,
    },
    img: {
      src: String,
      required: false,
    },
    btn: {
      label: String,
      required: false,
    },
  },

  setup(props, ctx) {
    const modifier = props.content.mode ? `header--${props.content.mode}` : ''
    const imgUrl = props.content.picture?.rawUrl
      ? `${process.env.VUE_APP_API_DOMAIN}${props.content.picture.rawUrl}`
      : ''

    const onClick = () => ctx.root.$emit('header:button-click')

    return {
      imgUrl,
      modifier,
      onClick,
    }
  },
})
