









































// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defineComponent, createElement as h } from '@vue/composition-api'
import { GLink } from '@/inc/types'

const props = {
  url: { type: String },
  target: { type: String },
}
const LinkAnchor = defineComponent({
  name: 'link-anchor',
  props,
  setup: (props, ctx) => () => <a href={props.url}>{ctx.slots.default()}</a>,
})

const LinkRouterLink = defineComponent({
  name: 'link-router-link',
  props,
  setup: (props, ctx) => () => (
    <router-link to={props.url}>{ctx.slots.default()}</router-link>
  ),
})

export default defineComponent({
  name: 'g-link',
  props: {
    content: {
      type: Object as () => GLink,
      default: {
        label: 'Click me',
        url: '#',
        modifiers: [],
      } as GLink,
    },
  },
  setup(props) {
    const { label, url, target, modifiers } = props.content
    const isAnchor = target !== undefined || /^http/.test(url)

    return {
      hasArrow: modifiers?.includes('arrow'),
      component: isAnchor ? LinkAnchor : LinkRouterLink,
      target: target || '',
      label,
      url,
    }
  },
})
