











































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  nextTick,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import { isCommuneResa } from '@/inc/utils'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import dayjs from 'dayjs'
import BoxColor from '@/components/design-system/box/BoxColor.vue'
import md5 from 'md5-hash'
import moment from 'moment'

export default defineComponent({
  name: 'NotificationsHeader',
  components: {
    BoxColor,
  },
  setup(props, ctx) {
    const notifications = ref<HTMLElement[]>([])
    const type = ref('')
    const title = ref('')
    const linkTitle = ref('')
    const linkUrl = ref('')
    const linkTarget = ref('')
    const publishNotification = ref(false)
    const cookieContent = ref('')
    const typeNotification = ref('')

    const close = (cookieContent, type) => {
      ctx.root.$cookie.set(`notif-${type}`, cookieContent)
      publishNotification.value = false
    }

    const { currentLang } = useGetters(['currentLang'])
    const urloutage = ref<any>(
      currentLang.value === 'de'
        ? '/de/digitale-meterstoringsregistratie/'
        : '/fr/inscription-panne-compteur-digital/'
    )

    onMounted(() => {
      publishNotification.value = false
      const today = dayjs(Date.now()).format('YYYY-MM-DD HH:mm')
      axios
        .get(`${getApiUrl()}/notifications/outages`)
        .then(async response => {
          const res = await response.data
          if (res) {
            let id = ''
            if (res?.notificationSapId) {
              id = res?.notificationSapId
              if (
                ctx.root.$cookie.get('notif-outage') &&
                ctx.root.$cookie.get('notif-outage') === id
              ) {
                publishNotification.value = false
                id = ''
              } else {
                publishNotification.value = true
                typeNotification.value = 'outage'
                cookieContent.value = id
                type.value = res?.outage?.type
                title.value = res?.outage?.title
                linkTitle.value = res?.outage?.button
                linkUrl.value = urloutage
                linkTarget.value = ''
              }
            }

            if (
              res?.message &&
              moment(res?.message?.dateStart).format('YYYY-MM-DD HH:mm') <=
                today &&
              moment(res?.message?.dateEnd).format('YYYY-MM-DD HH:mm') >=
                today &&
              id === ''
            ) {
              publishNotification.value = true
              type.value = res?.message?.type
              title.value = res?.message?.title
              linkTitle.value = res?.message?.button
              linkUrl.value = res?.message?.link?.url
              linkTarget.value = res?.message?.link?.target
              cookieContent.value = md5(
                res?.message?.dateStart +
                  res?.message?.dateEnd +
                  type.value +
                  title.value +
                  linkTitle.value +
                  linkUrl.value
              )
              typeNotification.value = 'message'
              if (
                ctx.root.$cookie.get('notif-message') &&
                ctx.root.$cookie.get('notif-message') === cookieContent.value
              ) {
                publishNotification.value = false
              }
            }
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    })

    return {
      close,
      notifications,
      isCommuneResa,
      type,
      title,
      linkTitle,
      linkUrl,
      linkTarget,
      publishNotification,
      cookieContent,
      typeNotification,
    }
  },
})
