import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { RootState } from '@/inc/types'
import { MobiliteGuidanceState } from '@/inc/types/store/mobiliteGuidance'

export const simulationInitialState: MobiliteGuidanceState = {
  simulation: {
    step: 'step-vehicule',
    uuid: null,
    choiceModel: true,
    accountMyresa: true,
    ean: null,
    installation: {
      adresse: null,
      compteur: null,
      prelevement: 0.0,
      amperage: 0.0,
      type: '',
      neutre: '',
      production: 0.0,
      borne: null,
      bornePower: 0.0,
      recommande: '',
      autonomyPerHour: 0.0,
      chargeTime: 0.0,
      equipements: [],
      puissancesJour: 0.0,
      puissancesSoir: 0.0,
    },
    vehicule: {
      marque: null,
      modele: null,
      version: null,
      capaciteBatterie: null,
      type: null,
      autonomie: null,
      consommation: null,
      puissanceChargeMax: null,
      capacite: null,
      utilisation: {
        distanceType: null,
        periodeRecharge: null,
        branchementMoyen: null,
        parkingElecPro: false,
        distancePro: null,
      },
    },
    upgrade: false,
    powerOk: false,
    powerWarn: false,
    raccOk: false,
    estimate: null,
    infoCosts: [],
  },
}

const state: MobiliteGuidanceState = simulationInitialState

const getters: GetterTree<MobiliteGuidanceState, RootState> = {
  getStep: state => state.simulation.step,
  getAccountMyresa: state => state.simulation.accountMyresa,
  getVehicule: state => state.simulation.vehicule,
  getSimulation: state => state.simulation,
}

const mutations: MutationTree<MobiliteGuidanceState> = {
  SET_STEP(state, payload) {
    state.simulation.step = payload
  },
  SET_CHARGE_TIME(state, payload) {
    state.simulation.installation.chargeTime = payload
  },
  SET_BORNE(state, payload) {
    state.simulation.installation.borne = payload
  },
  SET_BORNE_POWER(state, payload) {
    state.simulation.installation.bornePower = payload
  },
  SET_RECOMMANDE(state, payload) {
    state.simulation.installation.recommande = payload
  },
  SET_AUTONOMY_PER_HOUR(state, payload) {
    state.simulation.installation.autonomyPerHour = payload
  },
  SET_SIMULATION(state, payload) {
    state.simulation = payload
  },
}

const actions: ActionTree<MobiliteGuidanceState, RootState> = {
  calculateBorneChoice({ commit, state }) {
    const puissance = 2.3
    const consoKm = state.simulation.vehicule.consommation
      ? Number((state.simulation.vehicule.consommation / 100).toFixed(1))
      : 0
    const autonomieHeure = Number((puissance / consoKm).toFixed(1))
    let dist: number =
      state.simulation.vehicule.utilisation.distanceType === null
        ? 0
        : state.simulation.vehicule.utilisation.distanceType

    if (state.simulation.vehicule.utilisation.distancePro !== null) {
      dist -= state.simulation.vehicule.utilisation.distancePro
    }
    const distanceKm = Number(Number(dist).toFixed(1))

    const time = Number(((distanceKm * consoKm) / puissance).toFixed(1))
    const branchement = Number(
      Number(state.simulation.vehicule.utilisation.branchementMoyen).toFixed(1)
    )

    if (time <= branchement) {
      commit('SET_BORNE', 'simplicity')
      commit('SET_BORNE_POWER', puissance)
      commit('SET_RECOMMANDE', 'simplicity')
      commit('SET_AUTONOMY_PER_HOUR', Number(autonomieHeure.toFixed(1)))
      commit('SET_CHARGE_TIME', time)
    } else {
      const puissance = 7.4
      const autonomieHeure: number = puissance / consoKm
      const time = Number(((distanceKm * consoKm) / puissance).toFixed(1))

      if (time <= branchement) {
        commit('SET_BORNE', 'confort')
        commit('SET_BORNE_POWER', puissance)
        commit('SET_RECOMMANDE', 'confort')
        commit('SET_AUTONOMY_PER_HOUR', Number(autonomieHeure.toFixed(1)))
        commit('SET_CHARGE_TIME', time)
      } else {
        const puissance = 11
        const autonomieHeure: number = puissance / consoKm
        const time = Number(((distanceKm * consoKm) / puissance).toFixed(1))

        if (time <= branchement) {
          commit('SET_BORNE', 'confort_plus')
          commit('SET_BORNE_POWER', puissance)
          commit('SET_RECOMMANDE', 'confort_plus')
          commit('SET_AUTONOMY_PER_HOUR', Number(autonomieHeure.toFixed(1)))
          commit('SET_CHARGE_TIME', time)
        } else {
          const puissance = 22
          const autonomieHeure: number = puissance / consoKm
          const time = Number(((distanceKm * consoKm) / puissance).toFixed(1))

          if (time <= branchement) {
            commit('SET_BORNE', 'luxe')
            commit('SET_BORNE_POWER', puissance)
            commit('SET_RECOMMANDE', 'luxe')
            commit('SET_AUTONOMY_PER_HOUR', Number(autonomieHeure.toFixed(1)))
            commit('SET_CHARGE_TIME', time)
          }
        }
      }
    }
  },
}

export const mobiliteGuidance: Module<MobiliteGuidanceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
