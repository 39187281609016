var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-header header"},[_c('div',{staticClass:"my-header__logo"},[_c('a',{staticClass:"header__nav__logo",attrs:{"href":_vm.resaUrl}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 121 60","symbol":"logos-logo_resa"}})])]),(_vm.languagesAvailable)?_c('MyLang'):_vm._e(),(_vm.isLogged && _vm.user.firstname !== undefined)?_c('MyUser'):(!_vm.isLogged && _vm.$route.name !== 'MySignin')?_c('g-action',{attrs:{"content":{
      label: 'MyRESA',
      modifiers: [
        'btn',
        'reverse',
        'small',
        'outline',
        'static',
        'fixed',
        'no-animation' ],
      to: { name: 'MySignin' },
      icon: {
        size: '0 0 14 15',
        name: 'icons-profile',
      },
    }}}):_vm._e(),_c('a',{ref:"trigger",staticClass:"header-trigger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMenu($event)}}},[_vm._m(0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-trigger__hamburger"},[_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }