import axios, { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { FaqCategory, FaqState, RootState } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

/* eslint-disable */
const state: FaqState = {
  // Traductions
  i18n: {},
  cat: [],
  item: {
    id: '',
    name: '',
    children: [],
    numberChild: 1,
  },
  forms: [],
  files: {},
  preselected: '',
  secondPreselected: {
    id: '',
    name: '',
    email: '',
  },
}

const getters: GetterTree<FaqState, RootState> = {
  getI18n: state => state.i18n,
  getCategories: state => state.cat,
  getCat: state => id => state.cat?.find(c => c.id === id),
  getPreselected: state => state.preselected,
  getSecondPreselected: state => state.secondPreselected,
}

const mutations: MutationTree<FaqState> = {
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_FORMS(state, payload) {
    state.forms.push({...payload})
  },
  SET_CAT(state, payload: FaqCategory[]) {
    state.cat = []
    state.cat = payload
  },
  SET_CHILD(state, payload) {
    state.item = {...payload}
    state.item.numberChild = state.item.children?.length
  },
  UPDATE_FORMS(state, payload) {
    if (payload) {
      state.forms.map(key => {
        if (key.id === payload.id && key.type === payload.type) {
          key.value = payload.value
        }
        return key
      })
    }
  },
  RESET_FORMS(state) {
    state.forms = []
  },
  UPDATE_PRESELECTED(state, payload) {
    state.preselected = payload
  },
  UPDATE_SECOND_PRESELECTED(state, payload) {
    state.secondPreselected = payload
  },
}

const actions: ActionTree<FaqState, RootState> = {
  // FETCH Translations
  async FETCH_I18N({commit}) {
    const response: AxiosResponse = await axios.get(
      `${getApiUrl()}/translations/faq`
    )
    commit('SET_I18N', response.data)
  },
  async fetchCategories({commit}) {
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/category`)
    commit('SET_CAT', response.data)
  },
  async setPreselected({commit}, value) {
    commit('UPDATE_PRESELECTED', value)
  },
}

export const faq: Module<FaqState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
