













































































































import { defineComponent, ref } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import auth from '@/composables/auth'

export default defineComponent({
  name: 'my-commune-lang',
  props: {
    myLanguages: {
      type: Object,
      required: false,
    },
  },
  setup(_props, ctx) {
    const { $logger, $router } = ctx.root
    const { currentLang, languages } = useGetters(['currentLang', 'languages'])
    const { fetchChrome } = useActions(['fetchChrome'])
    const isOpen = ref(false)
    const container = ref()
    const myLang = ref('fr')
    const tableLang = ref({})

    if (_props.myLanguages) {
      tableLang.value = _props.myLanguages
    } else {
      tableLang.value = languages.value
    }

    if (currentLang.value !== undefined) {
      myLang.value = currentLang.value
    }

    const onChange = () => {
      let myUrl = ctx.root.$route.path
      if (myLang.value === 'de') {
        myUrl = myUrl.replace('/de/', '/fr/')
        myLang.value = 'fr'
      } else {
        myUrl = myUrl.replace('/fr/', '/de/')
        myLang.value = 'de'
      }
      fetchChrome(myLang.value)
      $router.push(myUrl).catch($logger.trace)
    }
    const open = () => (isOpen.value = true)
    const close = () => (isOpen.value = false)

    const toggle = () => {
      if (isOpen.value) {
        close()
      } else {
        open()
      }
    }

    const changeLang = (newLang, url) => {
      myLang.value = newLang
    }

    return {
      currentLang,
      languages,
      container,
      isOpen,
      close,
      onChange,
      toggle,
      myLang,
      changeLang,
      tableLang,
      isLogged: auth.isLogged,
    }
  },
})
