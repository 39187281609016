var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.i18n.communeMenu)?_c('nav',{staticClass:"panel-user"},[_c('h2',{staticClass:"not-visible"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.mainTitle || 'Navigation principale')+" ")]),(_vm.i18n.communeMenu.mainTitle)?[_c('h3',{staticClass:"menu-title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.mainTitle)+" ")]),_c('ul',{staticClass:"nav-items"},[_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            label: _vm.i18n.communeMenu.mainDashboard,
            to: { name: 'CommuneDashboard' },
          }}})],1)])]:_vm._e(),(
      _vm.authorizations.includes(_vm.authorizationTypes.INDEX_CONSULTER) ||
      _vm.authorizations.includes(_vm.authorizationTypes.ALL)
    )?[_c('h3',{staticClass:"menu-title mt-xs h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.releve.title)+" ")]),_c('ul',{staticClass:"nav-items"},[_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            label: _vm.i18n.communeMenu.releve.index,
            to: { name: 'CommuneIndex' },
          }}})],1),(
          _vm.i18n.communeMenu.releve.links &&
          _vm.i18n.communeMenu.releve.links.length > 0
        )?_vm._l((_vm.i18n.communeMenu.releve.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
              url: item.link.url,
              target: item.link.target,
              label: item.link.title,
            },"type":"externalLink"}})],1)}):_vm._e()],2)]:_vm._e(),(
      _vm.authorizations.includes(_vm.authorizationTypes.RACCORDEMENTS) ||
      _vm.authorizations.includes(_vm.authorizationTypes.ALL)
    )?[_c('h3',{staticClass:"menu-title mt-xs h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.raccordement.title)+" ")]),_c('ul',{staticClass:"nav-items"},[_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            label: _vm.i18n.communeMenu.raccordement.raccordementSuivi,
            to: { name: 'CommuneRecord' },
          }}})],1),(
          _vm.i18n.communeMenu.raccordement.links &&
          _vm.i18n.communeMenu.raccordement.links.length > 0
        )?_vm._l((_vm.i18n.communeMenu.raccordement.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
              url: item.link.url,
              target: item.link.target,
              label: item.link.title,
            },"type":"externalLink"}})],1)}):_vm._e()],2)]:_vm._e(),(
      _vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS) ||
      _vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS_EP) ||
      _vm.authorizations.includes(_vm.authorizationTypes.ALL)
    )?[_c('h3',{staticClass:"menu-title mt-xs h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.consommation.title)+" ")]),_c('ul',{staticClass:"nav-items"},[(
          _vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS) ||
          _vm.authorizations.includes(_vm.authorizationTypes.ALL)
        )?_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            label: _vm.i18n.communeMenu.consommation.communicantTitle,
            to: { name: 'CommuneConsoSmart' },
          }}})],1):_vm._e(),(
          _vm.isEp &&
          (_vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS_EP) ||
            _vm.authorizations.includes(_vm.authorizationTypes.ALL))
        )?_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            label: _vm.i18n.communeMenu.consommation.epTitle,
            to: { name: 'CommuneConsommationEp' },
          }}})],1):_vm._e(),(
          _vm.i18n.communeMenu.consommation.links &&
          _vm.i18n.communeMenu.consommation.links.length > 0
        )?_vm._l((_vm.i18n.communeMenu.consommation.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
              url: item.link.url,
              target: item.link.target,
              label: item.link.title,
            },"type":"externalLink"}})],1)}):_vm._e()],2)]:_vm._e(),(_vm.isEp || _vm.isElec)?_c('h3',{staticClass:"menu-title mt-xs h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeMenu.pannes.title)+" ")]):_vm._e(),(_vm.isEp || _vm.isElec)?_c('ul',{staticClass:"nav-items"},[(_vm.isEp)?_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
          label: _vm.i18n.communeMenu.pannes.pannesEpTitle,
          url: _vm.pannesEPUrl,
          target: '_blank',
        },"type":'externalLink'}})],1):_vm._e(),(_vm.isElec)?_c('li',{staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
          label: _vm.i18n.communeMenu.pannes.pannesElecTitle,
          url: _vm.pannesElecUrl,
          target: '_blank',
        },"type":'externalLink'}})],1):_vm._e(),(
        _vm.i18n.communeMenu.pannes.links &&
        _vm.i18n.communeMenu.pannes.links.length > 0
      )?_vm._l((_vm.i18n.communeMenu.pannes.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            url: item.link.url,
            target: item.link.target,
            label: item.link.title,
          },"type":"externalLink"}})],1)}):_vm._e()],2):_vm._e(),(_vm.i18n.communeMenu.links && _vm.i18n.communeMenu.links.length > 0)?_c('div',{staticClass:"mt-s other-links-container"},[_c('ul',{staticClass:"nav-items"},_vm._l((_vm.i18n.communeMenu.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item",on:{"click":function($event){return _vm.closeSidepanel()}}},[_c('CustomLink',{attrs:{"content":{
            url: item.link.url,
            target: item.link.target,
            label: item.link.title,
          },"type":"externalLink"}})],1)}),0)]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }