/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray, isObject } from '@/inc/utils'
import { GenericObject } from '@/inc/types'
import { snakeCase } from 'snake-case'

export const toKebab = (str: string): string => {
  if (str) {
    return str
      .replace(/^\w/, chr => chr.toLowerCase())
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }

  return str
}

export const toCamel = (s: string) =>
  s
    .replace(/^\w/, chr => chr.toLowerCase())
    .replace(/([-_][a-z])/gi, $1 =>
      $1.toUpperCase().replace('-', '').replace('_', '')
    )

export const pascalToKebab = (str: string): string => toKebab(str)
export const camelToKebab = (str: string): string => toKebab(str)

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    const n = {} as GenericObject

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i))
  }

  return o
}

export const keysToSnake = (o: any) => {
  if (isObject(o)) {
    const n = {} as GenericObject

    Object.keys(o).forEach(k => {
      n[snakeCase(k)] = o[k]
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => snakeCase(i))
  }

  return o
}
