import axios, { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { InterventionState, RootState } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

/* eslint-disable */
const state: InterventionState = {
  // Traductions
  i18n: {},
}

const getters: GetterTree<InterventionState, RootState> = {
  getI18n: state => state.i18n
}

const mutations: MutationTree<InterventionState> = {
  SET_I18N(state, payload) {
    state.i18n = payload
  },
}

const actions: ActionTree<InterventionState, RootState> = {
  // FETCH Translations
  async fetchI18n({commit}) {
    const response: AxiosResponse = await axios.get(
      `${getApiUrl()}/translations/intervention`
    )
    commit('SET_I18N', response.data)
  },
}

export const intervention: Module<InterventionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
