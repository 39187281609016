















































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'footer-contact',

  setup() {
    const { VUE_APP_DOMAIN } = process.env
    const { currentLang } = useGetters(['currentLang'])
    const urlPlainte = ref(
      `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/plainte}/`
    )
    if (currentLang.value === 'de') {
      urlPlainte.value = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/beschwerde`
    } else {
      urlPlainte.value = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/plainte`
    }

    return {
      ...useGetters(['chrome']),
      VUE_APP_DOMAIN,
      urlPlainte,
      currentLang,
    }
  },
})
