




























import { defineComponent } from '@vue/composition-api'
import { TextOnly } from '@/inc/types'

export default defineComponent({
  name: 'text-only',
  components: {},
  props: {
    content: {
      type: Object as () => TextOnly,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
