import { getApiUrl } from '@/inc/app.config'
import { FormsGravity } from '@/inc/types'
import { logger } from '@/inc/utils'
import { useMutations } from '@u3u/vue-hooks'

import axios from 'axios'
import auth from './auth'

export interface Faq {
  // eslint-disable-next-line camelcase
  post_id: string
  title: string
  slug: string
}
const formSend = [
  'name',
  'address',
  'text',
  'textarea',
  'number',
  'fileupload',
  'email',
  'checkbox',
  'phone',
  'EAN',
]

export const fetchFaq = (id: string) =>
  axios
    .get(`${getApiUrl()}/faqs/${id}`)
    .then(resp => resp.data)
    .catch(error => {
      logger.error('[FETCH FAQS] ID FAQ not exist')
    })

export const sendTempFile = fileName => {
  const headers: Record<string, string> = {}

  if (auth.sessionId) {
    headers.SessionId = auth.sessionId
    headers['Content-Type'] = '*'
  }

  return axios.get(
    // eslint-disable-next-line
    `${getApiUrl()}/file/temp?FileName=${fileName}​​​​​​​​`,
    { headers }
  )
}

export const convertFileuploadUrl = form => {
  const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
  if (form.type === 'fileupload') {
    const files: any = []
    const headers: Record<string, string> = {}
    const promises: any[] = []

    if (auth.sessionId) {
      headers.SessionId = auth.sessionId
      headers['Content-Type'] = '*'
    }

    if (form.value !== '' && form.value.length > 0) {
      for (const f of form.value) {
        promises.push(
          new Promise(resolve => {
            const fileName = f.file.name
            sendTempFile(fileName).then(res => {
              axios
                .put(res.data.putUrl, f.file, { headers })
                .then(resp => {
                  if (resp.status === 200) {
                    files.push({
                      DocumentName: fileName,
                      FileUrl: res.data.getUrl,
                      Extension: f.ext,
                    })
                    UPDATE_FORMS({ id: form.id, type: form.type, value: files })
                  }
                })
                .catch(err => {
                  console.log({ name: f.file.name, status: 'X' })
                  console.log(`[FAQ] Put temp url: ${err}`)
                })
              resolve({
                FileUrl: res.data.getUrl,
                DocumentName: f.file.name,
                Extension: f.ext,
              })
            })
          })
        )
      }
    }

    return true
  }

  return ''
}

export const associateComponent = (type, form: FormsGravity) => {
  if (form.value !== '') {
    switch (type) {
      case 'name':
        return {
          Prenom: form.value.firstname,
          Nom: form.value.lastname,
        }
      case 'address':
        return {
          Addresse: `${form.value.street} ${form.value.number}, ${form.value.zip} ${form.value.zip}`,
        }
      case 'text':
        return { Text: form.value }
      case 'textarea':
        return { Remarque: form.value }
      case 'number':
        return { Text: form.value }
      case 'fileupload':
        return { Files: convertFileuploadUrl(form) }
      case 'email':
        return { Email: form.value }
      case 'checkbox':
        return { Preference: form.value }
      case 'phone':
        return { Phone: form.value }
      case 'EAN':
        return { EAN: form.value }
      default:
        break
    }
  }

  return ''
}
