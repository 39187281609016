

























































































































import GazAnimation from '@/components/animations/Gaz.vue'
import LinesAnimation from '@/components/animations/Lines.vue'
import StepsItem from '@/components/home/StepsItem.vue'

import { defineComponent } from '@vue/composition-api'
import { HomeSteps } from '@/inc/types/components'

export default defineComponent({
  name: 'home-steps',
  components: {
    GazAnimation,
    LinesAnimation,
    StepsItem,
  },
  props: {
    content: {
      type: Object as () => HomeSteps,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
