
























































































































































































































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'footerLegalCommune',

  setup() {
    const resaUrl = VUE_APP_DOMAIN

    const scrollTop = () => {
      const el = document.querySelector('.app')
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }

    return {
      ...useGetters(['chrome']),
      resaUrl,
      scrollTop,
    }
  },
})
