


































































































































import { defineComponent, ref, reactive } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

export default defineComponent({
  setup(_props, ctx) {
    const { $logger, $router } = ctx.root
    const isOpen = ref(false)
    const container = ref()
    const faq = ref(false)
    const onChange = (e: Event): void => {
      const { value: url } = e.target as HTMLSelectElement

      $router.push(url).catch($logger.trace)
    }
    const open = () => (isOpen.value = true)
    const close = () => (isOpen.value = false)

    const toggle = () => {
      if (isOpen.value) {
        close()
      } else {
        open()
      }
    }
    const { currentLang, languages } = useGetters(['currentLang', 'languages'])
    if (
      ctx.root.$route.name === 'Faq' ||
      ctx.root.$route.name === 'FaqDetails'
    ) {
      faq.value = true
    }

    return {
      currentLang,
      languages,
      container,
      isOpen,
      close,
      onChange,
      toggle,
      faq,
    }
  },
})
