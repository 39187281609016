import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import axios, { AxiosResponse } from 'axios'

import { GravityFormState, RootState } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'

/* eslint-disable */
const state: GravityFormState = {
  // Traductions

  fields: [],
}

const mutations: MutationTree<GravityFormState> = {
  SET_FORMS(state, payload) {
    state.fields[payload.id] = payload
  },
  UPDATE_FORMS(state, payload) {
    if ('value' in payload) {
      state.fields[payload.id].value = payload.value
    }
    if ('vuejsDisabled' in payload) {
      state.fields[payload.id].vuejsDisabled = payload.vuejsDisabled
    }
  },
  RESET_FORMS(state) {
    for (const field of state.fields) {
      if (field) {
        field.value = ''
      }
    }
  },
}

export const gravityForm: Module<GravityFormState, RootState> = {
  state,
  mutations,
  namespaced: true,
}
