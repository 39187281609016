import axios from 'axios'
import dayjs from 'dayjs'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import {
  OutagesState,
  RootState,
  Outage,
  OutageType,
  OutageCheckActiveParams,
} from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import {
  fetchOutages,
  formatEps,
  formatTad,
  formatUnplanned,
  formatPlanned,
} from '@/composables/outages/outages'
import { logger } from '@/inc/utils'

const state: OutagesState = {
  // dom or ep
  type: null,
  // Traductions
  i18n: {},
  outages: [],
  // Formulaire info
  form: {},
}

const getters: GetterTree<OutagesState, RootState> = {}

const mutations: MutationTree<OutagesState> = {
  SET_TYPE(state, payload: OutageType) {
    state.type = payload
  },
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_OUTAGES(state, payload: Outage[]) {
    state.outages = []
    payload.sort((a, b) =>
      dayjs(a.status.dateStart).isAfter(dayjs(b.status.dateStart)) ? -1 : 1
    )
    state.outages = payload
  },
  SET_FORM(state, payload) {
    state.form = payload
  },
}

const actions: ActionTree<OutagesState, RootState> = {
  async FETCH_I18N({ commit }) {
    await axios
      .get(`${getApiUrl()}/outages/translations`)
      .then(({ data }) => commit('SET_I18N', data))
  },
  async FETCH_EP({ commit }, cp = '') {
    let outages: Outage[] = []
    let paramZipcode = ''
    if (cp !== '') {
      paramZipcode = `&Zipcode=${cp}`
    }
    await fetchOutages(
      'outages/list',
      formatEps,
      `Ep=true&EnCours=true${paramZipcode}`,
      false // Change when pagination works in Resa WS
    ).then(out => {
      outages = outages.concat(out)
    })

    const yesterday = dayjs().subtract(1, 'day').format('DD/MM/YYYY')
    await fetchOutages(
      'outages/list',
      formatEps,
      `DateDebut=${yesterday}&Ep=true&EnCours=false${paramZipcode}`,
      false // Change when pagination works in Resa WS
    ).then(out => {
      outages = outages.concat(out.filter(o => o.subcat === 'resolved'))
    })

    commit('SET_OUTAGES', outages)
  },
  async FETCH_DOM({ commit }) {
    let outages: Outage[] = []

    const unplanned = fetchOutages('outages/unplanned', formatUnplanned).then(
      out => {
        outages = outages.concat(out)
      }
    )

    const planned = fetchOutages('outages/planned', formatPlanned).then(out => {
      outages = outages.concat(out)
    })

    const tad = fetchOutages('outages/tad', formatTad).then(out => {
      outages = outages.concat(out)
    })

    await Promise.all([unplanned, tad, planned]).then(_ => {
      commit('SET_OUTAGES', outages)
    })
  },
  async CHECK_ACTIVE({ commit }, params: OutageCheckActiveParams) {
    let panneId: string | null = null
    await axios
      .get(`${getApiUrl()}/outages/active`, { params })
      .then(({ data }) => {
        if (data.panneId) {
          // eslint-disable-next-line
          panneId = data.panneId
        }
      })
      .catch(err => logger.warn('[outages] CHECK_ACTIVE', err))

    return panneId
  },
}

export const outages: Module<OutagesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
