import { RESA_ENV } from '@/inc/app.config'
import { useGetters, useState } from '@u3u/vue-hooks'

const {
  NODE_ENV,
  VUE_APP_TASK,
  VUE_APP_DOMAIN,
  VUE_APP_MYRESA_DOMAIN,
  VUE_APP_MYCOMMUNE_DOMAIN,
} = process.env

export const isMyresa =
  RESA_ENV === 'www.dev-my-resa.contraste-digital.dev' ||
  RESA_ENV?.startsWith('myresa') ||
  RESA_ENV?.startsWith('my-resa')

export const isCommuneResa =
  RESA_ENV === 'www.dev-macommune-resa.contraste-digital.dev' ||
  RESA_ENV?.startsWith('macommune') ||
  RESA_ENV?.startsWith('my-commune') ||
  RESA_ENV?.startsWith('mycommune')
// ||RESA_LOCATION?.includes('mycommune')

export const getCallbackUrl = (cb: string) => {
  let { origin } = window.location
  let callback = cb

  if (!/^http/.test(cb)) {
    if (isCommuneResa) {
      origin = VUE_APP_MYCOMMUNE_DOMAIN as string
    } else {
      origin = VUE_APP_MYRESA_DOMAIN as string
    }

    if (cb.includes('/fr/') || cb.includes('/de/')) {
      callback = `${origin}${cb}`
    } else {
      callback = `${origin}/fr${cb}`
    }
  }

  return callback
}

export const convertUnit = (value: string) => {
  if (value === 'KWH') {
    return 'kWh'
  } else if (value === 'M3') {
    return 'm³'
  }

  return value
}

export const convertRegister = (value: string) => {
  const { i18n } = useState('my', ['i18n'])
  if (value === 'HI') {
    return 'HAUT'
  } else if (value === 'LO') {
    return 'BAS'
  } else if (value === 'TH') {
    return 'TOTAL'
  } else if (value === '1.8.1') {
    return i18n.value?.meterInfo?.mymeter?.prelJour || value
  } else if (value === '1.8.2') {
    return i18n.value?.meterInfo?.mymeter?.prelNuit || value
  } else if (value === '2.8.1') {
    return i18n.value?.meterInfo?.mymeter?.injJour || value
  } else if (value === '2.8.2') {
    return i18n.value?.meterInfo?.mymeter?.injNuit || value
  } else if (value === 'JOUR') {
    return i18n.value?.meterInfo?.mymeter?.jour || value
  } else if (value === 'NUIT') {
    return i18n.value?.meterInfo?.mymeter?.nuit || value
  }

  return value
}

export const trimzerosIndex = (value: string) => {
  try {
    return value === '0' ? '0' : value.replace(/^0+/, '').replace(/\.0+$/, '')
  } catch {
    return value
  }
}

export const cadran = (cadran: Record<string, any> | Record<string, any>[]) =>
  Array.isArray(cadran) ? cadran[0] : cadran

/* eslint-disable */
export const formatIndexHistory = (indexData: Object) => {
  const indexHistory: Object = {}
  for (const [_, contract] of Object.entries(indexData)) {
    for (const detail of contract.detailContrat) {
      for (const cpt of detail.detailCpt) {
        const index = {
          dateRel: detail.dateRel,
          compteur: cpt.compteur,
        }
        if (!(cpt.numCompteur in indexHistory)) {
          indexHistory[cpt.numCompteur] = [index]
        } else {
          indexHistory[cpt.numCompteur].push(index)
        }
      }
    }
  }
  for (const [_, meter] of Object.entries(indexHistory)) {
    meter.sort((a, b) =>
      a.dateRel < b.dateRel ? 1 : b.dateRel < a.dateRel ? -1 : 0
    )
  }

  return indexHistory
}
/* eslint-enable */
