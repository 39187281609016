import VueRouter from 'vue-router'
import { Store } from 'vuex'

import { getApiUrl, langAvailable, langDefault } from '@/inc/app.config'
import { loadLanguageAsync, setI18nLanguage } from '@/inc/i18n'
import { fetch, isCommuneResa, isMyresa, logger } from '@/inc/utils'
import { authorizationTypes, Language, RootState } from '@/inc/types'
import axios from 'axios'
import gtm, { Event } from '@/composables/gtm'

const { VUE_APP_MYRESA_DOMAIN, VUE_APP_MYCOMMUNE_DOMAIN } = process.env

const authorizedRoutesByRole = {
  ADMIN: [authorizationTypes.ALL], // toutes les routes autorisées pour un admin
  AUTHENTICATED: ['CommuneDashboard', 'CommuneProfile'], // toutes les routes autorisées pour un user authentifié
  RACC_CONSU: ['CommuneRecord'],
  RACC_GERER: ['CommuneRecord', 'CommuneRecordSingle'],
  INDEX_CPT_CONSU: [
    'CommuneIndex',
    'CommuneDetailsCompteur',
    'CommuneIndexHistorique',
  ],
  INDEX_CPT_GERER: [
    'CommuneIndex',
    'CommuneDetailsCompteur',
    'CommuneIndexHistorique',
  ],
  CONSO_SMART: ['CommuneConsoSmart'],
  CONSO_EP: ['CommuneConsommationEp'],
  POINT_CONSO: ['CommuneAddress'],
}

export const guards = (router: VueRouter, store: Store<RootState>) => {
  // Forced SSR error -> SPA rendering
  // router.beforeEach((to, from, next) => {
  //   if (router.$$type === 'server') {
  //     try {
  //       throw Error('Forced SSR error')
  //     } catch (e) {
  //       next(e)
  //     }
  //   } else {
  //     next()
  //   }
  // })

  // There is a 'beforEach' in MegaMenu.vue (just in case)

  // Log
  router.beforeEach((to, from, next) => {
    logger.trace('[guards] from', from.name, 'to', to.name)
    logger.trace('[guards url]', from.fullPath, 'to', to.fullPath)
    logger.trace('[isMyResa]', isMyresa)
    logger.trace('[isResaCommune]', isCommuneResa)
    next()
  })

  // Debug mode
  router.beforeEach((to, from, next) => {
    if (
      router.app?.$debug &&
      (from.query.debug !== undefined || to.query.debug !== undefined)
    ) {
      router.app.$debug = true
    }

    if (to.query.debug === undefined && from.query.debug !== undefined) {
      const query = { ...to.query, debug: from.query.debug }

      next({
        name: to.name as string,
        path: to.path,
        hash: to.hash,
        query,
        params: to.params,
      })
    } else {
      next()
    }
  })

  // My Resa | User auth
  router.beforeEach(async (to, from, next) => {
    if (router.$$type === 'server') {
      return next()
    }

    if (
      to.meta.auth &&
      (to.name?.startsWith('Commune') || to.name === 'MyDashboard')
    ) {
      // call pour récupérer les infos utiles du user
      try {
        await axios.get(`${getApiUrl()}/me/dashboard?Quick=true`).then(res => {
          if (res.status === 200) {
            const { commune } = res.data
            const isUserCommune = commune?.id
            // si le user est un particulier et arrive dans mycommune on le redirige
            if (isCommuneResa && !isUserCommune) {
              if (typeof VUE_APP_MYRESA_DOMAIN === 'string') {
                window.location.href = `${VUE_APP_MYRESA_DOMAIN}/fr/dashboard/`
              }
            } else if (isMyresa && isUserCommune) {
              // si le user est une commune et arrive sur myresa on le redirige
              if (typeof VUE_APP_MYCOMMUNE_DOMAIN === 'string') {
                window.location.href = `${VUE_APP_MYCOMMUNE_DOMAIN}/fr/dashboard/`
              }
            }

            if (commune) {
              axios
                .get(`${getApiUrl()}/address/services?IdCommune=${commune.id}`)
                .then(({ data }) => {
                  const types = {
                    gaz: 'gaz',
                    elec: 'electricity',
                    ep: 'public_electricity',
                  }
                  const available: string[] = Object.keys(data)
                    .filter(key => data[key] === 'X' && types[key])
                    .map(key => types[key])

                  store.commit(
                    'commune/SET_ISELEC',
                    available.includes('electricity')
                  )
                  store.commit(
                    'commune/SET_ISEP',
                    available.includes('public_electricity')
                  )
                  store.commit('commune/SET_ISGAZ', available.includes('gaz'))
                })
                .catch(error => {
                  logger.error('[GUARDS] Service error')
                })

              window.localStorage.setItem(
                'communeName',
                res.data.commune.localite
              )

              store.commit('user/SET_USER', res.data)
            }
          }
        })
      } catch (error) {
        if (isCommuneResa) {
          return next({
            name: 'CommuneSignin',
            params: {
              lang: 'fr',
            },
          })
        }

        return next({
          name: 'MySignin',
          params: {
            lang: 'fr',
          },
        })
      }

      if (isCommuneResa) {
        // check si la route est accessible selon le role sinon retour au dashboard
        const roles = store.getters['user/getRoles']
        logger.trace('[USER ROLES]', roles)
        logger.trace('[ROUTE TO CHECK]', to.name)
        let authorized = false
        roles.forEach(role => {
          if (
            !authorized &&
            (authorizedRoutesByRole[role].includes(authorizationTypes.ALL) || // si Admin
              authorizedRoutesByRole[role].includes(to.name))
          ) {
            authorized = true
          }
        })

        logger.trace('[ROUTE AUTHORIZED]', authorized)

        if (!authorized) {
          return next({
            name: 'CommuneDashboard',
            params: {
              lang: to.params.lang,
            },
            query: {
              access: 'unauthorized',
            },
          })
        }
      }
    }

    const { login } = to.query
    if (login) {
      const event = {
        event: 'MyRESA_events',
        eventLabel: 'Login_confirmation',
      } as Event
      gtm.sendEvent(event)
    }

    const { ssoToken } = to.query
    if (ssoToken) {
      localStorage.setItem('token', `Bearer ${ssoToken}`)
      if (Object.keys(to.query).length) {
        return next({ path: to.path, query: {}, hash: to.hash })
      }
    }

    return next()
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, router.$$type))
        .then(lang => {
          if (langHasChanged) {
            if (isMyresa) {
              store.commit('user/SET_USER', lang)
            }

            return store.dispatch('fetchChrome', { lang })
          }

          return Promise.resolve()
        })
        .catch(logger.error)

      next()
    })
  }

  // Fetch content
  router.beforeEach(async (to, _from, next) => {
    // Fetch page resource is added through `router`
    try {
      await fetch(to, router, false)
    } catch (error) {
      logger.error(error)
    }

    return next()
  })

  router.beforeEach((to, _from, next) => {
    if (to.name === 'CommuneDashboard') {
      const codesPostaux = store.getters['user/getCodesPostaux']
      store.dispatch('user/refresh').then(() => console.log('User refreshed'))
      store
        .dispatch('commune/FETCH_NBR_PANNES', { codesPostaux })
        .then(() => console.log('Pannes fetched'))
    }

    return next()
  })
}
