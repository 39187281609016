




















import { ImgBlock } from '@/inc/types'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'img-block',
  components: {},
  props: {
    content: {
      type: Object as () => ImgBlock,
      required: true,
    },
  },

  setup(props, ctx) {
    const emitValue = () => {
      if (!props.content.isActive) {
        ctx.emit('input', props.content.value)
      }
    }

    return {
      emitValue,
    }
  },
})
