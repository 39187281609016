
























































































































































import GazAnimation from '@/components/animations/Gaz.vue'

import { CtaBanner } from '@/inc/types/components'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'cta-banner',
  components: {
    GazAnimation,
  },
  props: {
    content: {
      type: Object as () => CtaBanner,
      required: true,
    },
  },

  setup(props) {
    const imgUrl = `${process.env.VUE_APP_API_DOMAIN}`

    return {
      imgUrl,
    }
  },
})
