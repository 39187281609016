


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  ComputedRef,
  watch,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { toKebab } from '@/inc/utils/case'
import { Chrome } from '@/inc/types'

export default defineComponent({
  setup(_props, ctx) {
    const body = ref<HTMLElement | null>(null)
    const currentSubMenu = ref('')
    const isOpen = ref(false)
    const isSubMenuOpen = ref(false)
    const subItems = ref<HTMLElement[]>([])
    const trigger = ref<HTMLElement | null>(null)

    const open = () => {
      isOpen.value = true
      body.value!.style.overflow = 'hidden'
      if (ctx.root.$route.params.lang === 'fr') {
        currentSubMenu.value = 'client'
      } else {
        currentSubMenu.value = 'kunde'
      }
    }

    const close = () => {
      isOpen.value = false
      body.value!.removeAttribute('style')
    }

    const toggleMenu = () => {
      if (isOpen.value) {
        close()
      } else {
        open()
      }
    }

    const openSubMenu = () => {
      isSubMenuOpen.value = true
    }

    const closeSubMenu = () => {
      isSubMenuOpen.value = false
    }

    const isActiveMenu = (menu: string) => currentSubMenu.value === menu
    const setActiveMenu = (sub: string) => {
      currentSubMenu.value = sub
      openSubMenu()
    }

    onMounted(() => {
      body.value = document.body
      ctx.root.$router.beforeEach((to, from, next) => {
        close()
        next()
      })
    })

    return {
      chrome: useGetters(['chrome']).chrome as ComputedRef<Chrome>,
      closeSubMenu,
      currentSubMenu,
      isActiveMenu,
      isOpen,
      isSubMenuOpen,
      openSubMenu,
      setActiveMenu,
      subItems,
      toggleMenu,
      toKebab,
      trigger,
    }
  },
})
