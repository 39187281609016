

























































































import { defineComponent } from '@vue/composition-api'
import { Wizard } from '@/inc/types'

export default defineComponent({
  name: 'wizard',
  components: {},
  props: {
    content: {
      type: Object as () => Wizard,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
