// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'
import axios, { AxiosTransformer } from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import VueCompositionApi from '@vue/composition-api'
import hooks from '@u3u/vue-hooks'
import VueAware from 'vue-aware'
import VueSvgSprite from 'vue-svg-sprite'
import VueFileAgent from 'vue-file-agent'
import PortalVue from 'portal-vue'
import VueGoodTable from 'vue-good-table'
import VueJsonToCsv from 'vue-json-to-csv'
// Components
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
// Directives
import VueOutside from '@/inc/directives/outside'
import VueSrc from '@/inc/directives/src'
// Filters
import capitalize from '@/inc/filters/capitalize'
import lineBreak from '@/inc/filters/linebreak'
import modifiers from '@/inc/filters/modifiers'
import numbers from '@/inc/filters/numbers'
import trimzeros from '@/inc/filters/trimzeros'
import slugify from '@/inc/filters/slugify'
import { VueMaskFilter } from 'v-mask'
import i18n from '@/inc/i18n'
import { App, AppType } from '@/inc/types'
import { keysToCamel, logger, pascalToKebab } from '@/inc/utils'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LosslessJSON = require('lossless-json')

dayjs.locale('fr')
dayjs.extend(utc)
dayjs.extend(tz)

const timeZone = dayjs.tz.guess()
dayjs.tz.setDefault(timeZone)

// Plugins
// import Fragment from 'vue-fragment'
// Vue.use(Fragment.Plugin)

Vue.use(VueCompositionApi)

Vue.use(hooks)

Vue.use(VueAware)

Vue.use(VueSvgSprite, { url: '' })

Vue.use(VueFileAgent)

Vue.use(PortalVue)

// import the styles
Vue.use(VueGoodTable)

Vue.component('vue-json-to-csv', VueJsonToCsv)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
setInteractionMode('eager')

Vue.directive('outside', VueOutside)

Vue.directive('src', VueSrc)

Vue.filter('capitalize', capitalize)

Vue.filter('linebreak', lineBreak)

Vue.filter('modifiers', modifiers)

Vue.filter('numbers', numbers)

Vue.filter('trimzeros', trimzeros)

Vue.filter('slugify', slugify)

Vue.filter('VMask', VueMaskFilter)

Vue.prototype.$logger = logger
Vue.prototype.$debug = false
Vue.config.productionTip = false

// Import all global components
// Btn.vue => <g-btn></g-btn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name ||
    `g-${pascalToKebab(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))}`

  Vue.component(baseComponentName, baseComponentConfig)
})

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  const { type } = ctx as { type: AppType }
  const isApiRe = new RegExp(process.env.VUE_APP_API_PATH as string)

  logger.trace('[main:createApp]', type)

  // Avoid EAN (as bigInt) parsing rounding
  // 541460900000467314 -> 541460900000467300
  function convertLosslessNumber(key, value) {
    if (value && value.isLosslessNumber) {
      // If "lossLess", returns string value
      return value.value
    }

    return value
  }

  axios.defaults.transformResponse = [
    data => {
      if (typeof data === 'string') {
        try {
          // eslint-disable-next-line no-param-reassign
          data = LosslessJSON.parse(data, convertLosslessNumber)
        } catch (e) {
          /* Ignore */
        }
      }

      return data
    },
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
  ]

  // Transform properties casing
  // Only for API calls
  axios.interceptors.response.use(response => {
    if (response?.data && isApiRe.test(response.config.url as string)) {
      response.data = keysToCamel(response.data)
    }

    return response
  })

  // Set default base URL
  if (type === 'client') {
    axios.defaults.baseURL = window.location.origin
    Vue.prototype.$debug = window.location.search.includes('debug')
  }

  // Send ENV cookies to API (staging / branching)
  if (type === 'client' && process.env.VUE_APP_RELEASE === 'staging') {
    axios.interceptors.request.use(config => {
      const { epic_env_back: envBackCookie } = ctx.$cookie.getCookies()

      if (envBackCookie && isApiRe.test(config.url as string)) {
        logger.warn('ENV', config.url, envBackCookie)
        config.headers.cookie = `epic_env=${envBackCookie}`
      }

      return config
    })
  }

  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const AppComponent = require('@/App.vue').default
  const ErrorComponent = require('@/Error.vue').default
  const createRouter = require('@/router').default
  const createStore = require('@/store').default
  /* eslint-enable @typescript-eslint/no-var-requires, global-require */

  const store = createStore(type)
  const router = createRouter(store)
  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: 'RESA - %s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
