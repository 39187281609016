

















































































































import { defineComponent } from '@vue/composition-api'

import footerContact from '@/components/chrome/footer/Contact.vue'
import footerCta from '@/components/chrome/footer/Cta.vue'
import footerLegal from '@/components/chrome/footer/Legal.vue'
import footerMenu from '@/components/chrome/footer/Menu.vue'
import FooterLegalCommune from '@/components/commune/footer/LegalCommune.vue'
import footerChatbot from '@/components/chrome/footer/Chatbot.vue'

export default defineComponent({
  components: {
    FooterLegalCommune,
    footerContact,
    footerCta,
    footerLegal,
    footerMenu,
    footerChatbot,
  },
})
