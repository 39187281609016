





































import { gsap } from 'gsap'
import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'gaz-animation',
  components: {},

  setup() {
    const circles = ref<SVGPathElement[]>([])
    const isVisible = ref(false)
    const tl = ref<gsap.core.Timeline | null>(null)

    const initTl = () => {
      const tl = gsap.timeline({
        paused: true,
        repeat: -1,
      })

      tl.to(circles.value, 0.8, {
        stagger: -0.2,
        opacity: 0,
      })
      tl.to(circles.value, 1, {
        stagger: -0.2,
        opacity: 1,
      })

      return tl
    }

    const onAppear = visible => {
      if (visible) {
        isVisible.value = true
        tl.value!.play()
      } else {
        isVisible.value = false
        tl.value!.pause()
      }
    }

    onMounted(() => (tl.value = initTl()))

    return {
      circles,
      onAppear,
    }
  },
})
