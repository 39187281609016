export const transformToHtmlId = (content: string): string =>
  content
    .toLowerCase()
    // remove accents
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    // remove slash
    .replaceAll('/', '_')
    // remove unsupported characters
    .replace(/[^A-Za-z0-9 [\]{}_.:-]/g, '')
    .trim()
    .replaceAll(' ', '_')
