






























































































































import { defineComponent, computed } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'

export default defineComponent({
  name: 'g-modal',
  components: { VInput },
  props: {
    addStyles: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
    title: String,
    body: String,
    footer: String,
    input: Boolean,
    component: {
      type: Object,
      default: () => ({}),
    },
    propsComponent: {
      type: Object,
      default: () => ({}),
    },
    parentInputDivStyle: {
      type: Object,
      default: () => ({}),
    },
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const sizeModal = computed(() => {
      const sizes = {
        small: 'modal col-xxxs-20 col-s-8 mb-l-20',
        medium: 'modal col-xxxs-20 col-s-12 mb-l-20',
        large: 'modal col-xxxs-20 col-s-16 mb-l-20',
      }

      return sizes[props.size]
    })

    return { sizeModal }
  },
})
