import { getApiUrl } from '@/inc/app.config'
import { useGetters } from '@u3u/vue-hooks'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import auth from '../auth'

const getters = {
  ...useGetters('connection', ['currentStep', 'stateForLogs']),
}

const history = ref<Array<string>>([])
const errors = ref<Array<string>>([])
const awsUrl = ref<Array<string>>([])
const documents = ref<Array<string>>([])
const currentStep = ref(getters.currentStep)

const addHistory = () => {
  history.value.push(JSON.stringify(currentStep.value).replace(/\\"/g, '"'))
}

const addErrors = (error: string) => {
  errors.value.push(JSON.stringify(error))
}

const addDocuments = document => {
  documents.value.push(JSON.stringify(document).replace(/\\"/g, '"'))
}

const addAws = aws => {
  awsUrl.value.push(JSON.stringify(aws).replace(/\\"/g, '"'))
}

const sendLogs = (processId = null) => {
  axios.post(`${getApiUrl()}/logs/connection`, {
    filename: processId
      ? `${auth.sessionId}_${processId}`
      : `${auth.sessionId}`,
    category: 'connection',
    errors: errors.value.toString(),
    awsurl: awsUrl.value.toString(),
    history: history.value.toString(),
    documents: documents.value.toString(),
    state: JSON.stringify(getters.stateForLogs.value).replace(/\\"/g, '"'),
  })
}

export const connectionLogs = {
  addHistory,
  addDocuments,
  addErrors,
  sendLogs,
  addAws,
}
