import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { MetersState, RootState } from '@/inc/types'
import { Register } from '@/inc/types/store'

const meterTypes = {
  '01': 'elec',
  '02': 'gaz',
  '03': 'propane',
}

const state: MetersState = {
  ean: '',
  eans: [],
  meters: [],
  type: '',
  email: '',
  backUrl: '',
  error: '',
  token: '',
  isPreferencesSet: false,
}

const getters: GetterTree<MetersState, RootState> = {
  inputs: state => {
    const inputs = [] as Register[]
    const ean = state.eans.find(item => item.ean === state.ean)
    const token = state.eans.find(item => item.token === state.token)
    if (ean) {
      ean.compteurs.forEach(meter =>
        meter.registres.forEach(item =>
          inputs.push({
            numCompteur: meter.numCompteur,
            numEquip: meter.numEquip,
            ...item,
          })
        )
      )
    }
    if (token) {
      token.compteurs.forEach(meter =>
        meter.registres.forEach(item =>
          inputs.push({
            numCompteur: meter.numCompteur,
            numEquip: meter.numEquip,
            ...item,
          })
        )
      )
    }

    return inputs
  },

  meters: state => {
    const ean = state.eans.find(item => item.ean === state.ean)
    const token = state.eans.find(item => item.token === state.token)
    if (ean) {
      return ean.compteurs
    }
    if (token) {
      return token.compteurs
    }

    return []
  },

  type: (state, getters) => meterTypes[getters.meters[0]?.sectActivite],
  getEan: state =>
    state.token.length > 0
      ? state.eans.find(x => x.token === state.token)?.ean
      : state.eans.find(x => x.ean === state.ean)?.ean,
}

const mutations: MutationTree<MetersState> = {
  SET_METERS(state, payload) {
    if (payload) {
      state.meters = payload
    }
  },
  SET_PREFERENCE(state, payload) {
    if (payload) {
      state.isPreferencesSet = payload
    }
  },
  SET_EAN(state, payload) {
    if (payload) {
      state.ean = payload
    }
  },
  SET_TOKEN(state, payload) {
    if (payload) {
      state.token = payload
    }
  },
  RESET_TOKEN(state) {
    state.token = ''
  },
  SET_ERROR(state, payload) {
    if (payload) {
      state.error = payload
    }
  },
  SET_BACKURL(state, payload) {
    if (payload) {
      state.backUrl = payload
    }
  },
  SET_EMAIL(state, payload) {
    if (payload) {
      state.email = payload
    }
  },
  RESET_EANS(state) {
    state.eans = []
  },
  ADD_EAN(state, payload) {
    if (payload) {
      state.eans.push({
        isSubmitted: false,
        ...payload,
        type: meterTypes[payload.compteurs[0]?.sectActivite],
      })
    }
  },
  SET_TYPE(state, payload) {
    state.type = payload
  },
  SET_AS_SUBMITTED(state, payload) {
    if (!payload) {
      return
    }

    state.eans.forEach(ean => {
      if (payload.Ean === ean.ean) {
        ean.isSubmitted = true
      }
    })
  },
}

const actions: ActionTree<MetersState, RootState> = {}

export const meters: Module<MetersState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
