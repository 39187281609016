
































































































import { defineComponent } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import { fetchLive } from '@/inc/utils'
import { Tip } from '@/inc/types'
import TipPanel from '@/components/TipPanel.vue'

export default defineComponent({
  name: 'tip',
  props: {
    content: {
      type: Object as () => Tip,
      required: true,
    },
  },

  setup(props, ctx) {
    if (!props.content.url) {
      return undefined
    }

    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n
    const { $router } = ctx.root
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const { route } = $router.resolve(props.content.url)

    const onClick = () => {
      showPanel({
        component: { template: TipPanel },
        content: fetchLive(route),
      })
    }

    return {
      onClick,
      stringsShared,
    }
  },
})
