import Vue from 'vue'
import Vuex, { Plugin, Store, StoreOptions } from 'vuex'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import VuexPersistence from 'vuex-persist'

import { AppType, RootState } from '@/inc/types'
// Modules
import { version } from './modules/version'
import { chrome } from './modules/chrome'
import { connection } from './modules/connection'
import { meters } from './modules/meters'
import { my } from './modules/my'
import { notifications } from './modules/notifications'
import { resource } from './modules/resource'
import { sidePanel } from './modules/side-panel'
import { user } from './modules/user'
import { intervention } from './modules/intervention'
import { guidance } from './modules/guidance'
import { outages } from './modules/outages'
import { faq } from './modules/faq'
import { gravityForm } from './modules/gravityForm'
import { commune } from './modules/commune'
import { permcapacities } from './modules/permcapacities'
import { forain } from './modules/forain'
import { mobiliteGuidance } from '@/store/modules/mobiliteGuidance'
import { terminal } from './modules/terminal'

// End modules

Vue.use(Vuex)

export default function createStore(type: AppType): Store<RootState> {
  const plugins: Plugin<RootState>[] = []

  if (type === 'client') {
    const allowedMutations = ['user/SET_USER', 'user/DELETE_USER']
    const vuexLocal = new VuexPersistence<RootState>({
      storage: window.sessionStorage,
      modules: ['user'],
      reducer: state => ({ user: state.user }), // Only save user module
      filter: mutation => allowedMutations.includes(mutation.type),
    })
    plugins.push(vuexLocal.plugin)

    const persistentConnection = new VuexPersistence<RootState>({
      storage: window.sessionStorage,
      modules: ['connection'],
      reducer: state => ({ connection: state.connection }),
    })
    plugins.push(persistentConnection.plugin)
  }

  const store: StoreOptions<RootState> = {
    modules: {
      chrome,
      resource,
      version,
      // Namespaced
      connection,
      meters,
      my,
      notifications,
      sidePanel,
      user,
      intervention,
      guidance,
      outages,
      faq,
      gravityForm,
      commune,
      permcapacities,
      forain,
      mobiliteGuidance,
      terminal,
    },
    plugins,
  }

  return new Vuex.Store<RootState>(store)
}
