import { Notification } from '@/inc/types'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserState extends UserAuthenticated {}

interface User {
  email: string
  contact: {
    contactEmail: string
    phone: string
    adresse: AddressResa
  }
  firstname: string
  lastname: string
}

export interface UserAuthenticated extends User {
  uid: null | string
  activated: boolean
  ppp: boolean
  smartPortal: boolean
  validPhone: boolean
  validContactEmail: boolean
  listeEan: Ean[]
  dossiers: Project[] | null
  // BU
  // profile: Record<string, boolean>[]
  profile: {
    communeAG: boolean
    communeEP: boolean
  }
  notifications: Notification[]
  preferences: Record<string, any>
  commune: Commune
  isFetching: boolean
}

export type UserPermission = 'ag' | 'ep' | 'ppp'

export interface Ghost {
  sessionId: string
}

export interface Ean {
  ean: string
  adresse: AddressResa
  sectActivite: '01' | '02'
  status: 'ACTIF'
  dateDebutReleve: string
  dateFinReleve: string
  cpt: string[]
  partenaireId: string
  profile: {
    smartMeter: boolean
    pPP: boolean
    social: boolean
  }
  sector: Sector
}

export interface LocalizedEan {
  [key: string]: {
    address: AddressResa
    listeEan: Ean[]
  }
}

export type Sector = 'electricite' | 'gaz'

export interface AddressResa {
  cdPostal: string
  localite: string
  rue: string
  numRue: string
}

export interface Project {
  partenaireId: number
  devis: string | null
}

export interface Commune {
  id: string | null
  actif: boolean | false
  admin: boolean | false
  codePostaux: string[]
  departement: string | null
  fonction: string | null
  localite: string | null
  roles: string[]
}

export const authorizationTypes = {
  ALL: '*',
  RACCORDEMENTS: 'raccordements',
  DOSSIERS: 'dossiers',
  INDEX_CONSULTER: 'index',
  INDEX_GERER: 'activations',
  CONSOMMATIONS: 'consommations',
  CONSOMMATIONS_EP: 'consommations_ep',
  ADRESSES: 'adresses',
}

export const userAuthorizedAccess = {
  ADMIN: [authorizationTypes.ALL],
  RACC_CONSU: [authorizationTypes.RACCORDEMENTS],
  RACC_GERER: [authorizationTypes.RACCORDEMENTS, authorizationTypes.DOSSIERS],
  INDEX_CPT_CONSU: [authorizationTypes.INDEX_CONSULTER],
  INDEX_CPT_GERER: [
    authorizationTypes.INDEX_CONSULTER,
    authorizationTypes.INDEX_GERER,
  ],
  CONSO_SMART: [authorizationTypes.CONSOMMATIONS],
  CONSO_EP: [authorizationTypes.CONSOMMATIONS_EP],
  POINT_CONSO: [authorizationTypes.ADRESSES],
}
