






















































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useActions } from '@u3u/vue-hooks'
import TipPanel from '@/components/TipPanel.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'v-input',
  props: {
    label: String,
    id: String,
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: [String, Number, Boolean],
    name: String,
    placeholder: String,
    minlength: [Number, String],
    html: String,
    maxlength: [Number, String],
    min: [Number, String],
    max: [Number, String],
    step: [Number, String],
    pattern: [RegExp, String],
    disabled: Boolean,
    focusOutEvent: {
      type: Object,
      isActive: false,
      emitValue: '',
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    tip: String,
    tipPostType: String,
    tipPanelValue: String,
    tipStyle: {
      type: Object,
      default: () => ({}),
    },
    tipIconStyle: {
      type: Object,
      default: () => ({}),
    },
    parentDivStyle: {
      type: Object,
      default: () => ({}),
    },
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
    hidePassword: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: '',
    },
  },

  setup(props, ctx) {
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const changeType = ref(true)
    const editType = ref(props.type)

    const showTip = e => {
      e.preventDefault()
      axios
        .get(`${getApiUrl()}/${props.tipPostType}/${props.tipPanelValue}`)
        .then(({ data }) => {
          showPanel({
            component: { template: TipPanel },
            content: data.content,
          })
        })
    }
    const onFocusOut = () => {
      ctx.root.$emit(`${props.focusOutEvent.emitValue}`)
    }
    const emitHiddenPassword = () => {
      changeType.value = !changeType.value
      if (changeType.value) {
        return 'password'
      }

      return 'text'
    }

    return {
      showTip,
      onFocusOut,
      emitHiddenPassword,
      changeType,
      editType,
    }
  },
})
