






























































































/* eslint-disable */
import {defineComponent, onBeforeMount, onMounted, ref, watch,} from '@vue/composition-api'
import ImgBlock from '@/components/ui/ImgBlock.vue'
import {useActions, useGetters, useMutations, useState} from '@u3u/vue-hooks'
import * as check from '@/composables/faq'
import PanelAccordion from '@/components/ui/PanelAccordion.vue'

export default defineComponent({
  name: 'faq-category',
  components: {
    PanelAccordion,
    ImgBlock,
  },
  props: {
    inModal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(_props, ctx) {
    const {VUE_APP_DOMAIN} = process.env
    const faqState = useState('faq', ['i18n', 'cat', 'item'])
    const faqActions = useActions('faq', ['FETCH_I18N', 'fetchCategories'])
    const {getCat} = useGetters('faq', ['getCat'])
    const {SET_CHILD, UPDATE_PRESELECTED, UPDATE_SECOND_PRESELECTED} = useMutations('faq', [
      'SET_CHILD',
      'UPDATE_PRESELECTED',
      'UPDATE_SECOND_PRESELECTED',
    ])
    const {currentLang} = useGetters(['currentLang'])

    const cat = ref(faqState.cat)
    const item = ref(faqState.item)
    const faqs = ref(Array())

    const choice = ref()
    const isDisplay = ref(false)
    const idChild = ref(0)
    const showChildren = ref(false)
    const modalDisplay = ref(_props.inModal)
    const loading = ref(false)

    let path = `/${currentLang.value}/`
    if (currentLang.value === 'de') {
      path += 'hilfe-und-kontakt/'
    } else {
      path += 'aide-et-contact/'
    }

    const urlFaq =
      currentLang.value === 'de'
        ? '/hilfe-und-kontakt/'
        : '/aide-et-contact/'

    onBeforeMount(() => {
      faqActions.FETCH_I18N()
    })

    onMounted(async () => {
      loading.value = true
      await faqActions.fetchCategories()
      const id = findId(ctx.root.$route.params?.theme)
      const themeParam = id
      if (themeParam && !modalDisplay.value) {
        const catToDeploy = getCat.value(themeParam)
        if (catToDeploy) {
          await fetchCat(catToDeploy)
          choice.value = catToDeploy.id
        }
      }
      loading.value = false
    })

    const findId = slug => {
      if(slug){
        return faqState.cat.value.find(x => x.slug === slug).id
      }
      
      return ''
    }

    const showCat = cat => {
      choice.value = null
      if (modalDisplay.value) {
        ctx.root.$emit('searchwp:close')
      }
      ctx.root.$router
          .push({
            path: path + cat.slug,
          })
          .catch(e => true)
    }

    const fetchCat = async cat => {
      faqs.value = Array()
      let result = null
      SET_CHILD(cat)
      for (let i = 0; i < cat.children.length; i++) {
        result = await check.fetchFaq(cat.children[i].id)
        faqs.value.push({id: cat.children[i].id, faqs: result})
        if (cat.children[i].children.length > 0) {
          for (let j = 0; j < cat.children[i].children.length; j++) {
            result = await check.fetchFaq(cat.children[i].children[j].id)
            faqs.value.push({
              id: cat.children[i].children[j].id,
              faqs: result,
            })
          }
        }
      }
    }

    const resetItem = () => {
      ctx.root.$router.push({ path: path})
    }

    const displayChild = (id, level) => {
      if (idChild && idChild.value === id) {
        showChildren.value = !showChildren.value
      } else {
        showChildren.value = true
      }
      idChild.value = id
      UPDATE_SECOND_PRESELECTED({id: level.id, name: level.name})
    }

    const displayCategories = id => {
      if (choice.value === undefined) {
        return true
      } else if (choice.value === id) {
        isDisplay.value = true
        return true
      }
      isDisplay.value = true
      return false
    }

    watch(choice, old => {
      UPDATE_PRESELECTED(choice.value)
      UPDATE_SECOND_PRESELECTED({})
    })

    return {
      i18n: faqState.i18n,
      item,
      cat,
      choice,
      faqs,
      isDisplay,
      displayCategories,
      showCat,
      resetItem,
      displayChild,
      showChildren,
      idChild,
      VUE_APP_DOMAIN,
      currentLang,
      loading,
      urlFaq,
    }
  },
})
