























import { defineComponent } from '@vue/composition-api'
import { useActions } from '@u3u/vue-hooks'
import { fetchLive } from '@/inc/utils'
import { TipIcon } from '@/inc/types'
import TipPanel from '@/components/TipPanel.vue'

export default defineComponent({
  name: 'tip-icon',
  components: { TipPanel },
  props: {
    content: {
      type: Object as () => TipIcon,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $router } = ctx.root
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const { route } = $router.resolve(props.content.url)
    const { wording } = props.content
    const onClick = () => {
      showPanel({
        component: { template: TipPanel },
        content: fetchLive(route),
      })
    }

    return {
      wording,
      onClick,
    }
  },
})
