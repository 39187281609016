


































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import CustomLink from '@/components/g/CustomLink.vue'
import { authorizationTypes } from '@/inc/types'

export default defineComponent({
  name: 'my-header-user',
  components: {
    CustomLink,
  },
  setup(_props, ctx) {
    const isOpen = ref(false)
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }
    const myState = {
      ...useState('commune', ['i18n']),
    }
    const { signOut } = auth
    const { authorizations } = useGetters('user', ['authorizations'])

    const closePanel = () => {
      isOpen.value = false
    }
    const togglePanel = () => {
      isOpen.value = !isOpen.value
    }

    const doSignOut = async () => {
      ctx.root.$emit('menu:commune:close')
      mutations.START_LOADING()
      await signOut(true)
      mutations.STOP_LOADING()
      ctx.root.$router.push({ name: 'CommuneSignin', replace: true })
    }

    return {
      isOpen,
      closePanel,
      togglePanel,
      doSignOut,
      ...useGetters('user', ['hasAccess', 'username']),
      i18n: myState.i18n,
      authorizations,
      authorizationTypes,
    }
  },
})
