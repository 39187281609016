








































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import ChromeFooter from '@/components/chrome/Footer.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import DevGrid from '@/components/DevGrid.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import MyHeader from '@/components/my/Header.vue'
import MyCommuneHeader from '@/components/commune/Header.vue'
import Notifications from '@/components/Notifications.vue'
import NotificationsHeader from '@/components/NotificationsHeader.vue'
import OverlayPanel from '@/components/OverlayPanel.vue'
import SidePanel from '@/components/SidePanel.vue'

import NotFound from '@/views/NotFound.vue'

import auth from '@/composables/auth'
import { StorageUtils } from '@/inc/plugins/storage'
import { isUnderMaintenance } from '@/inc/app.config'
import favicon from '@/inc/custom/favicon'

export default Vue.extend({
  name: 'app',
  components: {
    // ChromeNav,
    ChromeFooter,
    ChromeHeader,
    DevGrid,
    EnvSwitcher,
    MyHeader,
    MyCommuneHeader,
    NotFound,
    Notifications,
    NotificationsHeader,
    OverlayPanel,
    SidePanel,
  },
  data() {
    return {
      hasError: false,
      isCreated: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template']),
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
    }),
    ...mapMutations('my', {
      startLoading: 'START_LOADING',
      stopLoading: 'STOP_LOADING',
    }),
    ...mapMutations('user', {
      setUser: 'SET_USER',
    }),
    ...mapActions(['fetchChrome']),
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    this.$logger.trace('[app:created]', this.$$type)

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
      this.setResource(this.$router.$resource)
    } else {
      await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
      this.setResource(this.$router.$resource)
    }

    // ClientOnly permet de fix le soucis au niveau des changements de langues
    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null

      const { meta } = this.$route

      document.body.dataset.rendering === 'SPA' &&
        auth.onAuthStateChanged(async user => {
          /* if (isMyresa) {
      this.$router.push({ name: 'MySignin' }).catch(this.$logger.trace)
    }*/

          /* if (isCommuneResa) {
      this.$router
        .push({ name: 'CommuneSignin' })
        .catch(this.$logger.trace)
    }*/

          // Authenticated forbidden
          if (meta.auth === false && user !== null) {
            await auth.signOut(false)
          }
        })

      // First routing,
      // try to resurrect an existing user
      // or create a ghost
      // No authenticated nor ghost
      if (!auth.isLogged.value && !auth.isGhost.value) {
        if (this.$debug && this.user.uid !== null) {
          this.$logger.debug('DEV MODE !!! PLEASE DO NOT WAIT !!!')
          auth.setUser(this.user)
        } else {
          // For MyResa, we show loader
          meta.auth && this.startLoading()
          auth.resurrect().then(user => {
            this.stopLoading()
            user && this.setUser(user)

            if (meta.auth === true && user === null) {
              /* if (isMyresa) {
                this.$router
                  .push({ name: 'MySignin' })
                  .catch(this.$logger.trace)
              }
              */
              /* if (isCommuneResa) {
                this.$router
                  .push({ name: 'CommuneSignin' })
                  .catch(this.$logger.trace)
              } */
            }
          })
        }
        //   if (to.meta.key === 'myresa') {
        //   } else {
        //     // For other routes, it can be a background task…
        //     auth.resurrect().then(user => {
        //       user && store.commit('user/SET_USER', user)
        //     })
        //   }
      }
    }

    if (this.hasError) {
      return
    }
    this.isCreated = true

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }

    // await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })
    this.setResource(this.$router.$resource)
    this.isCreated = true
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.$on('scroll-disabled', () => (this.body.style.overflow = 'hidden'))
    this.$on('scroll-enabled', () => this.body.removeAttribute('style'))
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)
    },
  },
  head() {
    if (this.meta) {
      return {
        ...this.meta,
        link: favicon.link.concat(this.meta.link || []),
        meta: favicon.meta.concat(this.meta.meta || []),
      }
    }

    return {}
  },
})
