/* eslint-disable max-len */
import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

import { langAvailable, langDefault, segments } from '@/inc/app.config'

import View from '@/views/View.vue'
import myRoutes from './myRoutes'
import myCommune from './myCommune'
import { isCommuneResa, isMyresa } from '@/inc/utils'

const { VUE_APP_VERSION: version } = process.env

// Format segments to (a|b)
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

let routes: RouteConfig[] = [
  {
    // Redirection example (/fr/nested/ => /fr/nested/foo/)
    path: '/myresa/',
    redirect: { name: 'MyDashboard' },
  },
  {
    path: '/myresa/',
    name: 'MyResa',
    component: () =>
      import(/* webpackChunkName: 'v-my-resa' */ '@/views/MyResa.vue'),
    meta: {
      ssr: false,
      resource: ['pages/my-resa'],
      key: 'myresa',
    },
    pathToRegexpOptions: { strict: true },
    children: version === 'next' ? myRoutes : undefined,
  },
  {
    // Redirection example (/fr/nested/ => /fr/nested/foo/)
    path: '/mycommune/',
    redirect: { name: 'CommuneDashboard' },
  },
  {
    path: '/mycommune/',
    name: 'MyCommune',
    component: () =>
      import(/* webpackChunkName: 'v-my-commune' */ '@/views/MyCommune.vue'),
    meta: {
      ssr: false,
      resource: ['pages/my-commune'],
      key: 'mycommune',
    },
    pathToRegexpOptions: { strict: true },
    children: version === 'next' ? myCommune : undefined,
  },
  {
    path: `/${formatSegment('meterWizard')}/`,
    name: 'MeterWizard',
    component: () =>
      import(
        /* webpackChunkName: 'v-meter-wizard' */ '@/views/pages/MeterWizard.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/${formatSegment('meterWizardDe')}/`,
    name: 'MeterWizardDe',
    component: () =>
      import(
        /* webpackChunkName: 'v-meter-wizard' */ '@/views/pages/MeterWizard.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/${formatSegment('intervention')}/`,
    name: 'Intervention',
    component: () =>
      import(
        /* webpackChunkName: 'v-intervention' */ '@/views/pages/Intervention.vue'
      ),
    meta: {
      ssr: true,
    },
  },
  {
    path: `/${formatSegment('news')}/:slug*`,
    name: 'News',
    component: () =>
      import(/* webpackChunkName: 'v-news' */ '@/views/News.vue'),
    meta: {
      ssr: true,
      endpoint: 'news',
    },
  },
  {
    path: `/${formatSegment('aide')}/`,
    name: 'Faq',
    component: () =>
      import(/* webpackChunkName: 'v-faq' */ '@/views/pages/Faq.vue'),
    meta: {
      ssr: true,
      endpoint: 'pages',
    },
  },
  {
    path: `/${formatSegment('aide')}/:theme`,
    name: 'FaqTheme',
    component: () =>
      import(/* webpackChunkName: 'v-faq-details' */ '@/views/pages/Faq.vue'),
    meta: {
      ssr: true,
      endpoint: 'faq-themes',
    },
  },
  {
    path: `/${formatSegment('aide')}/:slug*`,
    name: 'FaqDetails',
    component: () =>
      import(/* webpackChunkName: 'v-faq-details' */ '@/views/FaqDetails.vue'),
    meta: {
      ssr: true,
      endpoint: 'faq',
    },
  },
  {
    path: ':slug/:sub?',
    name: 'Page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
  {
    path: '*',
    name: 'All',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    // Redirection
    {
      path: '/i/',
      redirect: `/fr/${formatSegment('meterWizard')}/`,
    },
    {
      path: '/fr/i/',
      redirect: `/fr/${formatSegment('meterWizard')}/`,
    },
    {
      path: '/de/i/',
      redirect: `/de/${formatSegment('meterWizardDe')}/`,
    },
    {
      path: '/fr/faq/',
      redirect: `/fr/${formatSegment('contact')}/`,
    },
    {
      path: '/de/faq/',
      redirect: `/de/${formatSegment('contactDe')}/`,
    },
    {
      path: `/:lang(${langAvailable.join('|')})/homepage/`,
      redirect: `/:lang(${langAvailable.join('|')})/`,
    },
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: View as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
        ssr: false,
        resource: ['pages/my-resa'],
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
    {
      // FIX redirection quand une langue n'existe pas --> redirige vers accueil
      path: '/*/',
      redirect: `/${langDefault}/`,
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

// FEATURE: contextual routing
if (isMyresa) {
  // const root: RouteConfig[] = [
  //   {
  //     path: '/',
  //     redirect: { name: 'MyDashboard' },
  //   },
  // ]

  // routes = root.concat(
  //   myRoutes.map(r => {
  //     r.path = `/${r.path}`

  //     if (r.alias) {
  //       r.alias = `/${r.alias}`
  //     }

  //     return r
  //   })
  // )

  routes = [
    {
      path: '/',
      redirect: { name: 'MyDashboard', params: { lang: 'fr' } },
    },
    {
      path: '/',
      name: 'MyResa',
      component: () =>
        import(/* webpackChunkName: 'v-my-resa' */ '@/views/MyResa.vue'),
      meta: {
        ssr: false,
        static: true,
        key: 'myresa',
      },
      pathToRegexpOptions: { strict: true },
      children: myRoutes,
    },
  ]
  // If multilanguage, add lang segment
  if (langAvailable.length > 1) {
    const initialRoutes = [...routes]
    routes = [
      {
        path: `/:lang(${langAvailable.join('|')})`,
        component: View as VueConstructor<Vue>,
        meta: {
          // ScrollTop all the time for all routes
          scrollTop: true,
        },
        children: initialRoutes
          .filter(r => r.meta?.root !== true)
          .map(r => {
            // Remove leading slash
            r.path = r.path.replace(/^\//, '')

            return r
          }),
      },
      {
        path: '/',
        redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
      },

      /* {
        // FIX redirection quand une langue n'existe pas sur myresa --> redirige vers accueil
        path: '/*\/',
        redirect: `/${langDefault}/`,
      },*/
    ]

    routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
  }
}
if (isCommuneResa) {
  routes = [
    {
      path: '/',
      redirect: {
        name: 'CommuneDashboard',
        params: { lang: 'fr' },
      },
    },
    {
      path: '/',
      name: 'MyCommune',
      component: () =>
        import(/* webpackChunkName: 'v-my-commune' */ '@/views/MyCommune.vue'),
      meta: {
        ssr: false,
        static: true,
        key: 'mycommune',
      },
      pathToRegexpOptions: { strict: true },
      children: myCommune,
    },
  ]
  // If multilanguage, add lang segment
  if (langAvailable.length > 1) {
    const initialRoutes = [...routes]
    routes = [
      {
        path: `/:lang(${langAvailable.join('|')})`,
        component: View as VueConstructor<Vue>,
        meta: {
          // ScrollTop all the time for all routes
          scrollTop: true,
        },
        children: initialRoutes
          .filter(r => r.meta?.root !== true)
          .map(r => {
            // Remove leading slash
            r.path = r.path.replace(/^\//, '')

            return r
          }),
      },
      {
        path: '/',
        redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
      },

      /* {
        // FIX redirection quand une langue n'existe pas sur myresa --> redirige vers accueil
        path: '/*\/',
        redirect: `/${langDefault}/`,
      },*/
    ]

    routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
  }
}

export { routes }
