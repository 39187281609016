













































































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'dev-grid',
  components: {},
  setup() {
    const $el = ref<HTMLElement | null>(null)
    const isVisible = ref(false)
    const isDev = computed(
      () =>
        process.env.VUE_APP_RELEASE === 'staging' ||
        process.env.VUE_APP_RELEASE === 'development'
    )

    const show = () => {
      $el.value!.style.display = 'block'
      isVisible.value = true
    }

    const hide = () => {
      $el.value!.style.display = 'none'
      isVisible.value = false
    }

    const toggle = () => {
      // eslint-disable-next-line
      isVisible.value ? hide() : show()
    }

    const onKeyDown = e => {
      if (e.shiftKey && e.key === 'G') {
        toggle()
      }
    }

    onMounted(() => {
      if (!isDev) {
        return
      }

      window.addEventListener('keydown', onKeyDown)
    })

    return {
      $el,
      isDev,
    }
  },
})
