import axios, { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { RootState } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import { PermCapacitiesState } from '@/inc/types/store/permcapacities'
import { useGetters } from '@u3u/vue-hooks'

/* eslint-disable */
const state: PermCapacitiesState = {
  // Traductions
  i18n: {},
  records: [],
}

const getters: GetterTree<PermCapacitiesState, RootState> = {
  getI18n: state => state.i18n,
  getRecords: state => state.records,
}

const mutations: MutationTree<PermCapacitiesState> = {
  SET_I18N(state, payload) {
    state.i18n = payload
  },
  SET_RECORDS(state, payload) {
    state.records = payload
  },
}

const actions: ActionTree<PermCapacitiesState, RootState> = {
  FETCH_I18N({commit}) {
    axios.get(`${getApiUrl()}/perm-capacity/translations`).then(response => {
      commit('SET_I18N', response.data)
    })
  },
  async FETCH_RECORDS({commit}) {
    const {currentLang} = useGetters(['currentLang'])
    commit('SET_RECORDS', [])
    const response: AxiosResponse = await axios.get(`${getApiUrl()}/perm-capacity/${currentLang.value}/list`)
    commit('SET_RECORDS', response.data)
  },
}

export const permcapacities: Module<PermCapacitiesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
