






































































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'triple-cta',
  props: {
    content: Object,
  },

  setup() {
    return {}
  },
})
