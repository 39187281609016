
























import List from '@/components/news/List.vue'
import Message from '@/components/ui/Message.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'

import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-wysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    let content = ''
    if (props.content?.htmltext) {
      // eslint-disable-next-line quotes
      content = props.content?.htmltext.replaceAll("'", '’')
    } else {
      // eslint-disable-next-line quotes
      content = props.wysiwyg.replaceAll("'", '’')
    }
    // eslint-disable-next-line quotes
    content = content.replaceAll('=’', "='")
    // eslint-disable-next-line quotes
    content = content.replaceAll('}’', "}'")

    const runtimeComponent = computed(() => ({
      components: {
        Message,
        'v-list': List,
        RelatedLinks,
      },
      template: `<div>${content}</div>`,
    }))

    return {
      runtimeComponent,
    }
  },
})
