














































































































































import { defineComponent, reactive } from '@vue/composition-api'

interface Btn {
  label: string
  url?: string
  icon?: Record<string, unknown>
  to?: string | Record<string, unknown>
}

export default defineComponent({
  name: 'g-btn',
  props: {
    btn: {
      type: Object as () => Btn,
      default: {} as Btn,
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },

  setup(props) {
    const icon = reactive({
      size: '0 0 31 13',
      symbol: 'ui-full-arrow',
    })

    return {
      icon: props.btn?.icon || icon,
    }
  },
})
