var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"outside",rawName:"v-outside",value:(_vm.closePanel),expression:"closePanel"}],staticClass:"my-header-user-outer",class:{ 'is-dropdown': _vm.isOpen }},[_c('div',{staticClass:"my-header-user",on:{"click":_vm.togglePanel}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"my-header-user__icon",attrs:{"size":"0 0 14 15","symbol":"icons-profile"}}),_c('div',{staticClass:"my-header-user__name"},[_vm._v(_vm._s(_vm.username))]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"my-header-user__arrow",attrs:{"size":"0 0 22 14","symbol":"ui-arrow"}})]),(_vm.isOpen)?_c('nav',{staticClass:"my-panel--content",attrs:{"role":"navigation"}},[_c('div',{staticClass:"my-panel__arrow"}),[_c('div',{staticClass:"my-panel__title"},[_vm._v(_vm._s(_vm.i18n.communeUserMenu.mainTitle))]),_c('CustomLink',{attrs:{"content":{
          label: _vm.i18n.communeUserMenu.profileTitle,
          to: { name: 'CommuneProfile' },
        }},on:{"btnClick":function($event){return _vm.closePanel()}}}),(_vm.authorizations.includes(_vm.authorizationTypes.ALL))?_c('CustomLink',{attrs:{"content":{
          label: _vm.i18n.communeUserMenu.usersTitle,
          to: { name: 'CommuneUsers' },
        }},on:{"btnClick":function($event){return _vm.closePanel()}}}):_vm._e(),(
          _vm.authorizations.includes(_vm.authorizationTypes.ALL) ||
          _vm.authorizations.includes(_vm.authorizationTypes.ADRESSES)
        )?_c('CustomLink',{attrs:{"content":{
          label: _vm.i18n.communeUserMenu.addressTitle,
          to: { name: 'CommuneAddress' },
        }},on:{"btnClick":function($event){return _vm.closePanel()}}}):_vm._e()],(
        _vm.i18n.communeUserMenu.links && _vm.i18n.communeUserMenu.links.length > 0
      )?_c('div',{staticClass:"mt-xxxs other-links-container"},[_c('ul',{staticClass:"nav-items"},_vm._l((_vm.i18n.communeUserMenu.links),function(item,index){return _c('li',{key:index,staticClass:"nav-link-item"},[_c('a',{attrs:{"href":item.link.url,"target":item.link.target,"rel":"noopener"}},[_vm._v(" "+_vm._s(item.link.title)+" ")])])}),0)]):_vm._e(),_c('CustomLink',{staticClass:"logout-btn",attrs:{"content":{
        label:
          _vm.$options.filters &&
          _vm.$options.filters.capitalize(_vm.i18n.communeUserMenu.logoutBtn),
      },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full'],"type":"button"},on:{"btnClick":function($event){return _vm.doSignOut()}}})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }