































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'footerCta',

  setup() {
    const resaUrl = VUE_APP_DOMAIN

    return {
      ...useGetters(['chrome']),
      resaUrl,
    }
  },
})
