/* eslint-disable @typescript-eslint/ban-types */
import axios from 'axios'
import { computed, ComputedRef, Ref, ref, watch } from '@vue/composition-api'
import * as sso from '@/composables/sso'
import { user as userStore } from '@/store/modules/user'
import { useStore } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
import { isCommuneResa, isMyresa, logger } from '@/inc/utils'
import { StorageUtils } from '@/inc/plugins/storage'

import { Ghost, UserAuthenticated, UserState } from '@/inc/types'

export interface UserAuth {
  Username: string
  Password: string
}

interface Auth {
  readonly currentUser: Ref<UserAuthenticated | null | undefined>
  readonly currentGhostAsync: Promise<Ghost | null>
  sessionId: string | null
  isGhost: ComputedRef<boolean>
  isLogged: ComputedRef<boolean>
  email: ComputedRef<string | null | undefined>

  setUser(user: UserAuthenticated | null): void

  init(storage: StorageUtils): void

  signIn(params: UserAuth): Promise<UserAuthenticated | null>

  signOut(force?: boolean): Promise<void>

  resurrect(): Promise<UserAuthenticated | null>

  refresh(): void

  createGhost(): Promise<Ghost | null>

  onAuthStateChanged(fn: Function): () => void
}

const _user = ref<UserAuthenticated | null | undefined>()
let _ghostAsync: Promise<Ghost | null> = Promise.resolve(null)
let _storage: StorageUtils

const _sessionId = ref<string | null>(null)
const _observers = new Map<string, Set<Function>>()
const _subscribe = (key: string, fn: Function) => {
  if (_observers.has(key)) {
    const set = _observers.get(key)

    set && set.add(fn)
  } else {
    _observers.set(key, new Set([fn]))
  }
}
const _unsubscribe = (key: string, fn: Function) => {
  if (_observers.has(key)) {
    const set = _observers.get(key)

    set && set.delete(fn)
  }
}

const _dashboard = () => {
  if (isCommuneResa) {
    // In this case we only need a quick call to dashboard WS
    return axios
      .get(`${getApiUrl()}/me/dashboard?Quick=true`)
      .then(res => {
        auth.setUser(res.data as UserAuthenticated)

        return res.data
      })
      .catch(error => {
        logger.error(error)

        throw error
      })
  }

  return axios
    .get(`${getApiUrl()}/me/dashboard`)
    .then(res => {
      auth.setUser(res.data as UserAuthenticated)

      return res.data
    })
    .catch(error => {
      logger.error(error)

      throw error
    })
}

// const _sync = () => axios.post(`${getApiUrl()}/users/sync`)

const _getToken = (params: UserAuth) =>
  axios.post(`${getApiUrl()}/users/authenticate`, params)

const _getUser = () => {
  const token = _storage.getItem('token', false)

  if (token === null) {
    return Promise.reject(new Error('No token found'))
  }

  // if (isMyresa) {
  //   // _sync()
  //   //   .then(res => {
  //       _dashboard()
  //     })
  //     .catch(err => logger.error('[SYNC] error'))
  // }

  return _dashboard()
}

const _createGhost = () =>
  axios
    .post(`${getApiUrl()}/users`)
    .then(res => {
      logger.trace('_createGhost', res.data)

      return res.data as Ghost
    })
    .catch(error => {
      logger.error(error)

      return null
    })

const auth: Auth = {
  get currentUser() {
    return _user
  },
  setUser(user: UserAuthenticated | null) {
    // Ne se trouve plus dans le WS14, ajout manuel
    if (user !== null) {
      user.activated = true
      user.profile = { communeAG: false, communeEP: false }
    }
    _user.value = user
  },
  get currentGhostAsync() {
    return _ghostAsync
  },
  get sessionId() {
    return _sessionId.value
  },
  set sessionId(id: string | null) {
    _sessionId.value = id
  },
  isGhost: computed(
    () =>
      (_user.value === null || _user.value === undefined) &&
      auth.sessionId !== null
  ),
  isLogged: computed(
    () =>
      _user.value !== null && _user.value !== undefined && !auth.isGhost.value
  ),
  email: computed((): string | undefined => _user.value?.email),
  init(storage: StorageUtils) {
    logger.trace('[auth:init]')
    // Save storage
    _storage = storage

    // Init interceptors
    axios.interceptors.request.use(
      config => {
        const token = _storage.getItem('token', false)
        const headers = config.headers || (config.headers = {})

        if (token !== null) {
          headers.Authorization = token
        }

        return config
      },
      error => Promise.reject(error)
    )

    axios.interceptors.response.use(
      response => {
        const { headers } = response

        if (headers && headers.Authorization) {
          _storage.setItem('token', headers.Authorization)
        }

        // FEATURE: SSO, no more token through data ?
        // DEV
        // if (data && data.access_token && data.access_token.length > 10) {
        // const { token } = data

        // if (token) {
        //   _storage.setItem('token', `Bearer ${token}`)
        // }

        return response
      },
      error => {
        if (error.response) {
          const { status } = error.response

          // if ([0, 401, 500].includes(status)) {
          if ([0, 401].includes(status)) {
            auth.signOut(false)
          }
        }

        return Promise.reject(error)
      }
    )
  },
  refresh() {
    logger.trace('[auth:refresh]')
    _dashboard()
  },
  async signIn(params: UserAuth) {
    try {
      logger.info('[auth:signIn]', params)

      const res = await _getToken(params)
      _storage.setItem('token', `Bearer ${res.data.token}`)

      // Probleme de sync qui fait charger trop longtemps
      // await _sync()
      const user = await _getUser()
      logger.info('[auth:signIn]', user)

      return user
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async signOut(force = false) {
    const store = useStore()
    logger.trace('[auth:signOut]')

    // Token invalidation
    if (_storage.getItem('token', false)) {
      try {
        await axios.post(`${getApiUrl()}/users/logout`)
      } catch (error) {
        logger.trace('[auth:signOut:Error]', error)
      }
    }

    // Local updates
    _storage.removeItem('token')
    auth.setUser(null)
    auth.sessionId = null
    userStore.mutations?.DELETE_USER({} as UserState)

    // Ghost creation
    await _createGhost()

    // TEMP: Refresh maintenance, Delet when WS maintenance is available
    await store.value.dispatch('fetchChrome')

    // SSO logout
    force && sso.logout()
  },
  async resurrect(): Promise<UserAuthenticated | null> {
    logger.trace('[auth:resurrect]')
    if (
      this.currentUser.value === null ||
      this.currentUser.value === undefined
    ) {
      try {
        const user = await _getUser()

        this.setUser(user as UserAuthenticated)
      } catch (error) {
        // logger.error('[auth:resurrect]', error)
        this.setUser(null)
        await this.createGhost()
      }
    }

    return this.currentUser.value as UserAuthenticated | null
  },
  async createGhost() {
    logger.trace('[auth:createGhost]')
    _ghostAsync = _createGhost()

    const ghost = await _ghostAsync

    if (ghost === null) {
      return ghost
    }

    this.sessionId = ghost.sessionId

    return ghost
  },
  onAuthStateChanged(fn: Function) {
    _subscribe('onAuthStateChanged', fn)

    return () => _unsubscribe('onAuthStateChanged', fn)
  },
}

watch(_user, (curr, prev) => {
  const all = _observers.get('onAuthStateChanged')

  all &&
    all.forEach(fn => {
      fn(curr, prev)
    })
})

export default auth
