import { F } from 'rambda'
import { RouteConfig } from 'vue-router'

const myMeta = {
  ssr: false,
  resource: ['pages/my-resa'],
  key: 'myresa',
}

const myMetaFull = {
  ...myMeta,
  auth: true,
  sidebar: true,
}

export default [
  // Signin
  {
    path: 'signin/',
    name: 'MySignin',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-mySignin' */ '@/views/my/Signin.vue'),
    meta: myMeta,
  },
  // Signup
  {
    path: 'signup/',
    name: 'MySignup',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-mySignup' */ '@/views/my/Signup.vue'),
    meta: {
      ...myMeta,
      auth: false,
    },
  },
  {
    path: 'signup/success/',
    name: 'MySuccess',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-mySuccess' */ '@/views/my/Success.vue'),
    meta: myMeta,
  },
  // Create Password
  {
    path: 'signup/password/',
    name: 'MyPassword',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-mySignup-password' */ '@/views/my/Password.vue'
      ),
    meta: myMeta,
  },
  // Validate phone number
  {
    path: 'signup/validate-sms/',
    name: 'ValidatePhone',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-mySignup-password' */ '@/views/my/ValidatePhone.vue'
      ),
    meta: myMeta,
  },
  // Reset
  {
    path: 'reset/',
    name: 'MyReset',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myReset' */ '@/views/my/Reset.vue'),
    meta: myMeta,
  },
  {
    path: 'reset/password/',
    name: 'MyResetPassword',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-myResetPassword' */ '@/views/my/Password.vue'
      ),
    meta: myMeta,
  },
  // Dashboard
  {
    path: 'dashboard/',
    name: 'MyDashboard',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-myDashboard' */ '@/views/my/Dashboard.vue'
      ),
    meta: myMetaFull,
  },
  // Ag
  {
    path: 'ag/',
    name: 'MyAg',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myAg' */ '@/views/my/Ag.vue'),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'ag/:slug/',
    name: 'MyAgSingle',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myAg' */ '@/views/my/Ag.vue'),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  // Profile
  {
    path: 'profile/:sub/',
    alias: 'profile/',
    name: 'MyProfile',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myProfile' */ '@/views/my/Profile.vue'),
    meta: myMetaFull,
  },
  // Dossier
  {
    path: 'suivi-dossier/',
    name: 'MyRecord',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myRecord' */ '@/views/my/Record.vue'),
    meta: myMetaFull,
  },
  {
    // Dossier
    path: 'suivi-dossier/:dossierId',
    name: 'MyRecordSingle',
    // pathToRegexpOptions: { strict: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'v-myRecord' */ '@/views/my/RecordSingle.vue'
      ),
    meta: myMetaFull,
  },
  {
    // refresh
    path: 'suivi-dossier/refresh',
    name: 'MyRefresh',
    // pathToRegexpOptions: { strict: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: 'v-myRefresh' */ '@/views/my/Refresh.vue'),
    meta: myMetaFull,
  },
  // Ean
  {
    path: 'ean/:id/:cpt/',
    component: () =>
      import(/* webpackChunkName: 'v-myEan' */ '@/views/my/Ean.vue'),
    meta: {
      ...myMetaFull,
      subkey: 'my-meter',
    },
    children: [
      {
        // Meter dashboard
        path: '/',
        name: 'MyMeterDashboard',
        // pathToRegexpOptions: { strict: true },
        // props: true,
        component: () =>
          import(
            /* webpackChunkName: 'v-myMeterDashboard' */ '@/views/my/MeterDashboard.vue'
          ),
        meta: {
          ...myMetaFull,
          subkey: 'my-meter',
        },
      },
      {
        // Meter history
        path: 'history/',
        name: 'MyMeterHistory',
        pathToRegexpOptions: { strict: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'v-myMeterHistory' */ '@/views/my/MeterHistory.vue'
          ),
        meta: {
          ...myMetaFull,
          subkey: 'my-meter',
        },
      },
      {
        // Meter invoices
        path: 'invoices/',
        name: 'MyMeterInvoices',
        pathToRegexpOptions: { strict: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'v-myMeterInvoices' */ '@/views/my/MeterInvoices.vue'
          ),
        meta: {
          ...myMetaFull,
          subkey: 'my-meter',
        },
      },
      {
        // Meter infos
        path: 'infos/',
        name: 'MyMeterInfo',
        pathToRegexpOptions: { strict: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'v-myMeterInfo' */ '@/views/my/MeterInfo.vue'
          ),
        meta: {
          ...myMetaFull,
          subkey: 'my-meter',
        },
      },
    ],
  },
  // PPP
  {
    path: 'ppp/',
    name: 'MyPpp',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myPpp' */ '@/views/my/Ppp.vue'),
    meta: myMeta,
  },
  {
    path: 'ppp/signup/',
    name: 'MyPppSignup',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-mySignup' */ '@/views/my/Signup.vue'),
    props: true,
    meta: myMeta,
  },
  {
    path: 'ppp/password/',
    name: 'MyPppPassword',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myPassword' */ '@/views/my/Password.vue'),
    props: true,
    meta: myMeta,
  },
  {
    path: 'ppp/choice/',
    name: 'MyPppChoice',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myChoice' */ '@/views/my/Choice.vue'),
    props: true,
    meta: myMeta,
  },
  {
    path: 'ppp/success/',
    name: 'MyPppSuccess',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-mySuccess' */ '@/views/my/Success.vue'),
    meta: myMeta,
  },
  {
    path: 'ppp/link/:token/',
    name: 'MyPppLink',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(/* webpackChunkName: 'v-myLink' */ '@/views/my/Link.vue'),
    meta: {
      ...myMeta,
      auth: true,
    },
  },
  {
    // Bornes de recharge
    path: 'bornes-recharge/',
    name: 'chargingTerminals',
    pathToRegexpOptions: { strict: true },
    component: () => import('@/views/my/ChargingTerminals.vue'),
    meta: myMetaFull,
  },
  {
    // Bornes de recharge
    path: 'bornes-recharge/:status',
    name: 'chargingTerminalsStatus',
    pathToRegexpOptions: { strict: true },
    component: () => import('@/views/my/ChargingTerminals.vue'),
    meta: myMetaFull,
  },
  {
    // Bornes de recharge
    path: 'bornes-recharge-edit/:uuid',
    name: 'chargingTerminalsEdit',
    // pathToRegexpOptions: { strict: true },
    props: true,
    component: () => import('@/views/my/ChargingTerminalsEdit.vue'),
    meta: myMetaFull,
  },

  {
    path: 'bornes-recharge-delete/:uuid',
    name: 'chargingTerminalsDelete',
    props: true,
    component: () => import('@/views/my/ChargingTerminalsDelete.vue'),
    meta: myMetaFull,
  },
  {
    // Simulations
    path: 'mobility-simulations/',
    name: 'MobilitySimulations',
    pathToRegexpOptions: { strict: true },
    component: () => import('@/views/my/guidance/MobilitySimulations.vue'),
    meta: myMetaFull,
  },
  {
    // Simulations list details
    path: 'mobility-simulations-details/',
    name: 'EanSimulationsList',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: () => import('@/views/my/guidance/EanSimulationsList.vue'),
    meta: myMetaFull,
  },
] as RouteConfig[]
