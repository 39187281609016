







































































































































import { defineComponent } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import { isCommuneResa } from '@/inc/utils'

export default defineComponent({
  name: 'breadcrumb',
  props: {
    content: {
      type: Array,
      default: {},
    },
    specificUrl: {
      type: String,
      required: false,
      default: isCommuneResa ? 'dashboard/' : '',
    },
    faq: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const chrome = useState(['chrome'])
    const { currentLang } = useGetters(['currentLang'])
    const urlFaq =
      currentLang.value === 'de'
        ? '/de/hilfe-und-kontakt/'
        : '/fr/aide-et-contact/'
    const faqLabel =
      currentLang.value === 'de' ? 'Hilfe & Kontakt' : 'Aide & Contact'

    return {
      ...useGetters(['meta']),
      stringsShared: chrome.chrome.value.data.i18n,
      currentLang,
      faqLabel,
      urlFaq,
    }
  },
})
