import dayjs from 'dayjs'
import axios from 'axios'

import { getApiUrl } from '@/inc/app.config'
import auth from '../auth'
import { dotToComma } from '@/inc/data'
import { logger } from '@/inc/utils'
import { connectionLogs } from '@/composables/connection/connectionLogs'
import { useGetters } from '@u3u/vue-hooks'
import { POWER_LIMIT } from '../const'

export const lang = () => {
  const { currentLang } = useGetters(['currentLang'])
  const lang = currentLang.value === 'de' ? 'DE' : 'FR'

  return lang
}

const getPartner = (partner, type, tva = 'N') => {
  const res = {
    TypePartenaire: type,
    IdPartenaire: '',
    Nom: partner.lastname,
    Prenom: partner.firstname,
    CodeCivilite: getCodeCivility(partner.civil),
    Langue: lang(),
    Gsm: partner.phoneCode + partner.phone,
    Tel: '',
    Email: partner.email,
    Fonction: partner.job ? partner.job : '',
    NumTVA: partner.tva ? partner.tva : '',
    AssujetiTVA: tva,
    TypeURD: type === 'DEMANDEUR' ? partner.type : '',
    NomEntreprise: partner.company ? partner.company : '',
    FormeJuridique: partner.legal ? partner.legal : '',
    Adresse: {
      Rue: partner.street,
      // IdRue : Code rue
      NumRue: partner.streetNumber,
      NumCmpt: partner.letterBox ? partner.letterBox : '',
      CdPostal: String(partner.zip),
      Localite: partner.area,
      // IdLocalite : Code localité,
      // ParcelleSpw : Parcelle SPW,
      // IdRadRue : Code rue RAD,
      // IdRadLocalite : Code localité RAD,
      Pays: partner.country,
    },
  }

  return res
}

const getPartners = state => {
  const partners: any = []
  const tva = state.tva.type === 'AUTO' ? 'Y' : 'N'

  if (!state.ownerData.isOwner) {
    partners.push(getPartner(state.ownerData, 'PROPRIETAIRE', tva))
  }
  if (state.contactData.isContact) {
    partners.push(getPartner(state.contactData, 'CONTACT', tva))
  }
  if (state.personalData.firstname !== null) {
    partners.push(getPartner(state.personalData, 'DEMANDEUR', tva))
  }

  return partners
}

/* eslint-disable */
const getCpts = meters => {
  const cpts: any = []
  let index = 1
  meters.forEach(meter => {
    if (meter.energy === 'electricity') {
      const cpt = {
        Compteur: index,
        DetailsCompteurs: [
          {
            CodeDetailCpt: 'PUISSANCE',
            ValeurDetailCpt: getPower(meter.power),
          },
          {
            CodeDetailCpt: 'NUMCPT',
            ValeurDetailCpt: meter.numcpt ? meter.numcpt : '',
          },
          // issue
          {
            CodeDetailCpt: 'TYPECOMPTEUR',
            ValeurDetailCpt: meter.phasesSlug ? (meter.phasesSlug === 'TRI' ? 'TRI' : 'MONO') : '',
          },
          {
            CodeDetailCpt: 'AMPERAGE',
            ValeurDetailCpt: getAmperage(meter.amperage),
          },
          {
            CodeDetailCpt: 'PUISSANCEPREL',
            ValeurDetailCpt: meter.samplingPower ? dotToComma(meter.samplingPower) : '',
          },
          {
            CodeDetailCpt: 'PUISSANCEINJECT',
            ValeurDetailCpt: meter.injectedPower ? dotToComma(meter.injectedPower) : '',
          },
          {
            CodeDetailCpt: 'TARIF',
            ValeurDetailCpt: meter.tarifSlug ? meter.tarifSlug : '',
          },
        ],
      }
      cpts.push(cpt)
    }
    if (meter.energy === 'gaz') {
      const cpt = {
        Compteur: index,
        DetailsCompteurs: [
          {
            CodeDetailCpt: 'PUISSANCE',
            ValeurDetailCpt: getPower(meter.power),
          },
          {
            CodeDetailCpt: 'NUMCPT',
            ValeurDetailCpt: meter.numcpt ? meter.numcpt : '',
          },
        ],
      }
      cpts.push(cpt)
    }
    index++
  })

  return cpts
}
/* eslint-enable */

const getItemDemande = (state, processus, meters, energy, cdType) => {
  const item = {
    // electricity = 01
    SectActivite: energy === 'electricity' ? '01' : '02',
    Ean: getEans(state.eans, energy, processus),
    TypeTravail: processus === 'new' ? 'R01' : 'R02',
    Details: [
      {
        CodeDetail: 'CD_TYPE',
        Valeur: cdType,
      },
      {
        CodeDetail: 'TYPE_UTILISATION',
        Valeur: state.building.usage,
      },
      {
        CodeDetail: 'LOTISSEMENT',
        Valeur: state.building.appartements ? 'Y' : 'N',
      },
      {
        CodeDetail: 'PROD_SUP_10KVA',
        Valeur: state.building.is10kva ? 'Y' : 'N',
      },
      {
        CodeDetail: 'REMARQUE',
        Valeur: state.request.remarkCpts[energy],
      },
      {
        CodeDetail: 'DEPLA_CPT',
        Valeur: state.request.deplaCpt ? 'Y' : 'N',
      },
      // {
      //   CodeDetail: 'PUISSANCE',
      //   Valeur: 20,
      // },
      // {
      //   CodeDetail: 'TYPE_RACC',
      //   Valeur: 20,
      // },
      // {
      //   CodeDetail: 'TYPE_IMMEUBLE',
      //   Valeur: 20,
      // },
    ],
    NbCompteurs: meters.length,
    Compteur: getCpts(meters),
  }

  if (state.request.typeSmart) {
    item.Details.push({
      CodeDetail: 'COFFRET_25S60',
      Valeur: state.request.typeSmart === '25S60' ? 'Y' : 'N',
    })
  }

  return item
}

const getDemande = state => {
  const demandes: any = []
  const emeters = state.meters.filter(meter => meter.energy === 'electricity')
  const gmeters = state.meters.filter(meter => meter.energy === 'gaz')

  if (state.processus === 'new') {
    if (emeters.length) {
      demandes.push(
        getItemDemande(state, 'new', emeters, 'electricity', state.subProcessus)
      )
    }

    if (gmeters.length) {
      demandes.push(
        getItemDemande(state, 'new', gmeters, 'gaz', state.subProcessus)
      )
    }
  } else if (state.processus === 'edit') {
    const emodifs = [
      ...new Set(emeters.map(meter => meter.modif).filter(meter => meter)),
    ]

    const gmodifs = [
      ...new Set(gmeters.map(meter => meter.modif).filter(meter => meter)),
    ]

    emodifs.forEach(modif => {
      const cptsmodif = emeters.filter(meter => meter.modif === modif)
      demandes.push(
        getItemDemande(state, 'edit', cptsmodif, 'electricity', modif)
      )
    })

    gmodifs.forEach(modif => {
      const cptsmodif = gmeters.filter(meter => meter.modif === modif)
      demandes.push(getItemDemande(state, 'edit', cptsmodif, 'gaz', modif))
    })
  }

  return demandes
}

// eslint-disable-next-line
export const getRequestWs35 = (state: any) => {
  return {
    Langue: lang(),
    Partenaire: getPartners(state),
    Adresse: {
      Rue: state.address.street,
      NumRue: state.address.streetNumber,
      NumCmpt: state.address.letterBox ? state.address.letterBox : '',
      CdPostal: state.address.zip,
      Localite: state.address.area,
      ParcelleSpw: state.address.parcelleSpw ? state.address.parcelleSpw : '',
      Pays: state.address.country,
      Emplacement: state.address.location,
      // IdRue : Code rue
      // IdLocalite : Code localité,
      // IdRadRue : Code rue RAD,
      // IdRadLocalite : Code localité RAD,
    },
    Demande: getDemande(state),
    Complement: [
      {
        Libelle: 'DATESOUHTEETRAV',
        Valeur: state.request.date,
        Titre: 'N',
      },
      {
        Libelle: 'TYPE_TVA',
        Valeur: state.tva.type,
        Titre: 'N',
      },
      {
        Libelle: 'TYPE_LOGEMENT',
        Valeur: state.tva.logement,
        Titre: 'N',
      },
      {
        Libelle: 'REMARQUE ',
        Valeur: state.request.remarkInfos ? state.request.remarkInfos : '',
        Titre: 'N',
      },
      {
        Libelle: 'COORDGPSY',
        Valeur: state.address.coordinates.lat,
        Titre: 'N',
      },
      {
        Libelle: 'COORDGPSX',
        Valeur: state.address.coordinates.lng,
        Titre: 'N',
      },
    ],
  }
}

export const getDescriptionContact = (state): string => {
  const infos = {
    Demande: state.processus === 'edit' ? 'Modification' : 'Nouveau',
    SousDemande: state.subProcessus,
    Partenaire: getPartners(state),
    Societe: state.personalData.company ? state.personalData.company : '',
    Adresse: {
      Rue: state.address.street,
      NumRue: state.address.streetNumber,
      NumCmpt: state.address.letterBox ? state.address.letterBox : '',
      AdresseConnue: state.address.existingAddress,
      CdPostal: state.address.zip,
      Localite: state.address.area,
      CoordGPSX: state.address.coordinates.lat,
      CoordGPSY: state.address.coordinates.lng,
      ParcelleSpw: state.address.parcelleSpw ? state.address.parcelleSpw : '',
      Emplacement:
        state.address.location === null ? '' : state.address.location,
    },
    TypeBatiment: {
      Type: state.building.type,
      Usage: state.building.usage,
    },
    Lotissement: state.building.appartements,
    Is10KVA: state.building.is10kva,
    TVA: {
      Type: state.tva.type,
      Logement: state.tva.logement,
      /* eslint-disable */
      AssujetiTVA: state.tva.type === 'AUTO' ? true : false,
      /* eslint-enable */
    },
    DateSouhaiteeTrav: state.request.date ? state.request.date : '',
    GDPR: dayjs().format('YYYYMMDD'),
    InformationCompteurs: {},
    Remarques: state.request,
  }

  if (state.specificMeters.length > 0) {
    const meters = {
      Compteur: state.specificMeters,
      InfomationRaccordement: state.specificInfo,
    }
    Object.assign(infos.InformationCompteurs, meters)
  }

  if (state.temporaryMeter) {
    const meters = {
      Compteur: state.temporaryMeter,
      InfomationRaccordement: state.temporaryInfo,
    }
    Object.assign(infos.InformationCompteurs, meters)
  }

  if (state.meters.length > 4) {
    const meters = {
      Compteur: state.meters,
      InfomationRaccordement: '',
    }
    Object.assign(infos.InformationCompteurs, meters)
  }

  return JSON.stringify(infos)
}

const sendTempFile = fileName => {
  const headers: Record<string, string> = {}

  if (auth.sessionId) {
    headers.SessionId = auth.sessionId
    headers['Content-Type'] = '*'
  }

  return axios.get(
    // eslint-disable-next-line
    `${getApiUrl()}/file/temp?FileName=${fileName}​​​​​​​​`,
    { headers }
  )
}

export const getDescriptionFiles = (rawFiles: Record<string, any>) => {
  const promises: any[] = []
  const headers: Record<string, string> = {}

  if (auth.sessionId) {
    headers.SessionId = auth.sessionId
    headers['Content-Type'] = '*'
  }

  for (const [key, value] of Object.entries(rawFiles)) {
    if (value.multiple) {
      for (const f of value.file) {
        // eslint-disable-next-line
        promises.push(
          new Promise(resolve => {
            const fileName = `${value.name}-
              ${value.file.indexOf(f) + 1}.${f.ext}`
            sendTempFile(fileName).then(res => {
              axios
                .put(res.data.putUrl, f.file, { headers })
                .then(_ =>
                  connectionLogs.addDocuments({
                    name: value.name,
                    status: 'OK',
                  })
                )
                .catch(err => {
                  connectionLogs.addDocuments({ name: value.name, status: 'X' })
                  connectionLogs.addErrors(`[RACC] Put temp url: ${err}`)
                })
              resolve({
                FileUrl: res.data.getUrl,
                DocumentName: fileName,
                Extension: f.ext,
              })
            })
          })
        )
      }
    } else {
      // eslint-disable-next-line
      promises.push(
        new Promise(resolve => {
          const fileName = `${value.name}.${value.file.ext}`
          sendTempFile(fileName).then(res => {
            axios
              .put(res.data.putUrl, value.file.file, { headers })
              .then(_ =>
                connectionLogs.addDocuments({ name: value.name, status: 'OK' })
              )
              .catch(err => {
                connectionLogs.addDocuments({ name: value.name, status: 'X' })
                connectionLogs.addErrors(`[RACC] Put temp url: ${err}`)
              })
            resolve({
              FileUrl: res.data.getUrl,
              DocumentName: value.name,
              Extension: value.file.ext,
            })
          })
        })
      )
    }
  }

  return Promise.all(promises)
}

const sendFile = async (name: string, file: any, numDossier: string) => {
  const headers: Record<string, string> = {}

  if (auth.sessionId) {
    headers.SessionId = auth.sessionId
    headers['Content-Type'] = '*'
  }

  const awsUrl = await axios
    .get(
      // eslint-disable-next-line
      `${getApiUrl()}/file/send?DocumentName=${name}&IdDossier=${numDossier}​​​​​​​​&Submit=false​​​​​​​​`, { headers }
    )
    .catch(err => connectionLogs.addErrors(`[RACC] GET url: ${err}`))

  if (awsUrl) {
    connectionLogs.addAws(awsUrl.data.putUrl)

    const customAxios = axios.create()
    delete customAxios.defaults.headers.common.Authorization

    await customAxios
      .put(awsUrl.data.putUrl, file, { headers: { 'Content-Type': '*' } })
      .then(_ => connectionLogs.addDocuments({ name, status: 'OK' }))
      .catch(err => {
        connectionLogs.addDocuments({ name, status: 'X' })
        connectionLogs.addErrors(`[RACC] PUT file: ${err}`)
      })
  }
}

/* eslint-disable */
export const sendFiles = async (files: Record<any, any>, numDossier: string) => {
  for (const [key, value] of Object.entries(files)) {
    if (value.multiple && value.file) {
      for (const f of value.file) {
        const name = `${value.name}-${value.file.indexOf(f) + 1}.${f.ext.toLowerCase()}`
        const file = f.file
        // eslint-disable-next-line
        await sendFile(name, file, numDossier)
      }
    } else if (value.file) {
      const name = `${value.name}.${value.file.ext.toLowerCase()}`
      const file = value.file.file
      // eslint-disable-next-line
      await sendFile(name, file, numDossier)
    }
  }
}
/* eslint-enable */

const getPower = power => {
  if (!power) {
    return ''
  }

  if (power === POWER_LIMIT) {
    return '+43.6'
  }

  return dotToComma(power)
}

const getAmperage = amperage => amperage || ''

const getCodeCivility = civilite => {
  if (civilite === 'Mr') {
    return '0002'
  } else if (civilite === 'Mme') {
    return '0001'
  } else if (civilite === 'Melle') {
    return '0020'
  }

  return ''
}

const getEans = (eans, energy, processus) => {
  if (processus === 'edit') {
    if (energy === 'electricity') {
      return eans['01']
    }

    if (energy === 'gaz') {
      return eans['02']
    }
  }

  return ''
}

export const getSubSection = (subprocessus, meters) => {
  if (subprocessus === 'FORAIN') {
    return 'DEMANDE RACCORDEMENT FORAIN'
  } else if (subprocessus === 'CHAN_PROVI') {
    return 'DEMANDE RACCORDEMENT CHANTIER'
  } else if (subprocessus === 'NOUV_RTEC') {
    return 'DEMANDE RACCORDEMENT TECHNIQUE BUS'
  } else if (subprocessus === 'NOUV_ARMO') {
    return 'DEMANDE RACCORDEMENT TECHNIQUE ARMOIRE'
  } else if (subprocessus === 'NOUV_CCOM') {
    return 'DEMANDE RACCORDEMENT TECHNIQUE SPÉCIFIQUE'
  }

  const gazInf = meters.filter(el => el.energy === 'gaz' && el.power < 70)
  const gazSup = meters.filter(el => el.energy === 'gaz' && el.power >= 70)
  const electInf = meters.filter(
    el => el.energy === 'electricity' && el.power < 27.7
  )
  const electSup = meters.filter(
    el =>
      el.energy === 'electricity' &&
      (el.power >= 27.7 || el.power === '+ de 27.7')
  )

  if (
    gazInf.length > 4 &&
    gazSup.length === 0 &&
    electSup.length === 0 &&
    electInf.length === 0
  ) {
    return 'DEMANDE RACCORDEMENT BUILDING GAZ'
  }

  if (electInf.length > 0 && electSup.length === 0 && gazSup.length === 0) {
    return 'DEMANDE RACCORDEMENT BUILDING'
  }

  return 'DEMANDE RACCORDEMENT AUTRE'
}
