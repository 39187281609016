

































import { gsap } from 'gsap'
import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'lines-animation',
  components: {},

  setup() {
    const el = ref<HTMLElement | null>()
    const lines = ref<HTMLElement[]>([])
    const linesNumber = 8
    const tweens: gsap.core.Tween[] = []
    let isPlaying = false

    const setLines = () => {
      gsap.set(lines.value, {
        top: gsap.utils.distribute({
          base: 10,
          amount: 300,
          from: 'random',
        }),
      })

      lines.value.forEach(line => {
        gsap.set(line, {
          left: `${gsap.utils.random(-20, 20)}%`,
          width: `${gsap.utils.random(10, 60)}%`,
        })
      })
    }

    const setTweens = () => {
      const duration = 4
      const ease = 'power2.out'

      lines.value.forEach(line => {
        const tween = gsap.to(line, duration, {
          paused: true,
          delay: gsap.utils.random(0, 5),
          scaleX: 1,
          ease,
          onComplete: () => {
            line.classList.toggle('is-reversed')
            gsap.to(line, duration, {
              scaleX: 0,
              ease,
              onComplete: () => {
                if (isPlaying) {
                  tween.progress(0).play()
                } else {
                  tween.progress(0).pause()
                }
              },
            })
          },
        })

        tweens.push(tween)
      })
    }

    const onAppear = isVisible => {
      if (isVisible && !isPlaying) {
        isPlaying = true
        tweens.forEach(tween => tween.play())
      }

      if (!isVisible && isPlaying) {
        isPlaying = false
        tweens.forEach(tween => tween.pause())
      }
    }

    onMounted(() => {
      setLines()
      setTweens()
    })

    return {
      el,
      lines,
      linesNumber,
      onAppear,
    }
  },
})
