










































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import { isCommuneResa } from '@/inc/utils'

export default defineComponent({
  name: 'notifications',
  setup(props, ctx) {
    const notifications = ref<HTMLElement[]>([])
    const { readThis } = useMutations('notifications', { readThis: 'READ' })
    const { fetch } = useActions('notifications', ['fetch'])
    const { unread } = useGetters('notifications', ['all', 'read', 'unread'])
    const getTransform = index => {
      const scale = index * 0.1 >= 0.4 ? 0.4 : index * 0.1
      const translateY = index * 10 > 30 ? 30 : index * 10

      // prettier-ignore
      return {
        scale: 1 - scale,
        translateY,
      }
    }

    const close = (notif, index) => {
      notifications.value[index].classList.add('is-viewed')
      ctx.root.$cookie.set(`notif-${notif.id}`, true)
      setTimeout(() => {
        readThis(notif)
      }, 100)
    }

    onBeforeMount(() => {
      fetch()
    })

    const unreadNotifications = computed(() =>
      unread.value.filter(
        item =>
          !ctx.root.$cookie.get(`notif-${item.id}`) &&
          (item.target === 'myresa' ||
            item.target === 'global' ||
            item.target === 'my')
      )
    )

    const unreadNotifCommune = computed(() =>
      unread.value.filter(
        item =>
          !ctx.root.$cookie.get(
            `notif-${item.id}` &&
              (item.target === 'mycommune' ||
                item.target === 'global' ||
                item.target === 'my')
          )
      )
    )

    return {
      unread: isCommuneResa ? unreadNotifCommune : unreadNotifications,
      close,
      getTransform,
      notifications,
      isCommuneResa,
    }
  },
})
