



















































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import CustomLink from '@/components/g/CustomLink.vue'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'my-header-user',
  components: {
    CustomLink,
  },
  setup(_props, ctx) {
    const isOpen = ref(false)
    const myState = {
      ...useState('my', ['i18n']),
    }
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }
    const { signOut } = auth

    const closePanel = () => {
      isOpen.value = false
    }
    const togglePanel = () => {
      isOpen.value = !isOpen.value
    }

    const doSignOut = async () => {
      const event = {
        event: 'MyRESA_events',
        eventLabel: 'Logout_confirmation',
      } as Event
      gtm.sendEvent(event)

      mutations.START_LOADING()
      await signOut(true)
      mutations.STOP_LOADING()
      ctx.root.$router.push({ name: 'MySignin', replace: true })
    }

    return {
      isOpen,
      closePanel,
      togglePanel,
      doSignOut,
      ...useGetters('user', ['hasAccess', 'username']),
      i18n: myState.i18n,
    }
  },
})
