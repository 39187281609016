



















































































import { PortalTarget } from 'portal-vue'
import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'overlay-panel',
  components: {
    PortalTarget,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const body = ref<HTMLElement | null>(null)

    const close = () => {
      el.value!.classList.remove('is-visible')
      ctx.root.$el.classList.remove('scroll-disabled')
      body.value!.removeAttribute('style')
      ctx.root.$emit('overlay-panel:close')
    }

    const open = () => {
      el.value!.classList.add('is-visible')
      body.value!.style.overflow = 'hidden'
    }

    onMounted(() => {
      body.value = document.body
      ctx.root.$on('overlay-panel:open', open)
    })

    return {
      el,
      close,
    }
  },
})
