import Vue from 'vue'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import * as Sentry from '@sentry/vue'

import { routes } from './routes'
import { guards } from './guards'
import { RootState } from '@/inc/types'
import { logger } from '@/inc/utils'

Vue.use(VueRouter)

export default function createRouter(store: Store<RootState>): VueRouter {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, _from, savedPosition) {
      // Full example: https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
      // Can return:
      // - "falsy" (null | void | {})
      // - { x: number, y: number }
      // - { selector: string, offset?: { x: number, y: number } }
      // - Promise (e.g. waiting for event/transition)

      if (savedPosition) {
        // SavedPosition is only available for popstate navigations.
        return savedPosition
      }

      // Manage scroll to anchor
      if (to.hash) {
        if (document.querySelector(to.hash)) {
          return {
            selector: to.hash as string,
          }
        }

        return null
      }

      // Check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollTop)) {
        // Coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        return {
          x: 0,
          y: 0,
        }
      }

      return null
    },
  })

  guards(router, store)

  logger.debug(
    'routes',
    router.options.routes?.map(r => r.path)
  )

  if (
    process.env.VUE_APP_SENTRY_ENV !== 'Production' && // To Remove later
    process.env.VUE_APP_SENTRY_ENV !== 'Staging' && // To Remove later
    process.env.VUE_APP_SENTRY_ENV !== 'Developpement' &&
    process.env.VUE_APP_SENTRY_ENV !== 'Serve'
  ) {
    Sentry.init({
      Vue,
      dsn:
        'https://56a0358abd98a9b47553203ee5b777a1@sentry.resa.be:3443/4506076322725888',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
          ],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      // eslint-disable-next-line max-len
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // eslint-disable-next-line max-len
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Custom
      environment: process.env.VUE_APP_SENTRY_ENV,
    })
  }

  return router
}
