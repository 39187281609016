












































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'footer-menu',

  setup() {
    const isOpen = ref<boolean>(false)
    const resaUrl = VUE_APP_DOMAIN
    const closeMenu = () => {
      isOpen.value = false
      document.body.classList.remove('no-scroll')
    }
    const toggleMenu = () => {
      isOpen.value = !isOpen.value
      document.body.classList.toggle('no-scroll')
    }

    return {
      ...useGetters(['chrome']),
      isOpen,
      closeMenu,
      toggleMenu,
      resaUrl,
    }
  },
})
