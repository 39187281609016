const ssoBaseUrl = 'https://login.resa.be/adfs/oauth2'
const { VUE_APP_SSO_ID } = process.env

import { getCallbackUrl } from '@/inc/utils'

export const openPopup = url =>
  window.open(
    url,
    'sso',
    'location=yes,status=yes,left=0,top=0,width=800,height=600'
  )
export const authorize = (cb = '/dashboard/', origine: string = origin) => {
  const callback = getCallbackUrl(cb)

  /* eslint-disable camelcase */
  const q = {
    client_id: VUE_APP_SSO_ID,
    response_type: 'id_token',
    redirect_uri: `${origine}/sso.html?cb=${callback}`,
  }
  /* eslint-enable camelcase */
  const url = `${ssoBaseUrl}/authorize`

  openPopup(
    `${url}?${Object.keys(q)
      .map(k => `${k}=${q[k]}`)
      .join('&')}`
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const logout = (...cb: Function[]) => {
  cb.forEach(f => f.apply(null))

  const { origin } = window.location
  const url = `${ssoBaseUrl}/logout?redirect_uri=${origin}/sso.html`

  openPopup(url)
}
