

































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'info-callout',
  props: {
    title: String,
    content: String,
  },
  components: {},
  setup() {
    return {}
  },
})
