

































































































import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'g-list',
  props: {
    title: String,
    items: Array,
    iscategory: Boolean,
    isdownloads: Boolean,
  },
  setup(props) {
    const customClass = computed(() => [
      { 'is-category': props.iscategory },
      { 'is-downloads': props.isdownloads },
    ])

    return {
      customClass,
    }
  },
})
