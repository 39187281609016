






















































































































































































import SearchModule from '@/components/chrome/search/SearchModule.vue'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'search-wp',
  components: { SearchModule },
  setup(_prop, ctx) {
    const click = ref(false)
    const input = ref('')
    const result = ref()
    // partie i18n , permettant de recuperer les traductions
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])

    onBeforeMount(() => {
      actions.FETCH_I18N()
    })
    const modalClick = () => {
      ctx.root.$emit('searchwp:open')
      click.value = true
      input.value = ''
      result.value = {}
    }
    const modalClose = () => {
      click.value = false
      ctx.root.$emit('searchwp:close')
    }
    ctx.root.$on('searchwp:close', () => {
      click.value = false
    })

    return {
      click,
      modalClick,
      modalClose,
      input,
      i18n: state.i18n,
      ...useGetters(['chrome']),
    }
  },
})
