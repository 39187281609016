import { RouteConfig } from 'vue-router'

const myMeta = {
  ssr: true,
  resource: ['pages/my-commune'],
  key: 'mycommune',
  title: 'MyCommune',
}

const myMetaFull = {
  ...myMeta,
  auth: true,
  sidebar: true,
}

export default [
  // Signin
  {
    path: 'signin/',
    name: 'CommuneSignin',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeSignin' */ '@/views/commune/Signin.vue'
      ),
    meta: myMeta,
  },

  // Reset
  {
    path: 'reset/',
    name: 'CommuneReset',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-CommuneReset' */ '@/views/commune/Reset.vue'
      ),
    meta: myMeta,
  },
  {
    path: 'reset/password/',
    name: 'CommuneResetPassword',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-CommuneResetPassword' */ '@/views/commune/Password.vue'
      ),
    meta: myMeta,
  },
  {
    path: 'signup/success/',
    name: 'CommuneSuccess',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-mySuccess' */ '@/views/commune/Success.vue'
      ),
    meta: myMeta,
  },
  // Create Password
  {
    path: 'signup/password/',
    name: 'CommunePassword',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-mySignup-password' */ '@/views/commune/Password.vue'
      ),
    meta: myMeta,
  },
  // Dashboard
  {
    path: 'dashboard/',
    name: 'CommuneDashboard',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeDashboard' */ '@/views/commune/Dashboard.vue'
      ),
    meta: myMetaFull,
  },
  // raccordement
  {
    path: 'records/',
    name: 'CommuneRecord',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeRecord' */ '@/views/commune/record/Record.vue'
      ),
    meta: myMetaFull,
  },
  {
    // Dossier
    path: 'records/:id',
    name: 'CommuneRecordSingle',
    // pathToRegexpOptions: { strict: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'v-communeRecordSingle' */ '@/views/commune/record/RecordSingle.vue'
      ),
    meta: myMetaFull,
  },
  // Gestion des users
  {
    path: 'users/',
    name: 'CommuneUsers',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeUsers' */ '@/views/commune/Users.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'users/add/',
    name: 'CommuneAddUser',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeUsersAdd' */ '@/views/commune/user/Add.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'users/edit/',
    name: 'CommuneEditUser',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeUsersEdit' */ '@/views/commune/user/Edit.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'address/',
    name: 'CommuneAddress',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeAddress' */ '@/views/commune/Address.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'users/profile/',
    name: 'CommuneProfile',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeUsersProfile' */ '@/views/commune/user/Profile.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  // Relevés d'index
  {
    path: 'index/',
    name: 'CommuneIndex',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeRelevesIndex' */ '@/views/commune/index/RelevesIndex.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'index/meter/:ean/:compteur/',
    name: 'CommuneDetailsCompteur',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeDetailsCompteur' */ '@/views/commune/index/DetailsCompteur.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: 'index/meter/history/:ean/:compteur/',
    name: 'CommuneIndexHistorique',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeHistoriqueIndex' */ '@/views/commune/index/HistoriqueIndex.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  // Consommations
  {
    path: 'consommations/',
    name: 'CommuneConsoSmart',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeConsommations' */ '@/views/commune/consommation/ConsommationSmart.vue'
      ),
    meta: myMetaFull,
  },
  {
    path: 'consumption/',
    name: 'CommuneConsommationEp',
    pathToRegexpOptions: { strict: true },
    component: () =>
      import(
        /* webpackChunkName: 'v-communeConsommationEp' */ '@/views/commune/consommation/ConsommationEclairagePublic.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
  {
    path: '*',
    name: 'CommuneError',
    component: () =>
      import(
        /* webpackChunkName: 'v-communeError' */ '@/views/commune/NotFound.vue'
      ),
    meta: {
      ...myMeta,
      static: false,
      auth: true,
    },
  },
] as RouteConfig[]
