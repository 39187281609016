import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import { SidePanel, SidePanelState, RootState } from '@/inc/types'
import { ref } from '@vue/composition-api'
import { logger } from '@/inc/utils'

const state: SidePanelState = {
  all: [],
  index: 0,
}

const getters: GetterTree<SidePanelState, RootState> = {
  panels: state => state.all,
  hasPanels: state => state.all.length > 0,
}

const mutations: MutationTree<SidePanelState> = {
  ADD(state, panel: SidePanel) {
    state.index += 1
    panel.id = state.index
    state.all.push(panel)
  },
  REMOVE(state, panel: SidePanel) {
    state.all.splice(state.all.indexOf(panel), 1)
  },
  REMOVE_LAST(state) {
    state.all.pop()
  },
  REMOVE_ALL(state) {
    state.all = []
  },
}

const actions: ActionTree<SidePanelState, RootState> = {
  showPanel({ commit }, panel: SidePanel) {
    panel.isLoaded = ref(true)

    if (panel.content.status) {
      panel.isLoaded.value = false
      const p = panel.content

      p.then(res => {
        panel.content = res.data.content
        panel.isLoaded.value = true
      }).catch(err => {
        logger.error(err)
        panel.content = { title: 'Errror ' }
        panel.isLoaded.value = true
      })
      panel.content = {}
    }

    commit('ADD', panel)
    document.body.classList.add('no-scroll')
  },
  hidePanel({ commit }) {
    commit('REMOVE_LAST')
    document.body.classList.remove('no-scroll')
  },
  resetPanel({ commit }) {
    commit('REMOVE_ALL')
  },
}

export const sidePanel: Module<SidePanelState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
