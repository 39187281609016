































































































































import { defineComponent } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'

import Tip from '@/components/Tip.vue'

const components = {
  tip: Tip,
}
const Default = {
  template: '<div style="padding: 4rem;">No panel found…</div>',
}

export default defineComponent({
  name: 'side-panel',
  setup(_props, ctx) {
    const { hidePanel } = useActions('sidePanel', ['hidePanel'])
    const { panels, hasPanels } = useGetters('sidePanel', [
      'panels',
      'hasPanels',
    ])
    const close = hidePanel
    const getComponent = component => {
      const { slug, template } = component

      if (template) {
        return template
      }

      const registered = components[slug]

      if (registered) {
        return registered
      }

      ctx.root.$logger.warn('[SidePanel] No valid component found')

      return Default
    }

    return {
      close,
      getComponent,
      panels,
      hasPanels,
    }
  },
})
