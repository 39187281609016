


































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    modifiers: {
      type: Array,
      default: () => [],
    },
  },
  name: 'g-loader',
})
