



























































































































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import { authorizationTypes, GenericObject } from '@/inc/types'
import CustomLink from '@/components/g/CustomLink.vue'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'panel-user',
  components: {
    CustomLink,
  },
  props: {
    content: {
      type: Object as () => GenericObject,
      required: true,
    },
  },
  setup(props, ctx) {
    const { i18n } = useState('commune', ['i18n'])
    const chrome = useState(['chrome'])
    const { authorizations, getCodesPostaux } = useGetters('user', [
      'authorizations',
      'getCodesPostaux',
    ])
    const { currentLang } = useGetters(['currentLang'])
    const [cp] = getCodesPostaux.value
    let pannesElecPath = 'en-temps-reel/pannes-et-interruptions/'
    if (currentLang.value === 'de') {
      pannesElecPath = 'in-echtzeit/stromausfalle/'
    }
    const pannesElecUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/${pannesElecPath}?cp=${cp}`
    )
    let pannesEPpath = 'en-temps-reel/panne-eclairage-public/'
    if (currentLang.value === 'de') {
      pannesEPpath = 'in-echtzeit/storung-offentliche-beleuchtung/'
    }
    const pannesEPUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/${pannesEPpath}?cp=${cp}`
    )

    const closeSidepanel = () => {
      ctx.root.$emit('menu:commune:click')
    }

    return {
      i18n,
      authorizations,
      authorizationTypes,
      closeSidepanel,
      pannesEPUrl,
      pannesElecUrl,
      stringsShared: chrome.chrome.value.data.i18n,
      ...useState('commune', ['isElec', 'isEp', 'isGaz']),
    }
  },
})
