





































































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'

interface CLink {
  label?: string
  icon?: Record<string, unknown>
  to?: string | Record<string, unknown>
  url?: string
  target?: string
  linkTitle?: string
  iconPlacement?: string
}

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'router',
    },
    content: {
      type: Object as () => CLink,
      default: {} as CLink,
    },
    modifiers: {
      type: Array,
      default: () => [],
    },
  },
  name: 'g-custom-link',
  setup(_, ctx) {
    const btnClick = () => {
      ctx.emit('btnClick')
    }

    return {
      btnClick,
    }
  },
})
