var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"header-inner"},[_c('router-link',{staticClass:"header__nav__logo",attrs:{"to":("/" + _vm.currentLang + "/")}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 121 60","symbol":"logos-logo_resa"}})]),_c('div',{staticClass:"header__nav"},[_c('SearchWP'),(_vm.languagesAvailable && !_vm.disableLang() && !_vm.disableLangSearch)?_c('chrome-lang',{staticClass:"header__lang"}):_vm._e(),_c('CustomLink',{staticClass:"header__nav__myresa",attrs:{"content":{
          label: 'MyRESA',
          url: _vm.myResaUrl,
          icon: {
            size: '0 0 24 24',
            name: 'icons-avatars',
          },
        },"modifiers":[
          'btn',
          'reverse',
          'small',
          'outline',
          'static',
          'fixed',
          'no-animation' ],"type":'externalLink'}})],1),_c('mega-menu',{staticClass:"header__mega"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }