import dayjs from 'dayjs'
import axios, { AxiosResponse } from 'axios'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isYesterday from 'dayjs/plugin/isYesterday'

import { getApiUrl } from '@/inc/app.config'
import {
  Outage,
  OutageStatus,
  OutageAddress,
  LatLng,
  OutageSubCat,
} from '@/inc/types'
import { logger } from '@/inc/utils'

dayjs.extend(customParseFormat)
dayjs.extend(isYesterday)
dayjs.extend(isBetween)

interface OutageFilter {
  label: string
  value: string
}

export interface OutageSectionFilters {
  label: string
  filters: OutageFilter[]
}

// Map icons in function oh his type
export const OutagesMapIcons = {
  planned: 'markerplanned',
  identified: 'markeridentified',
  inprogress: 'markerinprogress',
  resolved: 'markerresolved',
}

const PAGESIZE = 1000000

// Function to get with path and params the endpoint pagined
const getEndPoint = (path: string, params: string, page: number) => {
  let endpoint = ''

  if (params) {
    endpoint = `${path}?PageSize=${PAGESIZE}&Page=${page}&${params}`
  } else {
    endpoint = `${path}?PageSize=${PAGESIZE}&Page=${page}`
  }

  return endpoint
}

// Function to call a outages endpoint with pagination.
// Await first call to get the first page and the number of pages
// Recall with promises and for loop to get all pages
export const fetchOutages = async (
  path: string,
  format,
  params = '',
  pagination = true
) => {
  const promises: Promise<AxiosResponse<any>>[] = []
  let outages: Outage[] = []
  let nbPage = 0

  await axios
    .get(`${getApiUrl()}/${getEndPoint(path, params, 0)}`)
    .then(({ data }) => {
      // eslint-disable-next-line
      nbPage = data.pagination.nbPage
      outages = outages.concat(format(data.data))
    })
    .catch(err => logger.error(`[OUTAGES] ${path}`, err.message))

  if (pagination) {
    for (let i = 1; i < nbPage; i++) {
      promises.push(
        axios
          .get(`${getApiUrl()}/${getEndPoint(path, params, i)}`)
          // eslint-disable-next-line
          .then(({ data }) => {
            outages = outages.concat(format(data.data))
          }) as Promise<AxiosResponse<any>>
      )
    }

    await Promise.all(promises).catch(err =>
      logger.error(`[OUTAGES] ${path}`, err.message)
    )
  }

  return outages
}

// Find outage in outages array by his id
export const findOutage = (outages: Outage[], id: string) =>
  outages.find(o => o.id === id)

// Get status of a outage with his end date and start date
const getStatus = (dateStart, dateFin) => {
  const today = dayjs()
  const yesterday = today.subtract(13, 'day')

  if (dateFin && dateFin.isBetween(yesterday, today, null, '()')) {
    return 'resolved'
  } else if (
    dateStart &&
    dateFin &&
    today.isBetween(dateStart, dateFin, null, '()')
  ) {
    return 'inprogress'
  }

  return ''
}

// Function to format all /lightning outputs in Outage element
export const formatEps = (eps: Array<any>) => {
  const outages: Outage[] = []
  eps.forEach(ep => {
    const address: OutageAddress = {
      zip: ep.adresse.zipcode,
      city: ep.adresse.ville,
      street: ep.adresse.rue,
      coords: { lat: ep.adresse.lat, lng: ep.adresse.long } as LatLng,
    }

    // Convert sting date of the webservice output element
    const start = dayjs(ep.dateCreation, 'DD/MM/YYYY')
    const end = ep.dateCloture ? dayjs(ep.dateCloture, 'DD/MM/YYYY') : null
    const statusOutage = getStatus(start, end)

    const status: OutageStatus = {
      idOutage: ep.id,
      dateStart: start,
      dateEnd: end,
      // cause: ep.panne.dernierStatut.shortDescr,
      // If end date get status, if not end date status === identified
      status: statusOutage || 'identified',
    }

    const outage: Outage = {
      id: ep.id,
      cat: 'ep',
      // If end date get status, if not end date status === identified
      subcat: statusOutage || 'identified',
      address,
      status,
    }
    outages.push(outage)
  })

  return outages
}

// Function to format all /outages/unplanned outputs in Outage element
export const formatUnplanned = (ups: Array<any>) => {
  const outages: Outage[] = []
  ups.forEach(up => {
    const address: OutageAddress = {
      zip: up.cdPostal,
      city: up.localite,
      street: up.rue,
      streetNumber: up.numRue,
      coords: { lat: up.gPS.lat, lng: up.gPS.long } as LatLng,
    }

    const getStatusUp = () => {
      if (up.statutPanne === 'RESOLU') {
        return 'resolved'
      } else if (up.statutPanne === 'EN_COURS') {
        return 'inprogress'
      }

      return 'identified'
    }

    const status: OutageStatus = {
      idOutage: up.id,
      dateStart: dayjs(`${up.datePanne}`, 'YYYYMMDD'),
      dateEnd: dayjs(`${up.dateFin} ${up.heureFin}`, 'DD-MM-YYYY HH:mm:ss'),
      hourStart: up.heurePriseCharge,
      duration: up.dureePanne,
      status: getStatusUp(),
      cause: up.cause,
    }

    const outage: Outage = {
      id: up.id,
      cat: 'unplanned',
      subcat: status.status as OutageSubCat,
      address,
      status,
    }

    // if resolved the outage must be in last 24h
    if (
      outage.subcat !== 'resolved' ||
      dayjs(status.dateEnd).isToday() ||
      dayjs(status.dateEnd).isYesterday()
    ) {
      outages.push(outage)
    }
  })

  return outages
}

// Function to format all /outages/planned outputs in Outage element
export const formatPlanned = (ps: Array<any>) => {
  const outages: Outage[] = []
  ps.forEach((p, idx) => {
    const address: OutageAddress = {
      zip: p.cdPostal,
      city: p.localite,
      street: p.rue,
      coords: { lat: p.gPS.avgLat, lng: p.gPS.avgLong } as LatLng,
    }

    // Convert string into date with his hour
    const start = dayjs(`${p.dateDebut} ${p.heureDebut}`, 'DD-MM-YYYY HH:mm:ss')
    const end = dayjs(`${p.dateFin} ${p.heureFin}`, 'DD-MM-YYYY HH:mm:ss')
    const statusOutage = getStatus(start, end)
    const status: OutageStatus = {
      idOutage: p.id,
      dateStart: dayjs(`${p.dateDebut} ${p.heureDebut}`, 'DD-MM-YYYY HH:mm:ss'),
      dateEnd: dayjs(`${p.dateFin} ${p.heureFin}`, 'DD-MM-YYYY HH:mm:ss'),
      status: statusOutage || 'planned',
      cause: p.coupureOuGElectrogene,
    }

    const outage: Outage = {
      id: idx,
      cat: 'planned',
      subcat: (statusOutage as OutageSubCat) || 'planned',
      address,
      status,
    }

    if (
      status.status !== 'resolved' ||
      dayjs(status.dateEnd).isYesterday() ||
      dayjs(status.dateEnd).isToday()
    ) {
      outages.push(outage)
    }
  })

  return outages
}

// Function to format all /outages/tad outputs in Outage element
export const formatTad = (tads: Array<any>) => {
  const outages: Outage[] = []
  tads.forEach(tad => {
    const address: OutageAddress = {
      zip: tad.zipcode,
      city: tad.town,
      locality: tad.locality,
      zone: tad.zone,
    }

    const status: OutageStatus = {
      idOutage: tad.incidentEventId,
      dateStart: dayjs(tad.activationDate, 'YYYY-MM-DD HH:mm:ss'),
      dateEnd: tad.deactivationDate
        ? dayjs(tad.deactivationDate, 'YYYY-MM-DD HH:mm:ss')
        : null,
      status: tad.description || 'inprogress',
      cause: tad.description,
    }

    const outage: Outage = {
      id: tad.incidentEventId,
      cat: 'tad',
      subcat: 'tad',
      address,
      status,
    }

    // if end date tad end date must be in last 24h
    const now = dayjs()
    const yesterday = now.subtract(1, 'day')
    if (!status.dateEnd || status.dateEnd >= yesterday) {
      outages.push(outage)
    }
  })

  return outages
}
