






























import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import auth from '@/composables/auth'
import MyCommuneLang from '@/components/commune/header/Lang.vue'
import MyCommuneUser from '@/components/commune/header/User.vue'
import { useActions, useState } from '@u3u/vue-hooks'
import Menu from '@/components/commune/Menu.vue'
import PanelUser from '@/components/commune/header/PanelUser.vue'

const {
  RESA_ENV,
  VUE_APP_DOMAIN,
  VUE_APP_TASK,
  VUE_APP_VERSION: version,
} = process.env

export default defineComponent({
  name: 'my-header',
  components: {
    MyCommuneLang,
    // MyNotifications,
    MyCommuneUser,
    Menu,
    PanelUser,
  },
  setup(_props, ctx) {
    const { FETCH_I18N } = useActions('commune', ['FETCH_I18N'])
    const { lang } = ctx.root.$route.params
    const { name } = ctx.root.$route
    const resource = useState(['resource'])
    const myLang = ref(lang)
    const { user } = useState(['user'])
    const isOpen = ref(false)
    const body = ref<HTMLElement | null>(null)

    const open = () => {
      isOpen.value = true
      body.value!.style.overflow = 'hidden'
    }

    const close = () => {
      isOpen.value = false
      body.value!.removeAttribute('style')
    }

    const toggleMenu = () => {
      if (isOpen.value) {
        close()
      } else {
        open()
      }
    }

    onMounted(() => {
      body.value = document.body
    })

    ctx.root.$router.beforeEach((_to, _from, next) => {
      ctx.root.$el.classList.remove('is-sidebar-open')

      next()
    })

    ctx.root.$on('menu:commune:click', () => toggleMenu())
    ctx.root.$on('menu:commune:close', () => close())

    watch(myLang, old => {
      FETCH_I18N()
    })

    const languagesAvailable = computed(
      () => Object.keys(resource.resource.value.languages).length > 1
    )

    return {
      isLogged: auth.isLogged,
      toggleMenu,
      user,
      languagesAvailable,
      isOpen,
      name,
    }
  },
})
