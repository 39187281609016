















































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

const { VUE_APP_TASK } = process.env

export default defineComponent({
  name: 'my-lang',
  props: {
    myLanguages: {
      type: Object,
      required: false,
    },
  },
  setup(_props, ctx) {
    const { $logger, $router } = ctx.root
    const { currentLang, languages } = useGetters(['currentLang', 'languages'])
    const isOpen = ref(false)
    const container = ref()
    const myLang = ref('fr')
    const tableLang = ref({})

    if (_props.myLanguages) {
      tableLang.value = _props.myLanguages
    } else {
      tableLang.value = languages.value
    }
    // let mylanguages = { de: '/de/', fr: '/fr/' }

    if (currentLang.value !== undefined) {
      myLang.value = currentLang.value
    }

    const onChange = () => {
      // const { value: url } = e.target as HTMLSelectElement
      let myUrl = ctx.root.$route.fullPath
      if (myLang.value === 'de') {
        myUrl = myUrl.replace('/de/', '/fr/')
        myLang.value = 'fr'
      } else {
        myUrl = myUrl.replace('/fr/', '/de/')
        myLang.value = 'de'
      }
      $router.push(myUrl).catch($logger.trace)
    }
    const open = () => (isOpen.value = true)
    const close = () => (isOpen.value = false)

    const toggle = () => {
      if (isOpen.value) {
        close()
      } else {
        open()
      }
    }

    /* if (VUE_APP_TASK === 'serve') {
      mylanguages = { de: '/de/my-resa/', fr: '/fr/my-resa/' }
    } else {
      mylanguages = languages.value
    } */

    const changeLang = (newLang, url) => {
      myLang.value = newLang
    }

    return {
      currentLang,
      languages,
      container,
      isOpen,
      close,
      onChange,
      toggle,
      myLang,
      changeLang,
      tableLang,
    }
  },
})
