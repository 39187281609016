








































import { defineComponent } from '@vue/composition-api'
import { Block } from '@/inc/types'

export default defineComponent({
  name: 'g-block',
  components: {},
  props: {
    content: {
      type: [Array, Object as () => Block],
      required: true,
    },
  },

  setup() {
    return {}
  },
})
