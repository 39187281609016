














































































































































































import { defineComponent } from '@vue/composition-api'
import { Message, NotificationSeverity } from '@/inc/types'

export default defineComponent({
  name: 'message',
  components: {},
  props: {
    content: {
      type: Object as () => Message,
      required: true,
    },
    severity: {
      type: String as () => NotificationSeverity,
      default: 'information',
    },
  },

  setup() {
    const icons = {
      success: 'ui-check-filled',
      warning: 'ui-warning-filled',
      error: 'ui-error-filled',
    }

    return {
      icons,
    }
  },
})
